<template>
  <div>
    <div class="row align-items-center justify-between">
      <div class="col-12 col-lg-4">
        <BreedCrumb/>
      </div>
      <div class="col-12 col-md-6">
        <div class="d-flex flex-md-row flex-column  justify-content-end">
          <div class="d-flex flex-column ms-md-2 " v-if="filter.role === 10 || filter.role === 20">
            <span class="text-xs">User</span>
            <n-select class="w-100" style="width: 200px;" filterable
                      v-model:value="filter.userId" :options="users" label-field="fullName" value-field="id"
                      @update:value="handleUserIdChange"></n-select>
          </div>
          <div class="d-flex flex-column ms-md-2 w-100">
            <span class="text-xs">Account</span>
            <n-select class="w-100" v-model:value="filter.accountId" :options="accounts" filterable
                      label-field="accountName" value-field="id" @update:value="getDeposits()"></n-select>
          </div>
          <!--          <n-input-group>-->
          <!--            <n-input v-model:value="filter.search" placeholder="search here"></n-input>-->
          <!--            <n-button type="primary" class="ms-2">-->
          <!--              <i class="mgc_search_line"></i>-->
          <!--            </n-button>-->
          <!--          </n-input-group>-->
          <router-link to="deposit-methods" v-if="role === 30">
            <n-button type="primary" class="ms-md-2 mt-3 w-100">Deposit Account</n-button>
          </router-link>
        </div>
      </div>
    </div>
    <n-divider class="my-1"></n-divider>
    <el-table border :data="deposits" header-cell-class-name="tb-header" :show-overflow-tooltip="true">
      <template #empty>
        <n-empty class="pt-4" description="No Data">
        </n-empty>
      </template>
      <el-table-column label="Account" prop="account.accountName"></el-table-column>
      <el-table-column label="Account #" prop="account.accountNumber"></el-table-column>
      <el-table-column label="Amount" prop="amount">
        <template #default="scope">
          <div class="text-end">
            <span>{{ scope.row.amount }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Fee" prop="fee">
        <template #default="scope">
          <div class="text-end">
            <span>{{ scope.row.fee }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Date" prop="transactionDate">
        <template #default="scope">
          <span>{{ getFormattedDate(scope.row.transactionDate) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="USD Rate" prop="usdRate"></el-table-column>
      <el-table-column label="Source" prop="source">
      </el-table-column>
      <el-table-column width="120" label="Status" prop="transactionStatus"></el-table-column>
      <el-table-column fixed="right" label="Action" width="100" v-if="filter.role !== 30">
        <template #default="scope">
          <div class="text-center">
            <el-tooltip content="view Details">
              <i class="bi bi-eye action-btn text-primary" @click="takeRow(scope.row, 'details')"></i>
            </el-tooltip>
            <n-dropdown v-if="scope.row.status !== 20" trigger="click" :options="statuses" @select="handleSelect"
                        placement="bottom-end"
                        class="shadow-sm "
                        style="min-width: 150px">
              <i class="bi bi-three-dots-vertical action-btn ms-2" @click="takeRow(scope.row)"></i>
            </n-dropdown>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <n-modal v-model:show="detailsDialog" :show-icon="false"
             preset="dialog" title="Transaction Details" style="width: auto; max-width: 500px">
      <div v-if="transaction.type === 10">
        <div class="d-flex justify-content-between">
          <span>Deposit Proof</span>
          <span>Account Details</span>
        </div>
        <div class="row  justify-content-between  align-items-center">
          <div class="col-12 col-md-6 mt-2">
            <div v-if="transaction?.documentJson !== null && transaction?.documentJson !== undefined">
              <n-image class="mt-1" :src="JSON.parse(transaction?.documentJson).path" alt="" :height="200"
                       :width="200"></n-image>
            </div>
            <!--            <img class="mt-1" :src="isBase64Image(transaction?.documents[0])" alt="" height="200" width="200">-->
          </div>
          <div class="col-12 col-md-6 mt-2">

            <n-table size="small" class="mt-1" :single-line="false" bordered>
              <tbody>
              <tr>
                <td class="text-xs" style="width: 50%">Bank Name</td>
                <td class="text-xs" style="width: 50%">{{ transaction?.fundingAccount?.bankName ?? '' }}</td>
              </tr>
              <tr>
                <td class="text-xs" style="width: 50%">Account Title</td>
                <td class="text-xs" style="width: 50%">{{ transaction?.fundingAccount?.accountTitle ?? '' }}</td>
              </tr>
              <tr>
                <td class="text-xs">Account Number</td>
                <td class="text-xs">
                  <div class="d-flex align-items-center">
                    <span>{{ transaction?.fundingAccount?.accountNumber ?? '' }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-xs">Branch</td>
                <td class="text-xs">{{ transaction?.fundingAccount?.branch ?? '' }}</td>
              </tr>
              <tr>
                <td class="text-xs">Currency</td>
                <td class="text-xs">{{ transaction?.fundingAccount?.currency ?? '' }}</td>
              </tr>

              </tbody>
            </n-table>
          </div>
        </div>
      </div>
      <div v-if="transaction.type === 20">
        <div class="d-flex justify-content-between">

          <span>Account Details</span>
        </div>
        <div class="">
          <div>
            <n-table size="small" style="width: 100%" class="mt-1" :single-line="false" bordered>
              <tbody>
              <tr>
                <td class="text-xs" style="width: 50%">Bank Name</td>
                <td class="text-xs" style="width: 50%">{{ transaction?.payoutAccount?.bankName ?? '' }}</td>
              </tr>
              <tr>
                <td class="text-xs" style="width: 50%">Account Title</td>
                <td class="text-xs" style="width: 50%">{{ transaction?.payoutAccount?.accountTitle ?? '' }}</td>
              </tr>
              <tr>
                <td class="text-xs">Account Number</td>
                <td class="text-xs">
                  <div class="d-flex align-items-center">
                    <span>{{ transaction?.payoutAccount?.accountNumber ?? '' }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-xs">Branch</td>
                <td class="text-xs">{{ transaction?.payoutAccount?.branch ?? '' }}</td>
              </tr>
              <tr>
                <td class="text-xs">Currency</td>
                <td class="text-xs">{{ transaction?.payoutAccount?.currency ?? '' }}</td>
              </tr>

              </tbody>
            </n-table>
          </div>
        </div>
      </div>
      <div class="mt-1">
        <div class="d-flex justify-content-end">
          <n-button :color="colors.blackPrimary" @click="detailsDialog = false">Close</n-button>
        </div>
      </div>
    </n-modal>
    <div class="mt-2 d-flex justify-content-end">
      <n-pagination
          class="d-pagination"
          v-model:page="pagination.page"
          v-model:page-size="pagination.pageSize"
          :page-count="pagination.totalPages"
          show-size-picker
          :page-sizes="pageSizes"
          :on-update-page="pageChange"
          :on-update-page-size="pageSizeChange"
      />
    </div>
    <n-spin v-if="loading" class="mn-spin" :stroke-width="12" :size="25"
            style="z-index: 999!important;"></n-spin>
  </div>
</template>
<script>
import {NButton, NDivider, NDropdown, NEmpty, NImage, NModal, NPagination, NSelect, NSpin, NTable,} from "naive-ui";
import BreedCrumb from "@/components/ui/breadcrumb.vue";
import transactionService from "@/services/transactionService";
import showMessage from "@/utility/showMessage";
import moment from "moment";
import userService from "@/services/userService";
import accountService from "@/services/accountService";
import store from "@/store";
import colors from "@/utility/colors";
import notificationService from "@/services/notificationService";

export default {
  name: "DepositAccount",
  components: {NModal, NImage, NSpin, NTable, NDropdown, NSelect, NPagination, NDivider, NButton, BreedCrumb, NEmpty},
  data() {
    return {
      loading: false,
      addDialog: false,
      detailsDialog: false,
      deposits: [],
      transaction: {},
      isMobile: false,
      bodyStyle: {
        width: "600px"
      },
      segmented: {
        content: "soft",
        footer: "soft"
      },
      users: [{id: 0, fullName: "All"}],
      accounts: [
        {id: 0, accountName: "All"}
      ],
      statuses: [
        {
          label: "Pending",
          key: 10,
        },
        {
          label: "Approved",
          key: 20,
        },
        {
          label: "Rejected",
          key: 30,
        },
      ],
      filter: {
        search: "",
        page: 1,
        pageSize: 10,
        totalPages: 1,
        accountId: 0,
        "filterText": "",
        "filterTag": "",
        "userId": Number(sessionStorage.getItem("userId")),
        "fromDate": new Date(),
        "toDate": new Date(),
        "month": new Date(),
        "transactionType": 10,
        "transactionStatus": 0,
        "role": Number(sessionStorage.getItem("role")),
      },
      pagination: {
        fetched: 0,
        page: 1,
        pageSize: 10,
        records: 0,
        totalPages: 0
      },
      pageSizes: [
        {
          label: '10 per page',
          value: 10
        },
        {
          label: '20 per page',
          value: 20
        },
        {
          label: '30 per page',
          value: 30
        },
        {
          label: '40 per page',
          value: 40
        }
      ]
    }
  },

  computed: {
    colors() {
      return colors;
    },
    role() {
      return Number(sessionStorage.getItem("role"));
    },
    store() {
      return store;
    }
  },
  methods: {
    isBase64Image(document) {
      let fileData = document?.fileData;
      const fileName = document?.fileName;
      const fileExtension = document?.fileExt;
      const mimeType = document?.mimeType;


      if (fileData === '' || fileData === null) {
        return;
      }
      const base64Prefixes = {
        'jpeg': 'data:image/jpeg;base64,',
        'jpg': 'data:image/jpeg;base64,',
        'png': 'data:image/png;base64,',
        // Add more image types if needed
      };

      const extension = fileExtension.toLowerCase();

      // Check if the file is an image based on MIME type or file extension
      const isImage = mimeType.startsWith('image/') || ['jpeg', 'jpg', 'png'].includes(extension);

      if (isImage) {
        // Ensure the fileData starts with the correct base64 prefix
        const prefix = base64Prefixes[extension];
        if (!fileData.startsWith(prefix)) {
          fileData = prefix + fileData;
        }
        return fileData; // Return the correctly prefixed base64 image data
      } else {
        // If it's not an image, create a Blob and download the file
        const byteCharacters = atob(fileData);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: mimeType});

        // Create a link element to download the file with the given fileName
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    takeRow(transaction, type) {
      this.transaction = JSON.parse(JSON.stringify(transaction));

      switch (type) {
        case 'details':
          this.detailsDialog = true;
          break;
      }
    },
    handleSelect(key) {
      switch (key) {
        case 10:
          this.changeStatus(this.transaction.id, 10);
          break;
        case 20:
          this.changeStatus(this.transaction.id, 20);
          break;
        case 30:
          this.changeStatus(this.transaction.id, 30);
          break;

      }
    },
    async changeStatus(id, status) {
      this.loading = true;
      try {
        let response = await transactionService.changeStatus(id, status);
        if (response.data.isSuccessful) {
          showMessage("success", response?.data?.msg ?? "Status updated");
          store.state.updateHeader = true;

          await this.getDeposits();
        } else {
          showMessage("success", response?.data?.msg ?? "Status already updated");
        }
      } catch (e) {
        showMessage("error", "Status update failed");
      }
      this.loading = false;
    },
    getFormattedDate(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    pageChange(page) {
      this.filter.page = page;
      this.getDeposits();
    },
    pageSizeChange(pageSize) {
      this.filter.pageSize = pageSize;
      this.getDeposits();
    },
    async getDeposits() {
      this.loading = true;
      try {
        let response = await transactionService.all(this.filter);
        this.deposits = response?.data?.data ?? [];
        this.pagination = response?.data?.pagination;
      } catch (e) {

        showMessage("error", "Something went wrong")
      }
      this.loading = false;
    },
    handleUserIdChange(newValue) {
      // Call your method here
      this.filter.page = 1;
      this.getUserAccounts(newValue);
    },
    async getUsers() {
      this.loading = true;
      try {
        let response = await userService.getAllUsers(this.filter.role);
        this.users = this.users.concat(response?.data?.data ?? []);
        if (this.filter.role === 10 || this.filter.role === 20) {

          this.filter.userId = 0;

        }
        await this.getUserAccounts(this.filter.userId);
      } catch (e) {

        showMessage("error", "Something went wrong")
      }
    },

    async getUserAccounts(userId) {
      this.loading = true;
      try {
        this.filter.userId = userId;
        let response = await accountService.getTransactionsAccount(this.filter);
        this.accounts = this.accounts.concat(response?.data?.data ?? []);
        await this.getDeposits();
      } catch (e) {

        showMessage("error", "Something went wrong");
      }
      this.loading = false;
    },
    checkLogin() {
      return (sessionStorage.getItem("loggedIn") === null || sessionStorage.getItem("loggedIn") === undefined || sessionStorage.getItem("loggedIn") === false);
    },
    async markNotificationRead() {
      // eslint-disable-next-line no-unused-vars
      let response = await notificationService.markRead(this.$store.state.notificationType);
      this.$store.commit('setUpdateNotification', true);
    }
  },
  created() {
    if (!this.checkLogin) {
      this.$router.push('/');
      return;
    }
    this.getUsers();
    this.markNotificationRead();
  }
}
</script>

<style scoped>

</style>