import axiosConfig from "@/config/axiosConfig";
import axios from "axios"

export default {
    uploadUrl: axiosConfig.apiBaseUrl + "Document/UploadDocument/UploadDocument",
    deleteUrl: axiosConfig.apiBaseUrl + "Document/Delete?blob=",


    async upload(document) {
        try {
            const fd = new FormData();
            fd.append("file", document);
            let response = await axios({
                method: "post",
                url: this.uploadUrl,
                data: fd,
                headers: {
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json",
                },
            });
            return response;
        } catch (error) {
            return error;
        }
    },
    delete(blob) {
        return axios.get(this.deleteUrl + blob)
    }
}