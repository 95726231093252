<template>
  <div>
    <div class="d-flex">
      <div class="d-flex align-items-center">
        <router-link to="accounts">
          <n-button quaternary class="me-2">
            <i class="bi bi-arrow-left text-xl"></i>
          </n-button>
        </router-link>
        <span class="h3">Open New Account</span>
      </div>
    </div>
    <div class="d-flex">
      <n-scrollbar x-scrollable class="py-2">
        <div class="scrollbar-flex-content">
          <div v-if="loading && this.categories.length === 0">
            <el-skeleton style="width: 312px" class="me-2" v-for="item in 3" :key="item">
              <template #template>
                <div class="py-2 d-flex justify-content-between align-items-center">
                  <el-skeleton-item variant="text" style=" height: 200px"/>
                </div>
                <div class="py-2 d-flex justify-content-between align-items-center">
                  <el-skeleton-item variant="text" style="width: 45%; height: 40px"/>
                  <el-skeleton-item variant="text" style="width: 45%; height: 40px"/>
                </div>
                <div class="py-2 d-flex justify-content-between align-items-center">
                  <el-skeleton-item variant="text" style="width: 45%; height: 40px"/>
                  <el-skeleton-item variant="text" style="width: 45%; height: 40px"/>
                </div>
                <div class="py-2 d-flex justify-content-between align-items-center">
                  <el-skeleton-item variant="text" style="width: 45%; height: 40px"/>
                  <el-skeleton-item variant="text" style="width: 45%; height: 40px"/>
                </div>
                <div class="py-2 d-flex justify-content-between align-items-center">
                  <el-skeleton-item variant="text" style="width: 45%; height: 40px"/>
                  <el-skeleton-item variant="text" style="width: 45%; height: 40px"/>
                </div>
              </template>
            </el-skeleton>
          </div>
          <el-card style="width: 312px; cursor: pointer;" shadow="hover" class="py-5 me-2" @click="selectCard(item)"
                   v-for="item in categories" :key="item.categoryId"
                   :class="item.categoryId === selected ? 'selected-card' : ''">
            <template #header>
              <div class="flex align-items-center">
                <div class="text-center">
                  <div class="flex flex-col align-items-center">
                    <span class="h3">{{ item.title }}</span>
                    <n-tag size="small" type="warning" round
                           :color="{ color: colors.logoPrimary, textColor: '#fff' }">
                      <i class="bi bi-check-circle-fill me-2"></i>
                      <span>{{ item.badgeTitle }}</span>
                    </n-tag>
                    <span class="text-sm font-normal mt-3">{{ item.description }}</span>
                  </div>
                </div>
              </div>
            </template>
            <template #default>
              <n-list show-divider>
                <n-list-item>
                  <div class="flex justify-between">
                    <span>Minimum Deposit</span>
                    <span class="font-semibold">{{ item.minimumDeposit }} {{ item.currency }}</span>
                  </div>
                </n-list-item>
                <n-list-item>
                  <div class="flex justify-between">
                    <span>Spread</span>
                    <span class="font-semibold">From {{ item.spread }}</span>
                  </div>
                </n-list-item>
<!--                <n-list-item>-->
<!--                  <div class="flex justify-between">-->
<!--                    <span>Commission</span>-->
<!--                    <span class="font-semibold" v-if="item.commission === 0">No Commission</span>-->
<!--                    <span class="font-semibold" v-else>{{ item.commission }}</span>-->
<!--                  </div>-->
<!--                </n-list-item>-->
              </n-list>
            </template>
          </el-card>

        </div>
      </n-scrollbar>
    </div>

    <n-button @click="nextPage()" :color="colors.logoPrimary" style="width: 312px">
      Continue
    </n-button>

  </div>
</template>
<script>

import {NButton, NList, NListItem, NScrollbar, NTag} from "naive-ui";
import colors from "@/utility/colors"
import accountService from "@/services/accountService";
import showMessage from "@/utility/showMessage"

export default {
  name: "NewAccount",
  components: {NScrollbar, NListItem, NList, NTag, NButton},
  data() {
    return {
      loading: false,
      selected: 1,
      categories: [],
      account: {
        id: 0,
        accountType: 1,
        accountNickName: "",
        accountPassword: "",
      },
    }
  },
  methods: {
    selectCard(item) {
      this.selected = item;
    },
    nextPage() {
      this.$store.state.categoryId = this.selected;
      this.$router.push('/create-account')
    },
    async getCategories() {
      this.loading = true;
      try {
        let response = await accountService.getCategories();
        this.categories = response?.data?.data ?? [];
        if (this.categories.length > 0) {
          this.selected = this.categories[0].id;

        }
      } catch (e) {
        showMessage("error", e?.data?.message ?? "Data fetching failed")
      }
      this.loading = false;
    },
    checkLogin() {
      return (sessionStorage.getItem("loggedIn") === null || sessionStorage.getItem("loggedIn") === undefined || sessionStorage.getItem("loggedIn") === false);
    }
  },
  created() {
    if (!this.checkLogin) {
      this.$router.push('/');
      return;
    }
    this.getCategories();
  },
  computed: {
    colors() {
      return colors;
    }
  }
}
</script>

<style scoped>
.scrollbar-flex-content {
  display: flex;
}

.selected-card {
  border: 2px solid #ffa630 !important;
}
</style>