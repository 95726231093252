<template>
  <div>
    <div class="row justify-content-between align-items-center">
      <div class="col-12 col-lg-4">
        <BreedCrumb :show-bread="true"/>
      </div>
      <div class="col-12 col-lg-4">
        <div class="d-flex">
          <n-input-group class="ms-md-2">
            <n-input v-model:value="filter.filterText" @input="getReferrals()" placeholder="search here"></n-input>
            <n-button type="primary" class="ms-2">
              <i class="mgc_search_line"></i>
            </n-button>
          </n-input-group>
          <el-tooltip content="Add IB Commission" v-if="Number(role) === 10 || Number(role) === 20">
            <n-button type="primary" class="ms-2" @click="addDialog = !addDialog">
              <i class="bi bi-plus-lg"></i>
            </n-button>
          </el-tooltip>
          <n-dropdown class="mt-2 mt-md-0" @select="handleSelect" :options="dropdown" trigger="hover" value="value"
                      label-field="label"
                      v-if="Number(role) === 30"
                      key-field="value">

            <n-button type="primary" class="ms-2">
              <i class="bi bi-text-right"></i>
            </n-button>
          </n-dropdown>
        </div>
      </div>
    </div>
    <n-divider class="my-1"></n-divider>
    <div class="row my-2 justify-content-between">
      <div class="col-12 col-md-3" v-if="Number(role) === 10 || Number(role) === 20">
        <div class="d-flex flex-col">
          <label>Filter by User</label>
          <el-select v-model="filter.userId" @change="changeUser()">
            <el-option v-for="user in users" :key="user.id" :label="user.fullName" :value="user.id"></el-option>
          </el-select>
        </div>
      </div>
      <div class="col-12 col-md-6 mt-2 mt-md-0">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="d-flex flex-col">
              <label>Start Date</label>
              <el-date-picker v-model="filter.startDate" :editable="false" class="action-btn w-100"></el-date-picker>
            </div>
          </div>
          <div class="col-12 col-md-6 mt-2 mt-md-0">
            <div class="d-flex flex-col">
              <label>End Date</label>
              <el-date-picker v-model="filter.endDate" :editable="false" class="action-btn w-100"></el-date-picker>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row my-1">
      <div class="col-12 col-md-3 mt-2 mt-md-0">
        <n-card hoverable style="height: 130px">
          <div class="d-flex justify-center align-items-center h-100">
            <n-space vertical align="center">
              <span class="text-xl">Partners</span>
              <AnimatedNumber
                  :to="dashboardData?.partners"
                  css-class="text-4xl font-semibold"

              />
            </n-space>
          </div>
        </n-card>
      </div>
      <div class="col-12 col-md-3 mt-2 mt-md-0">
        <n-card hoverable style="height: 130px">
          <div class="d-flex justify-center align-items-center h-100">
            <n-space vertical align="center">
              <span class="text-xl">Partners Accounts</span>
              <AnimatedNumber
                  :to="dashboardData?.partnersAccounts"
                  css-class="text-4xl font-semibold"

              />
            </n-space>
          </div>
        </n-card>
      </div>
      <div class="col-12 col-md-3 mt-2 mt-md-0">
        <n-card hoverable style="height: 130px">
          <div class="d-flex justify-center align-items-center h-100">
            <n-space vertical align="center">
              <span class="text-xl">Total Volume</span>
              <AnimatedNumber
                  :to="dashboardData?.totalBalance"
                  css-class="text-4xl font-semibold"
                  :format="formatCurrency"
              />
            </n-space>
          </div>
        </n-card>
      </div>
      <div class="col-12 col-md-3 mt-2 mt-md-0">
        <n-card hoverable style="height: 130px">
          <div class="d-flex justify-center align-items-center h-100">
            <n-space vertical align="center">
              <span class="text-xl">Total Withdraw</span>
              <AnimatedNumber
                  :to="dashboardData?.withdrawals"
                  css-class="text-4xl font-semibold"
                  :format="formatCurrency"
              />
            </n-space>
          </div>
        </n-card>
      </div>
    </div>
    <el-table border :data="referrals" header-cell-class-name="tb-header">
      <template #empty>
        <n-empty class="pt-4" description="No Data">
        </n-empty>
      </template>
      <el-table-column label="#" type="index" width="55" align="center">
        <template #default="scope">
          <div>{{ calculateIndex(scope.$index) }}</div>
        </template>
      </el-table-column>
      <el-table-column min-width="150" label="Partner Name" prop="fullName"></el-table-column>
      <el-table-column min-width="150" label="Account Registration Date" prop="city" width="250">
        <template #default="scope">
          <span>{{ getFormattedDate(scope.row.createdAt) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Last Trade date" prop="city" width="180">
        <template #default="scope">
          <span>{{ getFormattedDate(scope.row.lastTransactionDate) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Ib Profit" prop="ibProfit" width="150"></el-table-column>
    </el-table>
    <div class="mt-2 d-flex justify-content-end">
      <n-pagination
          class="d-pagination"
          v-model:page="pagination.page"
          v-model:page-size="pagination.pageSize"
          :page-count="pagination.totalPages"
          show-size-picker
          :page-sizes="pageSizes"
          :on-update-page="pageChange"
          :on-update-page-size="pageSizeChange"
      />
    </div>


    <el-dialog v-model="addDialog" title="Add Commission" style="width: auto; max-width: 500px">
      <template #default>
        <el-form label-position="top">
          <el-form-item label="IB User">
            <el-select v-model="addCommissionModel.commissionUserId" filterable
                       @change="getIbParentAccounts(addCommissionModel.commissionUserId)">
              <el-option v-for="user in ibUsers" :key="user.id" :label="user.fullName"
                         :value="user.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Parent">
            <el-input disabled :placeholder="ibPlaceHolder">

            </el-input>
          </el-form-item>
          <el-form-item label="Amount">
            <el-input-number :controls="false" class="w-100" v-model="addCommissionModel.amount">

            </el-input-number>

          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <n-button tertiary @click="addDialog = false">Cancel</n-button>
          <n-button type="primary" class="ms-2" :disabled="ibAccount.accountId < 1 || addCommissionModel.amount < 0.1"
                    @click="addCommission">Add
          </n-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="transferDialog" title="Transfer Commission" style="width: auto; max-width: 500px">
      <template #default>
        <el-form label-position="top">
          <el-form-item label="Ib Account">
            <el-input disabled :placeholder="transferIbAccount?.accountName"></el-input>
          </el-form-item>
          <el-form-item label="Trading Accounts">
            <el-select v-model="transferModel.tradingAccountId" filterable>
              <el-option v-for="account in tradingAccounts" :key="account.id" :label="account.accountName"
                         :value="account.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Amount">
            <el-input-number :controls="false" class="w-100" v-model="transferModel.amount">

            </el-input-number>
            <el-collapse-transition>
              <span v-if="transferModel.amount > transferIbAccount?.amount" class="text-sm text-danger">Your balance is {{
                  transferIbAccount?.amount
                }}</span>
            </el-collapse-transition>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <n-button tertiary @click="transferDialog = false">Cancel</n-button>
          <n-button
              :disabled="transferIbAccount === null || transferModel.amount >  transferIbAccount?.amount || transferIbAccount.id < 1 || transferModel.tradingAccountId < 1 || transferModel.amount < 10"
              type="primary" class="ms-2" @click="transferAmount">Transfer
          </n-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="withdrawalDialog" title="Withdraw" style="width: auto; max-width: 500px">
      <template #default>
        <el-form label-position="top">
          <el-form-item label="Ib Account">
            <el-input disabled :placeholder="myIbAccount?.accountName"></el-input>
          </el-form-item>
          <el-form-item label="Withdrawal Option">
            <el-select v-model="payoutType" @change="getAllPayoutAccounts(payoutType)">
              <el-option v-for="payout in payoutTypes" :key="payout.value"
                         :label="payout.text" :value="payout.value"></el-option>

            </el-select>
          </el-form-item>
          <el-form-item label="Payout Account">
            <el-select v-model="transferModel.payoutAccountId">
              <el-option v-for="account in payoutAccounts" :key="account.id"
                         :label="account.bankName +' (' + account.accountNumber+')'" :value="account.id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Withdrawal Amount">
            <el-input-number class="w-100" :controls="false" v-model="transferModel.amount"></el-input-number>
            <el-collapse-transition>
              <span v-if="transferModel.amount > myIbAccount?.amount" class="text-sm text-danger">Your balance is {{
                  transferIbAccount?.amount
                }}</span>
            </el-collapse-transition>
          </el-form-item>
          <div v-if="Number(payoutType) === 10" class="bg-gray-50 h-20 my-2 px-3 align-items-center d-flex justify-content-between">
            <span>To be withdraw</span>
            <div>
              <span class="text-2xl font-semibold">{{ calculatedPkr(transferModel.amount) }}</span><span>PKR</span>
            </div>
          </div>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <n-button tertiary @click="withdrawalDialog = false">Cancel</n-button>
          <n-button type="primary" class="ms-2" @click="withdraw"
                    :disabled="transferModel.amount > myIbAccount.amount || !myIbAccount.accountName">
            Withdraw
          </n-button>
        </div>
      </template>
    </el-dialog>

    <n-spin v-if="loading" class="mn-spin" :stroke-width="12" :size="25"
            style="z-index: 999!important;"></n-spin>

  </div>
</template>
<script>
import {NSpace, NButton, NCard, NDivider, NEmpty, NInput, NInputGroup, NPagination, NSpin, NDropdown} from "naive-ui";
import BreedCrumb from "@/components/ui/breadcrumb.vue";
import userService from "@/services/userService";
import showMessage from "@/utility/showMessage";
import moment from "moment/moment";
import AnimatedNumber from "@/components/ui/AnimateNumber.vue";
import transactionService from "@/services/transactionService";
import accountService from "@/services/accountService";
import paymentService from "@/services/paymentService";
import notificationService from "@/services/notificationService";
import settingsService from "@/services/settingsService";

export default {
  name: "UserReferrals",
  components: {
    NDropdown,
    AnimatedNumber,
    NSpace, NCard, NInputGroup, NInput, NButton, NPagination, NEmpty, NDivider, BreedCrumb, NSpin
  },
  data() {
    return {
      loading: false,
      addDialog: false,
      transferDialog: false,
      withdrawalDialog: false,
      payoutType: 10,
      payoutTypes: [
        {text: "Bank", value: 10},
        {text: "TRC20", value: 20},
      ],
      payoutAccounts: [],
      tradingAccounts: [],
      myIbAccount: {},
      dashboardData: {
        partners: 0,
        partnersAccounts: 0,
        totalBalance: 0,
        withdrawals: 0
      },
      withdrawalModel: {
        payoutAccountId: 0,
        withdrawAccountId: 0,
        amount: 0,
      },
      ibUsers: [],
      accounts: [],
      ibAccount: {},
      transferIbAccount: {},
      users: [{id: 0, fullName: "All"}],
      referrals: [],
      dropdown: [
        {label: "Transfer", value: 10},
        {label: "Withdraw", value: 20}
      ],
      addCommissionModel: {
        "accountId": 0,
        "amount": 0,
        "userId": 0,
        "commissionUserId": ""
      },

      transferModel: {
        ibAccountId: 0,
        tradingAccountId: 0,
        amount: 0,
        userId: Number(sessionStorage.getItem("userId")),
        payoutAccountId: 0,
      },
      depositModel: {
        referralCode: "",
        accountId: "",
        amount: 0,
        transactionType: 60,
        id: "",
      },
      filter: {
        "pageSize": 10,
        "page": 1,
        "filterText": "",
        "filterTag": "",
        "userId": Number(sessionStorage.getItem("userId")),
        "startDate": new Date(),
        "endDate": new Date()
      },
      config:{},
      pagination: {
        fetched: 0,
        page: 1,
        pageSize: 10,
        records: 0,
        totalPages: 0
      },

      pageSizes: [
        {
          label: '10 per page',
          value: 10
        },
        {
          label: '20 per page',
          value: 20
        },
        {
          label: '30 per page',
          value: 30
        },
        {
          label: '40 per page',
          value: 40
        }
      ]
    }
  },
  computed: {
    ibPlaceHolder() {
      if (this.ibAccount.fullName === undefined) {
        return ""
      } else {
        return this.ibAccount.fullName + ', ' + this.ibAccount.accountName + '(' + this.ibAccount.accountNumber + ')';
      }
    },
    role() {
      return Number(sessionStorage.getItem("role"));
    }
  },
  methods: {
    calculatedPkr(amount) {
      if (amount < 1 || typeof Number(this.config?.value) !== 'number') return amount;
      return (amount * Number(this.config?.value)).toFixed(2);
    },
    async getCurrencyConfig() {
      let response = await settingsService.getByKey("USD");
      this.config = response?.data ?? {};

    },
    calculateIndex(index) {
      return (this.pagination.page - 1) * this.pagination.pageSize + index + 1;
    },
    handleSelect(key) {
    this.transferModel.amount = 0;
      switch (key) {
        case 10:
          this.getTransferAccounts();
          this.getIbParentAccounts()
          this.transferDialog = true;
          break;
        case 20:
          this.getIbAccount();
          this.withdrawalDialog = true;
          break;
      }
    },
    changeUser() {
      this.filter.page = 1;
      this.getReferrals();
      this.getDashboardData();
    },
    formatCurrency(amount, locale = 'en-US') {
      const options = {
        style: 'decimal',
        minimumFractionDigits: amount % 1 === 0 ? 0 : 2,
        maximumFractionDigits: 2
      };
      return new Intl.NumberFormat(locale, options).format(amount);
    },
    getFormattedDate(date) {
      if (date === null) {
        return "";
      }
      return moment(date).format("DD-MMM-YYYY");
    },
    pageChange(page) {
      this.filter.page = page;
      this.getReferrals();
    },
    pageSizeChange(pageSize) {
      this.filter.pageSize = pageSize;
      this.getReferrals();
    },
    async getAllUsers() {
      this.loading = true;
      try {
        this.filter.role = this.role;
        let response = await userService.getAllUsers(this.filter.role);
        this.users = this.users.concat(response?.data?.data ?? []);
        if (Number(sessionStorage.getItem("role")) !== 30) {
          this.filter.userId = this.users[0].id;
        }
        await this.getReferrals()
        await this.getDashboardData()
        this.loading = false;
      } catch (e) {
        console.log(e)
        showMessage("error", "Something went wrong")
      }
      this.loading = false;
    },
    async getReferrals() {
      this.loading = true;
      try {
        let response = await userService.getReferrals(this.filter);
        this.referrals = response?.data?.data ?? [];

        this.pagination = response?.data?.pagination;
      } catch (e) {
        showMessage("error", "Something went wrong")
      }
      this.loading = false;
    },
    async getDashboardData() {
      this.loading = true;
      try {
        let response = await userService.getIbDashboardData(this.filter);
        this.dashboardData = response?.data?.data ?? {};
      } catch (e) {
        showMessage("error", "Something went wrong")
      }
      this.loading = false;
    },
    async getIbUsers() {
      this.loading = true;
      try {
        let response = await userService.getIbUsers();
        this.ibUsers = response.data.data ?? [];
      }
          // eslint-disable-next-line no-empty
      catch (e) {

      }
      this.loading = false;
    },
    async getIbAccount() {
      this.loading = true;
      try {
        let response = await accountService.getIbAccount(this.filter.userId);
        this.myIbAccount = response?.data;
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async getIbParentAccounts(id) {
      this.loading = true;
      try {
        let referralCode = this.ibUsers.find(x => x.id === id).referralCode;
        let response = await userService.getIbParentAccounts(referralCode);
        this.ibAccount = response?.data?.data ?? {};
      }
          // eslint-disable-next-line no-empty
      catch (e) {

      }
      this.loading = false;
    },
    async addCommission() {
      this.addDialog = false;
      this.loading = true;
      try {
        this.addCommissionModel.userId = this.ibAccount.userId;
        this.addCommissionModel.ibAccountId = this.ibAccount.accountId;
        this.addCommissionModel.id = this.depositModel.id;
        let response = await transactionService.addCommission(this.addCommissionModel)
        if (response?.data?.isSuccessful) {
          showMessage("success", "Commission Added");
          await this.getReferrals();
        }
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async getTransferAccounts() {
      this.loading = true;
      try {
        let response = await accountService.getIbTransferAccounts(this.filter.userId);
        this.tradingAccounts = response?.data?.tradingAccounts ?? [];
        this.transferIbAccount = response?.data?.ibAccount ?? {
          id: 0,
          amount: 0,
          accountName: "",
          accountPassword: "",
        };

        if (this.tradingAccounts.length > 0) {
          this.transferModel.tradingAccountId = this.tradingAccounts[0].id;
        }
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async transferAmount() {
      this.transferDialog = false;
      this.loading = true;
      try {
        this.transferModel.ibAccountId = this.transferIbAccount.id;
        let response = await accountService.transferCommission(this.transferModel);
        if (response?.data) {
          showMessage("success", "Transfer request generated");
        }
      } catch (e) {
        console.log(e)
      }

      this.loading = false;
    },
    async withdraw() {
      this.withdrawalDialog = false;
      this.loading = true;
      try {
        this.transferModel.ibAccountId = this.transferIbAccount.id;
        this.transferModel.userId = this.filter.userId;
        let response = await accountService.withdrawCommission(this.transferModel);
        if (response?.data) {
          showMessage("success", "Withdrawal request generated");
        }
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },
    async getAllPayoutAccounts(type) {
      this.loading = true;
      try {
        const userId = Number(sessionStorage.getItem("userId"));
        let response = await paymentService.getPayoutAccounts(userId, type);
        this.payoutAccounts = response?.data?.data ?? [];
        if (this.payoutAccounts.length > 0) {
          this.transferModel.payoutAccountId = this.payoutAccounts[0].id;
        }
      } catch (e) {
        showMessage("error", "Something went wrong!. plz try again later")
      }
      this.loading = false;
    },
    async markNotificationRead() {
      // eslint-disable-next-line no-unused-vars
      let response = await notificationService.markRead(this.$store.state.notificationType);
      this.$store.commit('setUpdateNotification', true);
    }
  },
  created() {
    this.getIbUsers();
    this.getAllUsers();
    this.getTransferAccounts();
    this.getAllPayoutAccounts(this.payoutType);
    this.markNotificationRead();
    this.getCurrencyConfig();
  }
}
</script>

<style scoped>

</style>