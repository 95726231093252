<template>
  <div>
    <div class="row align-items-center justify-between">
      <div class="col-12 col-lg-4 flex align-items-center">
        <router-link to="deposits">
          <n-button tertiary class="me-2" size="small">
            <i class="bi bi-arrow-left"></i>
          </n-button>
        </router-link>
        <BreedCrumb :show-bread="false"/>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 col-md-6">
        <el-form label-position="top" require-asterisk-position="right" v-if="fundingAccountType === 10">
          <el-form-item label="Payment Method" required>
            <template #label>
              <span class="text-sm">Account Details</span>
            </template>
            <el-select v-model="deposit.fundingAccountId" @change="changeAccount(deposit.fundingAccountId)">
              <el-option v-for="method in fundingAccounts" :label="method.bankName" :key="method.id"
                         :value="method.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <template #label>
              <span class="text-xs">Account Details</span>
            </template>
            <template #default>
              <n-table size="small" :single-line="false" bordered v-loading="methodLoad">
                <tbody>
                <tr>
                  <td class="text-xs" style="width: 50%">Account Title</td>
                  <td class="text-xs" style="width: 50%">{{ paymentMethod.accountTitle }}</td>
                </tr>
                <tr>
                  <td class="text-xs">Account Number</td>
                  <td class="text-xs">
                    <div class="d-flex align-items-center">
                      <span>{{ paymentMethod.accountNumber }}</span>
                      <div class="flex-grow"></div>
                      <el-tooltip content="Copy to clipboard">
                        <n-button tertiary size="tiny" @click="copyText(paymentMethod.accountNumber)">
                          <i class="bi bi-clipboard"></i>
                        </n-button>
                      </el-tooltip>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="text-xs">Branch</td>
                  <td class="text-xs">{{ paymentMethod.branch }}</td>
                </tr>
                <tr>
                  <td class="text-xs">Currency</td>
                  <td class="text-xs">{{ paymentMethod.currency }}</td>
                </tr>

                </tbody>
              </n-table>
            </template>
          </el-form-item>
          <el-form-item required>
            <template #label>
              <span class="text-sm">To Account</span>
            </template>
            <el-select v-model="deposit.depositAccountId">
              <el-option
                  v-for="account in accounts"
                  :key="account.id"
                  :label="account.accountName + ' (' + account.accountNumber + ')'"
                  :value="account.id"
              >
                <template #default>
                  <span>{{ account.accountName }} ({{ account.accountNumber }})</span>
                </template>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <template #label>
              <span class="text-sm">Amount</span>
            </template>
            <el-input type="number" style="text-align: left !important;" class="w-100 text-start" :controls="false"
                      v-model="deposit.amount">
              <template #prefix>
                <el-icon v-if="this.fundingAccountType === 20">
                  <i class="bi bi-currency-dollar"></i>
                </el-icon>
                <span v-else>PKR</span>
              </template>
            </el-input>
            <el-collapse-transition>
              <span v-if="deposit.amount < 10"
                    class="text-xs text-danger">Deposit amount must be greater than 10.</span>
            </el-collapse-transition>
          </el-form-item>
          <div class="bg-gray-50 h-20 my-2 px-3 align-items-center d-flex justify-content-between">
            <span>To be deposit</span>
            <div>
              <span class="text-2xl font-semibold">{{ calculatedUsd(deposit.amount) }}</span><span>USD</span>
            </div>
          </div>
          <n-button @click="goToVerify()"
                    :disabled="deposit.amount < 10 || deposit.depositAccountId < 1 || deposit.fundingAccountId < 1"
                    :color="colors.logoPrimary">
            Next
          </n-button>
        </el-form>
        <el-form label-position="top" require-asterisk-position="right" v-if="fundingAccountType === 20">
          <el-form-item label="Payment Method" required>
            <template #label>
              <span class="text-sm">TRC20 Account Details</span>
            </template>
            <el-select v-model="deposit.fundingAccountId" @change="changeAccount(deposit.fundingAccountId)">
              <el-option v-for="method in fundingAccounts" :label="method.bankName" :key="method.id"
                         :value="method.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item required>
            <template #label>
              <span class="text-sm">To Account</span>
            </template>
            <el-select v-model="deposit.depositAccountId">
              <el-option
                  v-for="account in accounts"
                  :key="account.id"
                  :label="account.accountName + ' (' + account.accountNumber + ')'"
                  :value="account.id"
              >
                <template #default>
                  <span>{{ account.accountName }} ({{ account.accountNumber }})</span>
                </template>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <template #label>
              <span class="text-sm">Amount</span>
            </template>
            <el-input type="number" style="text-align: left !important;" class="w-100 text-start" :controls="false"
                      v-model="deposit.amount">
              <template #prefix>
                <el-icon>
                  <i class="bi bi-currency-dollar"></i>
                </el-icon>
              </template>
            </el-input>
            <el-collapse-transition>
              <span v-if="deposit.amount < 10"
                    class="text-xs text-danger">Deposit amount must be greater than 10.</span>
            </el-collapse-transition>
          </el-form-item>

          <el-collapse-transition>
            <el-form-item>
              <div class="d-flex flex-column align-items-start">
                <span class="text-xs">To deposit funds, make a transfer to the blockchain address below. Then continue and upload screenshot of the deposit in the next screen</span>
                <span class="text-xs mt-2">Your unique TRON account address</span>

                <span class="text-base font-semibold d-block">{{ paymentMethod.accountNumber }}</span>
                <div class="d-flex">
                  <n-button class="mt-1" :color="colors.logoPrimary" @click="copyText(paymentMethod.accountNumber)">
                    <i class="bi bi-copy"></i>
                    <span class="ms-2">Copy Address</span>
                  </n-button>
                  <router-link to="accounts" class="m-0">
                    <n-button class="ms-2 mt-1" tertiary>

                      <span>Go to My Accounts</span>
                    </n-button>
                  </router-link>
                </div>
              </div>
              <div class="mt-2">
                <n-qr-code error-correction-level="H" :value="paymentMethod.accountNumber"/>
              </div>
            </el-form-item>
          </el-collapse-transition>
          <!--          <div class="bg-gray-50 h-20 my-2 px-3 align-items-center d-flex justify-content-between">
                      <span>To be deposit</span>
                      <div>
                        <span class="text-2xl font-semibold">{{ calculatedPkr(deposit.amount) }}</span><span>PKR</span>
                      </div>
                    </div>-->
          <n-button v-if="showQrCode" @click="goToVerify()"
                    :disabled="deposit.amount < 10 || deposit.depositAccountId < 1 || deposit.fundingAccountId < 1"
                    :color="colors.logoPrimary">
            Next
          </n-button>
          <n-button v-else :color="colors.logoPrimary" @click="showQrCode=true">Continue</n-button>


        </el-form>

      </div>
    </div>
    <div v-if="loading" class="spinner-border cstm-loading" role="status">

    </div>
  </div>

</template>
<script>
import BreedCrumb from "@/components/ui/breadcrumb.vue";
import {NTable, NButton, useMessage, NQrCode} from "naive-ui";
import colors from "@/utility/colors"
import accountService from "@/services/accountService";
import showMessage from "@/utility/showMessage";
import fundingAccountService from "@/services/fundingAccountService";
import settingsService from "@/services/settingsService";

export default {
  name: "NewDeposit",
  components: {NQrCode, NButton, NTable, BreedCrumb},
  data() {
    return {
      loading: true,
      methodLoad: false,
      showQrCode: false,
      useMessage: useMessage(),
      config: {},
      deposit: {
        transactionId: 0,
        accountId: 0,
        amount: 0,
        currency: "PKR",
        transactionType: 10,
        transactionStatus: 10,
        fundingAccountId: "",
        "source": 0,
        "depositAccountId": 0,
        "transactionFee": 0,
        "userRole": Number(this.$store.state.role) ?? 30,
        "documentJson":"",
        "userId": Number(sessionStorage.getItem("userId"))
      },
      paymentMethod: {},
      fundingAccounts: [],
      accounts: [],
      fundingAccountType: 10,
      filter: {
        "pageSize": 10,
        "page": 1,
        "filterText": "",
        "filterTag": "",
        "userId": Number(sessionStorage.getItem("userId")) ?? 0,
        "tradingAccountType": 100,
        "belongsToAdmin": true,
        "role": Number(sessionStorage.getItem("role")),
      }
    }
  },

  methods: {
    calculatedUsd(amount) {
      if (amount < 1 || typeof Number(this.config?.value) !== 'number') return amount;
      return (amount / Number(this.config?.value)).toFixed(2);
    },
    calculatedPkr(amount) {
      if (amount < 1 || typeof Number(this.config?.value) !== 'number') return amount;
      return (amount * Number(this.config?.value)).toFixed(2);
    },
    goToVerify() {
      this.$store.state.depositAccount = this.deposit;
      this.$router.push('/verify-deposit')
    },
    copyText(text) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
          //this.useMessage.success("Copied to clipboard", {duration: 1000});
          showMessage("info", "Copied to clipboard");
          // eslint-disable-next-line no-unused-vars
        }).catch(err => {
          showMessage("error", "Copy failed");
        });
      }
    },
    changeAccount(methodId) {
      this.methodLoad = true;
      this.paymentMethod = this.fundingAccounts.find(x => x.id === methodId) ?? {}
      setTimeout(() => this.methodLoad = false, 500)

    },
    async getAllAccounts() {
      this.loading = true;
      try {
        let response = await accountService.getTransactionsAccount(this.filter);
        this.accounts = response?.data?.data ?? [];
        if (this.accounts.length > 0 && this.$store.state.depositAccountId < 1) {
          this.deposit.tradingAccountId = this.accounts[0].id;
        }
        this.pagination = response?.data?.pagination;
      } catch (e) {
        showMessage("error", "Something went wrong");
      }
      this.loading = false;
    },
    async getFundingAmount(type) {
      this.loading = true;
      try {
        let response = await fundingAccountService.getAll(type);
        this.fundingAccounts = response?.data?.data ?? [];
        if (this.fundingAccounts.length > 0) {
          this.deposit.fundingAccountId = this.fundingAccounts[0].id;
          this.paymentMethod = this.fundingAccounts[0];
        }
        this.pagination = response?.data?.pagination;
      } catch (e) {
        showMessage("error", "Something went wrong!.")
      }
      this.loading = false;
    },
    async getCurrencyConfig() {
      let response = await settingsService.getByKey("USD");
      this.config = response?.data ?? {};

    },
    checkLogin() {
      return (sessionStorage.getItem("loggedIn") === null || sessionStorage.getItem("loggedIn") === undefined || sessionStorage.getItem("loggedIn") === false);
    }
  },
  created() {
    if (!this.checkLogin) {
      this.$router.push('/');
      return;
    }
    this.getAllAccounts();
    this.fundingAccountType = this.$store.state.fundingAccountType;
    this.deposit.source = this.fundingAccountType;
    if (this.$store.state.depositAccountId > 0) {
      this.deposit.depositAccountId = this.$store.state.depositAccountId;
    } else {
      this.deposit.depositAccountId = "";
    }

    this.getFundingAmount(this.fundingAccountType);
    this.getCurrencyConfig();

  },
  computed: {
    colors() {
      return colors;
    }
  },

}
</script>

<style scoped>
.el-input-number .el-input__inner {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  line-height: 1;
  text-align: start !important;
  display: flex !important;
  justify-content: start !important;
}
</style>