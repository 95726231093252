<template>
  <div>
    <div
        class="d-none d-md-flex align-items-center cstm-header px-2 position-relative border-b"
    >
      <n-button
          v-if="togglePrimaryBtn"
          @click="toggleSidebar()"
          :color="colors.blackPrimary"
          size="tiny"
          style="position: absolute; left: -15px"
      >
        <i
            :class="[
            'bi bi-chevron-double-left',
            collapsed ? ' rotate-right' : 'rotate-left',
          ]"
        ></i>
      </n-button>

      <img
          v-else
          src="../../assets/img/logos/logo1.png"
          alt="Logo"
          :width="30"
      />

      <div class="d-flex ms-2" v-if="user?.userReferralCode">
        <span>Referral Link: {{ user?.userReferralCode }}</span>
        <el-tooltip content="copy referral link">
          <n-button
              class="ms-2"
              tertiary
              size="tiny"
              @click="copyLink(user?.userReferralCode)"
          >
            <i class="bi bi-copy"></i>
          </n-button>
        </el-tooltip>
      </div>
      <div class="flex-grow"></div>
      <n-space justify="center" align="center">
        <n-button quaternary>
          <i class="mgc_wallet_3_line me-2"></i>
          <span>{{ getFormattedAmount(data?.totalAmount)}} {{ data?.currency }}</span>
        </n-button>
        <n-popover :overlap="false" placement="bottom-end" trigger="hover">
          <template #trigger>
            <n-badge
                :value="notifications.length"
                :color="colors.logoPrimary"
                :max="15"
                :show-zero="false"
                :processing="true"
            >
              <i class="bi bi-bell text-lg action-btn ms-2"></i>
            </n-badge>
          </template>
          <div class="large-text">
            <div class="infinite-list-wrapper" style="overflow: auto">
              <n-list
                  clickable
                  hoverable
                  show-divider
                  v-if="notifications.length > 0"
                  style="max-height: 300px; max-width: 350px"
              >
                <n-list-item
                    v-for="i in notifications"
                    :key="i.id"
                    @click="goToRoute(i.type)"
                >
                  <template #default>
                    <span>{{ i.title }}</span
                    ><br/>
                    <n-ellipsis
                        :line-clamp="1"
                        class="text-xs text-secondary"
                    >{{ i.description }}
                    </n-ellipsis
                    >
                  </template>
                </n-list-item>
              </n-list>
              <div v-else>
                <n-empty size="small" description="">
                  <template #default>
                    <span class="text-xs">No Unread Notification</span>
                  </template>
                  <template #icon>
                    <i class="bi bi-chat-dots"></i>
                  </template>
                </n-empty>
              </div>
            </div>
          </div>
        </n-popover>
        <n-dropdown
            trigger="hover"
            :options="options"
            @select="handleSelect"
            class="shadow-sm"
            style="min-width: 150px"
        >
          <i class="bi bi-person text-lg action-btn ms-2 me-2"></i>
        </n-dropdown>
      </n-space>
    </div>
    <div
        class="d-flex d-md-none align-items-center justify-content-between cstm-header px-3 position-relative border-b"
    >
      <div
          v-if="!togglePrimaryBtn"
          class="px-1 rounded cursor-pointer"
          @click="showDrawer = !showDrawer"
      >
        <i class="bi bi-list text-2xl font-bold"></i>
      </div>
      <img src="../../assets/img/logos/logo2.png" alt="Logo" :width="70"/>
      <div>
        <n-popover :overlap="false" placement="bottom-end" trigger="hover">
          <template #trigger>
            <n-badge
                :value="notifications.length"
                :color="colors.logoPrimary"
                :max="15"
                :show-zero="false"
                :processing="true"
            >
              <i class="bi bi-bell text-lg action-btn ms-2"></i>
            </n-badge>
          </template>
          <div class="large-text w-100">
            <div class="infinite-list-wrapper" style="overflow: auto">
              <n-list
                  clickable
                  hoverable
                  show-divider
                  v-if="notifications.length > 0"
                  style="max-height: 300px; max-width: 350px"
              >
                <n-list-item
                    v-for="i in notifications"
                    :key="i.id"
                    @click="goToRoute(i.type)"
                >
                  <template #default>
                  <span>{{ i.title }}</span
                  ><br/>
                    <n-ellipsis :line-clamp="1" class="text-xs text-secondary">{{
                        i.description
                      }}
                    </n-ellipsis>
                  </template>
                </n-list-item>
              </n-list>
              <div v-else>
                <n-empty size="small" description="">
                  <template #default>
                    <span class="text-xs">No Unread Notification</span>
                  </template>
                  <template #icon>
                    <i class="bi bi-chat-dots"></i>
                  </template>
                </n-empty>
              </div>
            </div>
          </div>
        </n-popover>
        <n-dropdown
            trigger="hover"
            :options="options"
            @select="handleSelect"
            class="shadow-sm"
            style="min-width: 150px"
        >
          <i class="bi bi-person text-lg action-btn ms-3 me-2"></i>
        </n-dropdown>
      </div>
    </div>

    <n-modal
        v-model:show="profileDialog"
        :show-icon="false"
        preset="dialog"
        title="Profile"
    >
      <template #action>
        <div class="d-flex justify-content-end">
          <n-button secondary @click="profileDialog = false">Close</n-button>
                    <n-button type="primary" class="ms-2" @click="updateUser()">Update</n-button>
        </div>
      </template>
      <template #default>
        <n-form>
          <n-form-item label="Full Name" class="my-0">
            <n-input
                placeholder=""
                disabled
                v-model:value="user.fullName"
            ></n-input>
          </n-form-item>
          <n-form-item label="Username" class="my-0">
            <n-input
                placeholder=""
                v-model:value="user.email"
            ></n-input>
          </n-form-item>
          <n-form-item label="Password" class="my-0">
            <n-input
                placeholder="" @keydown.space.prevent
                type="password"
                 show-password-on="click"
                v-model:value="user.password"
            ></n-input>
          </n-form-item>
          <n-form-item label="Referral Link" class="my-0">
            <n-input-group>
              <n-input
                  placeholder=""
                  disabled
                  v-model:value="user.userReferralCode"
              >
              </n-input>
              <el-tooltip
                  v-if="!user?.userReferralCode"
                  content="Request Referral Code"
              >
                <n-button
                    @click="requestReferral(user.id)"
                    :color="colors.blackPrimary"
                >Request
                </n-button
                >
              </el-tooltip>
            </n-input-group>
          </n-form-item>
        </n-form>
      </template>
    </n-modal>
    <n-drawer
        :z-index="999"
        v-model:show="showDrawer"
        :width="300"
        placement="left"
    >
      <n-drawer-content>
        <template #header>
          <!-- <span class="text-xl md:text-4xl font-semibold transition-all">Medatiq</span> -->
          <!--          <img src="../../assets/img/logos/logo2.png" alt="Logo" :width="140">-->
          <div class="w-100">
            <div class="px-3 d-flex align-items-center w-100">
              <el-icon class="me-2">
                <i class="bi bi-person"></i>
              </el-icon>
              <div class="d-flex flex-column">
                <span class="font-normal">{{ user?.fullName }}</span>
                <n-ellipsis
                    style="max-width: 100px"
                    class="text-secondary text-sm"
                >{{ user?.email }}
                </n-ellipsis
                >
              </div>
            </div>
            <el-divider class="mt-1 w-100" v-if="user?.userReferralCode"></el-divider>
            <div class="text-sm d-flex align-items-center px-3" v-if="user?.userReferralCode">

              <el-tooltip content="copy referral link">
                <n-button
                    class="me-2"
                    tertiary
                    size="tiny"
                    @click="copyLink(user?.userReferralCode)"
                >
                  <i class="bi bi-copy"></i>
                </n-button>
              </el-tooltip>
              <div>
                <span>Referral Code: </span><br>
                <span>{{ user?.userReferralCode }}</span>
              </div>
            </div>
            <el-divider class="mt-1 w-100"></el-divider>
            <div class="px-3 d-flex align-items-center font-normal w-100">
              <i class="mgc_wallet_3_line me-2"></i>
              <span>{{ getFormattedAmount(data?.totalAmount) }} {{ data?.currency }}</span>
            </div>
          </div>
        </template>
        <template #default>
          <el-menu
              class="el-menu-vertical-demo w-100 custom-sidebar border-0 d-flex flex-column mt-1"
              style="height: calc(100vh - 120px)"
              router
          >
            <el-menu-item
                v-if="Number(role) === 10 || Number(role) === 20"
                index="1"
                route="home"
                :class="{ 'active-menu-item': $route.path === '/home' }"
                @click="showDrawer = false"
            >
              <el-icon>
                <i class="bi bi-grid"></i>
              </el-icon>
              <template #title>
                <span>Dashboard</span>
              </template>
            </el-menu-item>
            <el-menu-item
                index="2"
                route="accounts"
                v-if="Number(role) === 30"
                :class="{ 'active-menu-item': $route.path === '/accounts' }"
                @click="showDrawer = false"
            >
              <el-icon>
                <i class="bi bi-grid"></i>
              </el-icon>
              <template #title>
                <span>My Accounts</span>
              </template>
            </el-menu-item>
            <el-menu-item
                index="3"
                route="deposits"
                :class="{ 'active-menu-item': $route.path === '/deposits' }"
                @click="showDrawer = false"
            >
              <el-icon>
                <i class="bi bi-arrow-down-circle"></i>
              </el-icon>
              <template #title>
                <span>Deposit</span>
              </template>
            </el-menu-item>
            <el-menu-item
                index="4"
                route="withdraws"
                :class="{ 'active-menu-item': $route.path === '/withdraws' }"
                @click="showDrawer = false"
            >
              <el-icon>
                <i class="bi bi-arrow-up-right-circle"></i>
              </el-icon>
              <template #title>
                <span>Withdraw</span>
              </template>
            </el-menu-item>
            <el-menu-item
                index="5"
                route="transactions"
                :class="{ 'active-menu-item': $route.path === '/transactions' }"
                @click="showDrawer = false"
            >
              <el-icon>
                <!--          <i class="bi bi-currency-bitcoin"></i>-->
                <i class="bi bi-currency-dollar"></i>
              </el-icon>
              <template #title>
                <span>Transactions</span>
              </template>
            </el-menu-item>
            <el-menu-item
                index="11"
                route="partners"
                :class="{ 'active-menu-item': isActive('/partners') }"
                @click="store.state.notificationType = 0; showDrawer = false"
            >
              <el-icon>
                <!--          <i class="bi bi-currency-bitcoin"></i>-->
                <i class="bi bi-layout-text-sidebar"></i>
              </el-icon>
              <template #title>
                <span>IB Dashboard</span>
              </template>
            </el-menu-item>
            <el-menu-item index="7" route="profile" v-if="Number(role) === 30"
                          @click="showDrawer = false">
              <el-icon>
                <i class="bi bi-person "></i>
              </el-icon>
              <template #title>
                <span>Profile</span>
              </template>
            </el-menu-item>
            <el-menu-item
                v-if="Number(role) === 10 || Number(role) === 20"
                index="6"
                route="users"
                :class="{ 'active-menu-item': $route.path === '/users' }"
                @click="showDrawer = false"
            >
              <el-icon>
                <i class="bi bi-people"></i>
              </el-icon>
              <template #title>
                <span>Users</span>
              </template>
            </el-menu-item>
            <el-menu-item
                v-if="Number(role) === 10 || Number(role) === 20"
                index="8"
                route="tickets"
                :class="{ 'active-menu-item': $route.path === '/tickets' }"
                @click="showDrawer = false"
            >
              <el-icon>
                <i class="bi bi-patch-question"></i>
              </el-icon>
              <template #title>
                <span>Support Tickets</span>
              </template>
            </el-menu-item>
            <el-menu-item
                v-if="Number(role) === 10 || Number(role) === 20"
                index="9"
                route="useraccounts"
                :class="{ 'active-menu-item': $route.path === '/useraccounts' }"
                @click="showDrawer = false"
            >
              <el-icon>
                <i class="bi bi-person-circle"></i>
              </el-icon>
              <template #title>
                <span>Users Accounts</span>
              </template>
            </el-menu-item>
            <el-menu-item v-if="Number(role) === 10 || Number(role) === 20" index="38" route="funding-accounts"
                          :class="{ 'active-menu-item': isActive('/funding-accounts') }">
              <el-icon>
                <i class="bi bi-bank"></i>
              </el-icon>
              <template #title>
                <span>Funding Accounts</span>
              </template>
            </el-menu-item>
            <!--            <el-menu-item v-if="Number(role) === 10 || Number(role) === 20" index="37" route="settings"
                                      :class="{ 'active-menu-item': isActive('/settings') }">
                          <el-icon>
                            <i class="bi bi-gear"></i>
                          </el-icon>
                          <template #title>
                            <span>Settings</span>
                          </template>
                        </el-menu-item>-->
            <div class="flex-grow"></div>
          </el-menu>
        </template>
      </n-drawer-content>
    </n-drawer>

    <audio
        ref="notificationSound"
        :src="require('@/assets/notification.mp3')"
        preload="auto"
    ></audio>
    <n-spin v-if="loading" class="mn-spin" :stroke-width="12" :size="25"
            style="z-index: 999!important;"></n-spin>
  </div>
</template>
<script>
const notificationSound = require("../../assets/notification.mp3");
import colors from "@/utility/colors";
import store from "@/store/index";
import callHub from "@/callHub";
import {
  NBadge,
  NButton,
  NDrawer,
  NDrawerContent,
  NDropdown,
  NEllipsis,
  NEmpty,
  NForm,
  NFormItem,
  NInput,
  NInputGroup,
  NList,
  NListItem,
  NModal,
  NPopover,
  NSpace, NSpin,
} from "naive-ui";
import {NIcon} from "naive-ui";
import {h} from "vue";
import {
  Person16Regular as Person,
  DoorArrowRight20Regular as Logout,
} from "@vicons/fluent";
import accountService from "@/services/accountService";
import userService from "@/services/userService";
import showMessage from "@/utility/showMessage";
import notificationService from "@/services/notificationService";

export default {
  name: "HeaderComp",
  components: {
    NSpin,
    NEllipsis,
    NEmpty,
    NListItem,
    NList,
    NPopover,
    NBadge,
    NInputGroup,
    NDrawerContent,
    NDrawer,
    NInput,
    NFormItem,
    NForm,
    NModal,
    NDropdown,
    NButton,
    NSpace,
  },
  data() {
    return {
      loading: false,
      showDrawer: false,
      profileDialog: false,
      noMore: false,
      notificationSound: notificationSound,
      data: {},
      user: {
        fullName: "",
        email: "",
        password: "",
      },
      colors: colors,
      store: store,
      togglePrimaryBtn: true,
      notifications: [],
      visibleNotifications: [],
      loadCount: 10,
      options: [
        {
          label: "Profile",
          key: "profile",
          icon: this.renderIcon(Person),
        },
        {
          label: "Logout",
          key: "logout",
          icon: this.renderIcon(Logout),
        },
      ],
    };
  },
  async mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    await callHub.start();
    callHub.client.on("Deposit", () => {
      this.getNotifications();
    });
    callHub.client.on("Removed", () => {
      this.getAll();
    });
    callHub.client.on("CommissionWithdraw", () => {
      this.getNotifications();
    });
    callHub.client.on("Referral", () => {
      this.getNotifications();
    });
    callHub.client.on("Margin", () => {
      this.getNotifications();
    });
    callHub.client.on("Rename", () => {
      this.getNotifications();
    });
    callHub.client.on("Transfer", () => {
      this.getNotifications();
    });
    callHub.client.on("Withdraw", () => {
      this.getNotifications();
    });
    callHub.client.on("Commission", () => {
      this.getNotifications();
    });
    callHub.client.on("PasswordChange", () => {
      this.getNotifications();
    });
    callHub.client.on("Delete", () => {
      this.getNotifications();
    });
    callHub.client.on("Removed", () => {
      this.getNotifications();
    });
    callHub.client.on("AccountApproved", () => {
      this.getNotifications();
    });
    callHub.client.on("AccountCreated", () => {
      this.getNotifications();
    });
    callHub.client.on("CommissionTransferred", () => {
      this.getNotifications();
    });
    callHub.client.on("UpdateStatus", () => {
      this.getNotifications();
      this.getAllAmount();
    });
    callHub.client.on("SignUp", () => {
      this.getNotifications();
    });
    callHub.client.on("ProfileCompleted", () => {
      this.getNotifications();
    });
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    getFormattedAmount(amount){
      if(amount < 1) return amount;
      return Number(amount).toFixed(2)
    },
    isActive(route) {
      return this.$route.path === route;
    },
    goToRoute(type) {
      switch (type) {
        case 10:
          this.store.state.notificationType = 10;
          this.$router.push("/tickets");
          break;
        case 20:
          this.store.state.notificationType = 20;
          this.$router.push("/deposits");
          break;
        case 30:
          this.store.state.notificationType = 30;
          this.$router.push("/withdraws");
          break;
        case 40:
          this.store.state.notificationType = 40;
          this.$router.push("/transactions");
          break;
        case 50:
          this.store.state.notificationType = 50;
          this.$router.push("/transactions");
          break;
        case 60:
          this.store.state.notificationType = 60;
          this.$router.push("/partners");
          break;
        case 70:
          this.store.state.notificationType = 70;
          this.$router.push("/transactions");
          break;
        case 80:
          this.store.state.notificationType = 80;
          this.$router.push("/useraccounts");
          break;
        case 100:
          this.store.state.notificationType = 100;
          this.$router.push("/useraccounts");
          break;
        case 90:
          this.store.state.notificationType = 90;
          this.$router.push("/users");
          break;
        case 110:
          this.store.state.notificationType = 90;
          this.$router.push("/accounts");
          break;
        case 120:
          this.store.state.notificationType = 90;
          this.$router.push("/accounts");
          break;
      }
    },
    copyLink(referral) {
      //const link = "http://localhost:8080?referral=" + referral;
      const link = "http://my.medatiqmarket.com?referral=" + referral;
      navigator.clipboard.writeText(link);
    },
    handleResize() {
      this.togglePrimaryBtn = window.innerWidth > 768;
    },
    renderIcon(icon) {
      return () => {
        return h(NIcon, null, {
          default: () => h(icon),
        });
      };
    },

    handleSelect(key) {
      switch (key) {
        case "profile":
          this.profileDialog = true;
          break;
        case "logout":
          this.logout();
          break;
      }
    },
    logout() {
      sessionStorage.clear();
      localStorage.clear();
      this.$store.state.loggedIn = false;
      this.$store.state.profileComplete = true;
      this.$router.push("/");
    },
    toggleSidebar() {
      this.store.state.sidebarCollapsed = !this.store.state.sidebarCollapsed;
    },
    async getAllAmount() {
      try {
        let response = await accountService.getAllAmount(
            Number(sessionStorage.getItem("userId"))
        );
        this.data = response?.data;
      } catch (e) {
        // eslint-disable-next-line no-empty
      }
    },
    async getUser() {
      const userId = Number(sessionStorage.getItem("userId"));
      try {
        let response = await userService.getUser(userId);

        this.user = response?.data;
      } catch (e) {
        // eslint-disable-next-line no-empty
      }
    },
    async requestReferral(id) {
      this.profileDialog = false;
      this.loading = true;

      try {
        let response = await userService.generateReferral(id);
        if (response?.data?.isSuccessful) {
          showMessage("success", "Referral request sent to Admin");
        } else {
          showMessage("error", "Something went wrong. Plz try again later");
        }
      } catch (e) {
        // eslint-disable-next-line no-empty
      }
      this.loading = false;
    },
    playNotificationSound() {
      this.$refs.notificationSound.play();
    },
    async updateUser(){
      this.loading = true;
      this.profileDialog = false;
      try {
        let response = await userService.update(this.user);
        if(response?.data?.isSuccessful){
          showMessage("success", "User updated");
          await  this.getUser();
        }
      }
      catch (e){
        showMessage("error", e?.message ?? "User update failed")
      }
      this.loading = false;
    },


    async getNotifications() {
      try {
        //const oldNotifications = this.notifications.length;
        let response = await notificationService.all(
            Number(sessionStorage.getItem("userId"))
        );
        this.notifications = response?.data?.data;
        // if (this.notifications.length > oldNotifications) {
        //   let note = new Audio(this.notificationSound);
        //   note.addEventListener("canplaythrough", () => {
        //     /* the audio is now playable; play it if permissions allow */
        //     note.play(); // the audio now can be played
        //   });
        // }
      } catch (e) {
        // eslint-disable-next-line no-empty
      }
    },
  },
  created() {
    this.getAllAmount();
    this.getUser();
    this.getNotifications();
  },
  watch: {
    updateHeader(newValue) {
      if (newValue) {
        this.getAllAmount();
        this.$store.state.updateHeader = false; // Reset the state
      }
    },
    updateNotification(newVal) {
      if (newVal) {
        this.getNotifications();
        this.$store.commit("setUpdateNotification", false);
      }
    },
  },
  computed: {
    role() {
      return Number(sessionStorage.getItem("role"));
    },
    updateHeader() {
      return this.$store.state.updateHeader;
    },
    collapsed() {
      return this.store.state.sidebarCollapsed;
    },
    updateNotification() {
      return this.$store.state.updateNotification;
    },
  },
};
</script>

<style scoped>
.dropdown-item:focus {
  background-color: #18a058 !important;
}

.rotate-left {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.rotate-right {
  transform: rotate(180deg);
  transition: transform 0.5s ease;
}

.el-menu-item.is-active {
  color: #fff !important;
  background-color: #020617 !important;
}

.active-menu-item {
  color: #fff !important;
  background-color: #020617 !important;
}

/*
.el-menu-item.is-active {
  color: #18a058 !important;
  background-color: #18a05829 !important;
}
*/
.el-menu-item:hover {
  color: #fff !important;
  background-color: #020617 !important;
}

.el-menu-item.is-active.active-menu-item:hover {
  color: #fff !important;
  background-color: #020617e8 !important;
}

.active-menu-item:hover {
  color: #fff !important;
  background-color: #020617 !important;
}

/*.el-menu-item:hover{
  background-color: #18a05829 !important;
}*/
.el-menu-item {
  border-radius: 5px;
  height: 40px;
  color: #64748b;
  margin: 5px 0 5px 0 !important;
}

.n-drawer-header__main {
  width: 100% !important;
}
</style>
