<template>
  <div class="b-container-fluid ">
    <div class="row justify-content-center login-wrapper align-items-center">
      <div class="col-12 col-md-6 col-lg-4">
        <n-card v-if="forgotPassword">
          <div class="py-2">

            <span class="text-2xl font-bold">Forgot Password?</span>
            <div class="mt-5">
              <el-form label-position="top">
                <el-form-item>
                  <template #label>Enter your email to send password</template>
                  <n-input v-model:value="userName" placeholder="Username"></n-input>
                </el-form-item>
              </el-form>
              <div class="mt-3">
                <div class="d-flex justify-content-between">
                  <n-button tertiary @click="forgotPassword = false">
                    <i class="bi bi-arrow-left"></i>
                    <span class="ms-1">Back</span>
                  </n-button>
                  <n-button :loading="loading" type="primary" :disabled="userName === ''"  @click="resetPassword">
                    <span class="" >Submit</span>
                  </n-button>
                </div>
              </div>
            </div>
          </div>
        </n-card>
        <n-card v-else>
          <div class="text-center py-2">
            <span class="text-2xl font-bold">Welcome to Medatiq</span>
          </div>
          <n-tabs
              v-model:value="activeTab"
              class="card-tabs"
              default-value="signin"
              size="large"
              animated
              pane-wrapper-style="margin: 0 -4px"
              pane-style="padding-left: 4px; padding-right: 4px; box-sizing: border-box;"
              type="line"
              justify-content="space-evenly"
          >
            <n-tab-pane justify-content="space-evenly" name="signin" tab="Sign in">
              <template #tab>
                <span class="text-black">Sign in</span>
              </template>

              <el-form label-position="top">
                <el-form-item>
                  <template #label>Email</template>
<!--                  <n-input  :status="emailStatus" @keyup.space.prevent @input="validateEmail(user.userName)"-->
<!--                           v-model:value="user.userName">-->

<!--                  </n-input>-->
                  <el-input type="email" @keyup.space.prevent @input="validateEmail(user.userName)" size="large"
                            v-model="user.userName"></el-input>
                  <el-collapse-transition>
                  <span class="text-caption txt-danger" v-if="msg.email">{{
                      msg.email
                    }}</span>
                  </el-collapse-transition>
                </el-form-item>
                <el-form-item>
                  <template #label>Password</template>
<!--                  <n-input :status="passwordStatus" @keyup.enter="login()" @keyup.space.prevent-->
<!--                           @input="checkPassword(user.password)"-->
<!--                           v-model:value="user.password" type="password"-->
<!--                           showPasswordOn="click"/>-->
                  <el-input @keyup.enter="login()" @keyup.space.prevent size="large"
                            @input="checkPassword(user.password)"
                            v-model="user.password" type="password" show-password show-word-limit></el-input>
                  <el-collapse-transition>
                  <span class="text-caption txt-danger" v-if="msg.password">{{
                      msg.password
                    }}</span>
                  </el-collapse-transition>
                </el-form-item>
              </el-form>
              <n-button :color="colors.logoPrimary" class="mt-5" :text-color="colors.black" @click="login()" block
                        strong :loading="loading">
                Continue
              </n-button>
              <el-divider>OR</el-divider>
              <!--              <n-button block secondary>-->
              <!--                <template #icon>-->
              <!--                  <img src="../../assets/svg/google.svg" alt="google">-->
              <!--                </template>-->
              <!--                <span> Continue with Google</span>-->
              <!--              </n-button>-->
              <div class="text-center text-blue-600 py-3">
                <n-button text type="success" @click="forgotPassword = true">
                  <span>I forgot my password</span>
                </n-button>
              </div>
            </n-tab-pane>
            <n-tab-pane  name="signup" tab="Sign up">
              <template #tab>
                <span class="text-black">Create an account</span>
              </template>
              <el-form label-position="top">
                <el-form-item>
                  <template #label>Country / Region of residence</template>
                  <n-select filterable v-model:value="signupUser.country" :status="countryStatus"
                            @blur="checkCountry(signupUser.country)"
                            :options="countries"/>
                  <span v-if="countryStatus === 'error'" class="text-danger text-xs">Please specify your country or region of residence</span>
                </el-form-item>
                <el-form-item>
                  <template #label>Fullname</template>
                  <n-input
                      v-model:value="signupUser.fullName">

                  </n-input>
                </el-form-item>
                <el-form-item>
                  <template #label>Email</template>
                  <n-input :status="emailStatus" @keyup.space.prevent
                           @input="validateEmail(signupUser.userName)"
                           v-model:value="signupUser.userName">

                  </n-input>
                  <el-collapse-transition>
                  <span class="text-caption txt-danger" v-if="msg.email">{{
                      msg.email
                    }}</span>
                  </el-collapse-transition>
                </el-form-item>

                <el-form-item>
                  <template #label>Password</template>
                  <el-tooltip effect="light">
                    <template #content>
                      <div class="pswd-indicator">
                        <span class="font-semibold">Password Strength</span>
                        <div class="p-0 m-0">
                          <i v-if="signupUser.password.length < 5" class="bi bi-x-circle-fill me-2 text-danger"></i>
                          <i v-else class="bi bi-check-circle-fill me-2 text-success"></i>
                          <span :class="signupUser.password.length < 5 ? 'text-secondary' : 'text-success'">At least five characters</span>
                        </div>
                        <div class="p-0 m-0">
                          <i v-if="!specialCh" class="bi bi-x-circle-fill me-2 text-danger"></i>
                          <i v-else class="bi bi-check-circle-fill me-2 text-success"></i>
                          <span
                              :class="!specialCh ? 'text-secondary' : 'text-success'">At least one special character</span>
                        </div>
                        <div>
                          <i v-if="!hasCapitalChar" class="bi bi-x-circle-fill me-2 text-danger"></i>
                          <i v-else class="bi bi-check-circle-fill me-2 text-success"></i>
                          <span
                              :class="!hasCapitalChar ? 'text-secondary' : 'text-success'">At least one capital character</span>
                        </div>
                        <div>
                          <i v-if="!hasNumber" class="bi bi-x-circle-fill me-2 text-danger"></i>
                          <i v-else class="bi bi-check-circle-fill me-2 text-success"></i>
                          <span :class="!hasNumber ? 'text-secondary' : 'text-success'">At least One number</span>
                        </div>
                      </div>
                    </template>
                    <template #label>Password</template>
                    <n-input minlength="5" @keyup.space.prevent @input="testPassword(signupUser.password)"
                             v-model:value="signupUser.password" type="password"
                             showPasswordOn="click"/>
                  </el-tooltip>
                </el-form-item>

                <el-form-item>
                  <template #label>Referral Code <span class="text-xs">(optional)</span></template>
                  <n-input
                      v-model:value="signupUser.referralCode">
                  </n-input>
                </el-form-item>
              </el-form>
              <n-button :loading="loading" :color="colors.logoPrimary" class="mt-3" :text-color="colors.black" block
                        strong
                        @click="signUp()">
                Sign up
              </n-button>
            </n-tab-pane>
          </n-tabs>
        </n-card>

      </div>
    </div>

  </div>
</template>
<script>


import {NButton, NCard, NInput, NSelect, NTabPane, NTabs} from "naive-ui";
import colors from "@/utility/colors";
import countries from "@/utility/countries"
import showMessage from "@/utility/showMessage"
import authService from "@/services/authService";
import axios from 'axios'
import store from "@/store";

export default {
  name: "LoginPage",
  computed: {
    colors() {
      return colors
    },
    countries() {
      return countries
    },

  },
  components: {NSelect, NTabs, NTabPane, NInput, NCard, NButton},
  data() {
    return {
      loading: false,
      forgotPassword: false,
      activeTab: "signin",
      userName: "",
      passwordStatus: "",
      emailStatus: "",
      countryStatus: "",
      msg: [],
      specialCh: false,
      hasCapitalChar: false,
      hasNumber: false,
      signupUser: {
        userName: "",
        password: "",
        country: "",
        referralCode: "",
        fullName: "",

      },
      user: {
        "username": "",
        "password": "",
        "newPassword": "",
        "frontDocument": "",
        "backDocument": ""
      },

      rules: {
        'user.userName': {
          required: true,
          message: 'Please input your email',
          trigger: ['input', 'blur']
        },
        'user.password': {
          required: true,
          message: 'Please input your password',
          trigger: ['input', 'blur']
        }
      }
    }
  },
  methods: {
    checkCountry(country) {
      if (!country) {
        this.countryStatus = 'error'
      } else {
        this.countryStatus = ''
      }
    },
    passwordCheck(password) {
      if (!password) {
        return 'error'
      } else {
        return ''
      }
    },
    validateEmail(value) {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        this.msg['email'] = '';
        this.emailStatus = ''
      } else if (!value) {
        this.msg['email'] = 'Please enter a your email address';
        this.emailStatus = 'error'
      } else {
        this.msg['email'] = 'Please enter a valid email address';
        this.emailStatus = 'error'
      }
    },
    checkPassword(value) {
      if (value) {
        this.msg['password'] = '';
        this.passwordStatus = ''
      } else {
        this.msg['password'] = 'Please enter a password';
        this.passwordStatus = 'error'
      }
    },
    testPassword(inputString) {
      // Define a regular expression to match any character that is not alphanumeric or whitespace
      const regex = /[^a-zA-Z0-9\s]/;
      const capitalCharRegex = /[A-Z]/;
      const numberRegex = /[0-9]/;
      // Test the input string against the regular expression
      this.specialCh = regex.test(inputString);
      this.hasCapitalChar = capitalCharRegex.test(inputString);
      this.hasNumber = numberRegex.test(inputString);
    },
    async login() {
      this.loading = true;
      try {

        // sessionStorage.setItem("loggedIn", true);
        // this.$store.state.loggedIn = true;
        // this.$router.push('/accounts');
        let response = await authService.login(this.user);
        if (response.data !== null && response.status === 200) {
          sessionStorage.setItem("loggedIn", true);
          this.$store.state.loggedIn = true;
          sessionStorage.setItem("token", response.data.token)
          sessionStorage.setItem("userId", response.data.userId)
          sessionStorage.setItem("role", response.data.role)
          sessionStorage.setItem("profileCompleted", response.data.profileCompleted)
          this.$store.state.role = response.data.role;
          this.$store.state.profileCompleted = response.data.profileCompleted;
          axios.defaults.headers.common["Authorization"] = "Bearer " + response.data.token;
          this.handleResize();
          if (response.data.role === 30) {
            this.$router.push('/accounts');
          } else {
            this.$router.push('/home');
          }
        }

      } catch (e) {
        showMessage("error", e?.response?.data.toString() ?? "Something went wrong")
      }

      this.loading = false;

    },
    async signUp() {
      this.testPassword(this.signupUser.password)
      if (!this.hasCapitalChar || !this.hasNumber || !this.hasCapitalChar || this.signupUser.password.length < 5) {
        showMessage("error", "Password too weak");
        return;
      }
      this.loading = true;
      try {
        let response = await authService.signup(this.signupUser);
        if (response.data.isSuccessful) {
          showMessage("success", "Signed up successfully. Please login with your email and password");
        } else {
          showMessage("success", response?.data?.msg)
        }

      } catch (e) {
        console.log(e)
        showMessage("error", "Signup failed!. Please try again later");
      }
      this.loading = false;
    },
    async resetPassword() {
      this.loading = true;
      try {
        let response = await authService.requestPassword(this.userName);
        if (response?.data?.isSuccessful) {
          showMessage("success", response?.data?.msg ?? "Password sent to email");
          this.forgotPassword = false;
        } else {
          showMessage("success", response?.data?.msg ?? "Password reset failed");
        }
      } catch (e) {
        showMessage("success", e?.data?.msg ?? "Password reset failed");
      }
      this.loading = false;
    },
    logoutUser(){
      sessionStorage.clear();
      localStorage.clear();
      this.$store.state.loggedIn = false;
      this.$store.state.loggedIn = false;
      this.$store.state.sidebarCollapsed = false;
      this.$store.state.sidebarCollapsed = false;
      this.$store.state.showSidebar = true;
    },
    handleResize() {
      store.state.showSidebar = window.innerWidth > 768;
    },

  },
  created() {
    this.logoutUser();
    const referral = this.$route.query.referral;
    const register = this.$route.query.register;
    if(register){
      this.activeTab = "signup";
    }
    if (referral) {
      this.signupUser.referralCode = referral;
      this.activeTab = "signup";

      // You can also store it in localStorage or Vuex if needed
    }
  }
};
</script>
<style scoped>
.my-input {
  display: flex !important;
  flex-direction: column !important;
  align-items: start !important;
  position: relative;
}

.pswd-indicator {
  font-size: 11px !important;
  margin-top: 5px;
}
</style>
