<template>
  <div>



    <n-spin v-if="loading" class="mn-spin" description="Loading" :stroke-width="12"></n-spin>
  </div>
</template>
<script>
import {NSpin} from "naive-ui";

export default {
  name: "IbDashboard",
  components: {NSpin},
  data() {
    return {
      loading: false,
    }
  }
}
</script>

<style scoped>

</style>