import axios from "axios";
import axiosConfig from "@/config/axiosConfig";

export default {
    getAllUrl: axiosConfig.apiBaseUrl + "Ticket/GetAll",
    allUrl: axiosConfig.apiBaseUrl + "Ticket/All",
    statusUrl: axiosConfig.apiBaseUrl + "Ticket/ChangeStatus?ticketId=",

    all(filter) {
        return axios.post(this.allUrl, filter)
    },
    getAll() {
        return axios.get(this.getAllUrl);
    },
    changeStatus(id, status) {
        return axios.get(this.statusUrl + id + "&status=" + status);
    }


}