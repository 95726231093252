<template>
  <div>
    <div class="row align-items-center justify-between">
      <div class="col-12 col-lg-4 flex align-items-center">
        <router-link to="withdraws">
          <n-button tertiary class="me-2" size="small">
            <i class="bi bi-arrow-left"></i>
          </n-button>
        </router-link>
        <BreedCrumb :show-bread="false"/>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 col-md-6">
        <el-form label-position="top" require-asterisk-position="right">
          <el-form-item>
            <template #label>
              <div class="text-sm">From Account</div>
            </template>
            <el-select v-model="withdrawModel.withdrawalAccountId">
              <el-option
                  v-for="account in accounts"
                  :key="account.id"
                  :label="account.accountName + ' (' + account.accountNumber + ')'"
                  :value="account.id"
              >
                <template #default>
                  <span>{{ account.accountName }} ({{ account.accountNumber }})</span>
                </template>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Payment Method">
            <template #label>
              <div class="text-sm">Account Details</div>
            </template>
            <el-select v-model="withdrawModel.payoutAccountId" @change="changeAccount(withdrawModel.payoutAccountId)">
              <el-option v-for="method in paymentMethods" :label="method.bankName" :key="method.id"
                         :value="method.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <template #label>
              <div class="text-xs">Account Details</div>
            </template>
            <template #default>
              <n-table size="small" :single-line="false" bordered v-loading="methodLoad">
                <tbody>
                <tr>
                  <td class="text-xs" style="width: 50%">Account Title</td>
                  <td class="text-xs" style="width: 50%">{{ paymentMethod.accountTitle }}</td>
                </tr>
                <tr>
                  <td class="text-xs">Account Number</td>
                  <td class="text-xs">
                    <div class="d-flex align-items-center">
                      <span>{{ paymentMethod.accountNumber }}</span>
                      <div class="flex-grow"></div>
                      <el-tooltip content="Copy to clipboard">
                        <n-button tertiary size="tiny" @click="copyText(paymentMethod.accountNumber)">
                          <i class="bi bi-clipboard"></i>
                        </n-button>
                      </el-tooltip>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="text-xs">Branch</td>
                  <td class="text-xs">{{ paymentMethod.branch }}</td>
                </tr>
                <tr>
                  <td class="text-xs">Currency</td>
                  <td class="text-xs">{{ paymentMethod.currency }}</td>
                </tr>

                </tbody>
              </n-table>
            </template>
          </el-form-item>

          <el-form-item>
            <template #label>
              <div class="text-sm">Amount</div>
            </template>
            <el-input @keyup="checkFund(withdrawModel.amount)" type="number" style="text-align: left !important;"
                      class="w-100 text-start" :controls="false"
                      v-model="withdrawModel.amount">
              <template #prefix>
                <el-icon>
                  <i class="bi bi-currency-dollar"></i>
                </el-icon>
              </template>
            </el-input>
            <el-collapse-transition>
              <span v-if="amountMessage" class="text-xs text-danger m-0 p-0">{{ amountMessage }}</span>
            </el-collapse-transition>
          </el-form-item>
          <div v-if="Number(payoutAccountType) === 10" class="bg-gray-50 h-20 my-2 px-3 align-items-center d-flex justify-content-between">
            <span>To be withdraw</span>
            <div>
              <span class="text-2xl font-semibold">{{ calculatedPkr(withdrawModel.amount) }}</span><span>PKR</span>
            </div>
          </div>
          <n-button @click="withdrawMoney()"
                    :disabled="withdrawModel.amount < 10 || withdrawModel.withdrawalAccountId < 1 || btnDisbld"
                    :color="colors.logoPrimary">
            Withdraw
          </n-button>
        </el-form>
      </div>
    </div>
    <div v-if="loading" class="spinner-border cstm-loading" role="status">

    </div>
  </div>

</template>
<script>
import BreedCrumb from "@/components/ui/breadcrumb.vue";
import {NTable, NButton, useMessage} from "naive-ui";
import colors from "@/utility/colors"
import accountService from "@/services/accountService";
import showMessage from "@/utility/showMessage";
import paymentService from "@/services/paymentService";
import transactionService from "@/services/transactionService";
import settingsService from "@/services/settingsService";

export default {
  name: "WithdrawAmount",
  components: {NButton, NTable, BreedCrumb},
  data() {
    return {
      loading: true,
      methodLoad: false,
      btnDisbld: false,
      useMessage: useMessage(),
      amountMessage: "",
      payoutAccountType: 10,
      config: {},
      withdrawModel: {
        "userRole": Number(this.$store.state.role) ?? 30,
        "userId": Number(sessionStorage.getItem("userId")),
        "accountId": 0,
        "payoutAccountId": 0,
        "amount": 0,
        "fee": 0,
        "withdrawalAccountId": 0,
        "source": 0
      },
      deposit: {
        transactionId: 0,
        paymentMethod: 1,
        accountId: 0,
        amount: 0,
        currency: "PKR",
        transactionType: 10,
        transactionStatus: 10,
      },
      paymentMethod: {},
      paymentMethods: [],
      accounts: [],
      filter: {
        "pageSize": 10,
        "page": 1,
        "filterText": "",
        "filterTag": "",
        "userId": Number(sessionStorage.getItem("userId")) ?? 0,
        "accountType": 100,
        "belongsToAdmin": true,
      }
    }
  },
  methods: {
    calculatedUsd(amount) {
      if (amount < 1 || typeof Number(this.config?.value) !== 'number') return amount;
      return (amount / Number(this.config?.value)).toFixed(2);
    },
    calculatedPkr(amount) {
      if (amount < 1 || typeof Number(this.config?.value) !== 'number') return amount;
      return (amount * Number(this.config?.value)).toFixed(2);
    },
    checkFund(amount) {
      let fromAccount = this.accounts.find(x => x.id === this.withdrawModel.withdrawalAccountId);
      if (fromAccount === undefined || fromAccount === null) {
        this.amountMessage = "Please select account first";
        return;
      } else {
        this.amountMessage = "";
      }
      this.withdrawModel.amount = Number(this.withdrawModel.amount);
      if (amount > fromAccount.amount) {
        this.btnDisbld = true;
        this.amountMessage = "Not enough money. Your balance is " + fromAccount.amount + " " + fromAccount.currency;
      } else if (amount < fromAccount.amount && amount < 10) {
        this.btnDisbld = true;
        this.amountMessage = "Withdrawal amount must be equal or greater than 10";
      } else {
        this.btnDisbld = false;
        this.amountMessage = "";
      }
    },
    goToVerify() {
      this.$store.state.depositAccount = this.deposit;
      this.$router.push('/verify-deposit')
    },
    copyText(text) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
          //this.useMessage.success("Copied to clipboard", {duration: 1000});
          showMessage("info", "Copied to clipboard");
          // eslint-disable-next-line no-unused-vars
        }).catch(err => {
          showMessage("error", "Copy failed");
        });
      }
    },
    changeAccount(methodId) {

      this.methodLoad = true;
      this.paymentMethod = this.paymentMethods.find(x => x.id === methodId) ?? {}

      setTimeout(() => this.methodLoad = false, 500)

    },
    async withdrawMoney() {
      this.loading = true;
      try {
        // this.$router.push(this.$store.state.previousRoute);
        // return;
        // eslint-disable-next-line no-unreachable
        let response = await transactionService.withdraw(this.withdrawModel);
        if (response.data.isSuccessful) {
          showMessage("success", response?.data?.msg ?? "Withdrawal request generated");
          this.$router.push(this.$store.state.previousRoute)
        } else {
          showMessage("error", response?.data?.msg ?? "Complete your profile to withdraw money");
          this.$router.push(this.$store.state.previousRoute)
        }
      } catch (e) {
        showMessage("error", e?.msg ?? "Withdrawal failed")
      }
      this.loading = false;
    },
    async getAllAccounts() {
      this.loading = true;
      try {
        let response = await accountService.getTransactionsAccount(this.filter);
        this.accounts = response?.data?.data ?? [];
        if (this.accounts.length > 0 && this.$store.state.withdrawAccountId < 1) {
          this.withdrawModel.withdrawalAccountId = this.accounts[0].id;
        }
        this.pagination = response?.data?.pagination;
      } catch (e) {
        showMessage("error", "Something went wrong!. plz try again later")
      }
      this.loading = false;
    },
    async getAllPaymentMethods() {
      this.loading = true;
      try {
        const userId = Number(sessionStorage.getItem("userId"));
        let response = await paymentService.getPayoutAccounts(userId, this.payoutAccountType);
        this.paymentMethods = response?.data?.data ?? [];
        if (this.paymentMethods.length > 0) {
          this.withdrawModel.payoutAccountId = this.paymentMethods[0].id;
          this.paymentMethod = this.paymentMethods[0];
        }
        this.pagination = response?.data?.pagination;
      } catch (e) {
        showMessage("error", "Something went wrong!. plz try again later")
      }
      this.loading = false;
    },
    async getCurrencyConfig() {
      let response = await settingsService.getByKey("USD");
      this.config = response?.data ?? {};

    },
    checkLogin() {
      return (sessionStorage.getItem("loggedIn") === null || sessionStorage.getItem("loggedIn") === undefined || sessionStorage.getItem("loggedIn") === false);
    }
  },
  created() {

    if (!this.checkLogin) {
      this.$router.push('/');
      return;
    }
    this.getAllAccounts();
    this.payoutAccountType = this.$store.state.payoutAccountType;
    this.withdrawModel.source = this.payoutAccountType;
    this.getAllPaymentMethods();

    if (this.$store.state.withdrawAccountId > 0) {

      this.withdrawModel.withdrawalAccountId = this.$store.state.withdrawAccountId;
    }
    this.getCurrencyConfig();
  },
  computed: {
    colors() {
      return colors;
    }
  }
}
</script>

<style scoped>
.el-input-number .el-input__inner {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  line-height: 1;
  text-align: start !important;
  display: flex !important;
  justify-content: start !important;
}
</style>