<template>
  <div>
    <div class="row align-items-center justify-between">
      <div class="col-12 col-lg-4">
        <BreedCrumb/>
      </div>
      <div class="col-12 col-lg-5">
        <div class="d-flex flex-md-row flex-column">
          <n-select class="w-100" v-model:value="filter.role" :options="roleFilters"
                    @update:value="changeRole()"/>
          <n-input-group class="ms-md-2 mt-2 mt-md-0">
            <n-input v-model:value="filter.filterText" @input="getUsers()" placeholder="search here"></n-input>
            <n-button type="primary" class="ms-2">
              <i class="mgc_search_line"></i>
            </n-button>
          </n-input-group>
          <!--          <n-button type="primary" class="ms-2" @click="addDialog = !addDialog">Add User</n-button>-->
        </div>
      </div>
    </div>
    <n-divider class="my-1"></n-divider>
    <el-table border :data="users" header-cell-class-name="tb-header" show-overflow-tooltip>
      <template #empty>
        <n-empty class="pt-4" description="No Data">
        </n-empty>
      </template>
      <el-table-column min-width="120" label="Full Name" prop="fullName"></el-table-column>
      <el-table-column min-width="120" label="Username" prop="username"></el-table-column>
      <el-table-column min-width="100" label="Country" prop="country"></el-table-column>
      <el-table-column min-width="100" label="State" prop="state">
      </el-table-column>
      <el-table-column label="City" prop="city"></el-table-column>
      <el-table-column fixed="right" label="Action" width="100">
        <template #default="scope">
          <n-space size="large">
            <el-tooltip content="Generate Referral">
              <i class="bi bi-code-square action-btn" @click="generateReferral(scope.row.id)"></i>
            </el-tooltip>
            <el-tooltip content="View User">
              <i class="bi bi-eye action-btn" @click="getProfile(scope.row.id)"></i>
            </el-tooltip>
          </n-space>
        </template>
      </el-table-column>

    </el-table>
    <div class="mt-2 d-flex justify-content-end">
      <n-pagination
          class="d-pagination"
          v-model:page="pagination.page"
          v-model:page-size="pagination.pageSize"
          :page-count="pagination.totalPages"
          show-size-picker
          :page-sizes="pageSizes"
          :on-update-page="pageChange"
          :on-update-page-size="pageSizeChange"
      />
    </div>

    <n-modal
        v-model:show="addDialog"
        :show-icon="false"
        preset="dialog"
        title="User"
    >
      <template #action>
        <div class="d-flex justify-content-end">
          <n-button secondary @click="addDialog = false">Cancel</n-button>
          <n-button type="primary" class="ms-2">Add User</n-button>
        </div>
      </template>
      <n-form>
        <n-form-item label="Full Name" class="my-0">
          <n-input placeholder=""></n-input>
        </n-form-item>
        <n-form-item label="User Name" class="my-0">
          <n-input placeholder=""></n-input>
        </n-form-item>
        <n-form-item label="Password" class="my-0">
          <n-input placeholder=""></n-input>
        </n-form-item>
      </n-form>

    </n-modal>


    <n-modal v-model:show="profileDialog" preset="dialog" title="User Profile" style="width: 650px" :show-icon="false">
      <div class="profileDialaog">
        <n-spin :show="loading" :stroke-width="8">
          <template #description>
            Saving and continue
          </template>
          <el-tabs v-model="activeTab">
            <el-tab-pane :name="1" label="Personal" >
              <el-form label-position="top" class="mt-2">
                <div class="row">
                  <el-form-item class="mb-1 col-12 col-md-6">
                    <template #label>
                      <span class="mb-0">Name</span>
                    </template>
                    <el-input disabled v-model="user.fullName"></el-input>
                  </el-form-item>
                  <el-form-item class="mb-1 col-12 col-md-6">
                    <template #label>
                      <span>Email</span>
                    </template>
                    <el-input disabled v-model="user.email"></el-input>
                  </el-form-item>
                  <el-form-item class="mb-1 col-12 col-md-6">
                    <template #label>
                      <span>Country</span>
                    </template>
                    <el-select disabled filterable v-model="user.country">
                      <el-option v-for="con in countries" :label="con.label" :key="con.value"
                                 :value="con.label"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item class="mb-1 col-12 col-md-6">
                    <template #label>
                      <span>State</span>
                    </template>
                    <el-input disabled v-model="user.state"></el-input>
                  </el-form-item>
                  <el-form-item class="mb-1 col-12 col-md-6">
                    <template #label>
                      <span>City</span>
                    </template>
                    <el-input disabled v-model="user.city"></el-input>
                  </el-form-item>
                  <el-form-item class="mb-1 col-12 col-md-6">
                    <template #label>
                      <span>Contact</span>
                    </template>
                    <el-input disabled v-model="user.contact"></el-input>
                  </el-form-item>
                  <el-form-item class="mb-1 col-12">
                    <template #label>
                      <span>Street Address</span>
                    </template>
                    <el-input disabled type="textarea" v-model="user.streetAddress"></el-input>
                  </el-form-item>
                </div>
              </el-form>
            </el-tab-pane>
            <el-tab-pane :name="2" label="Trading" >
              <el-form label-position="top" class="mt-2">
                <el-form-item>
                  <template #label>
                    <span>Trading Experience</span>
                  </template>
                  <el-radio-group v-model="user.tradingExperience">
                    <el-radio disabled label="Beginner" :value="10"/>
                    <el-radio disabled label="Master" :value="20"/>
                  </el-radio-group>
                </el-form-item>
                <el-form-item>
                  <template #label>
                    <span>Are you a team leader?</span>
                  </template>
                  <el-radio-group v-model="user.isTeamLeader" class="">
                    <el-radio disabled label="Yes" :value="true"/>
                    <el-radio disabled label="No" :value="false"/>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane :name="3" label="Documents" >
              <el-form label-position="top" class="mt-2">
                <el-form-item>
                  <template #label>
                    <span>CNIC / Passport</span>
                  </template>
                  <template #default>
                    <div class="w-100">
                      <div class="d-flex">
                        <div v-for="doc in user.documents" :key="doc.id" class="ms-2">
                          <div v-if="isImage(doc.mimeType)" >
                            <n-image width="150" :src="imageSrc(doc.mimeType,doc.fileData)" alt="doc" />
                          </div>


                        </div>
                        <!--                        <div>-->
                        <!--                          <div v-if="user.documents.length > 0">-->
                        <!--                            <span>{{ user.documents[0]?.fileName }}</span>-->
                        <!--                          </div>-->
                        <!--                        </div>-->
                        <!--                        <div class="mt-2">-->
                        <!--                          <div v-if="user.documents.length > 1">-->
                        <!--                            <span>{{ user.documents[1]?.fileName }}</span>-->
                        <!--                            <n-button circle size="small" tertiary class="ms-2" @click="removeDoc(1)"><i-->
                        <!--                                class="bi bi-x"></i>-->
                        <!--                            </n-button>-->

                        <!--                          </div>-->
                        <!--                        </div>-->
                      </div>
                    </div>
                  </template>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane :name="4" label="Payment Method" >

              <el-table border :data="user.payoutAccounts" header-cell-class-name="tb-header" size="small" stripe
                        :max-height="200">
                <el-table-column label="Bank Name" prop="bankName"></el-table-column>
                <el-table-column label="Account Title" prop="accountTitle"></el-table-column>
                <el-table-column label="Account #" prop="accountNumber"></el-table-column>
                <el-table-column label="Branch" prop="branch"></el-table-column>

              </el-table>
            </el-tab-pane>
          </el-tabs>
          <div class="flex justify-end mt-2">
            <n-space>
              <n-button v-if="activeTab === 1" tertiary @click="profileDialog = false">Cancel</n-button>
              <n-button v-else tertiary @click="activeTab > 1 ? activeTab -= 1 : activeTab = 1">Previous</n-button>
              <n-button v-if="activeTab < 4" @click="saveAndNext()" :color="colors.blackPrimary">Next</n-button>
              <n-button v-else @click="profileDialog=false, activeTab = 1" :color="colors.blackPrimary">Finish
              </n-button>
            </n-space>
          </div>
        </n-spin>
      </div>
    </n-modal>

    <n-spin v-if="loading" class="mn-spin"  :stroke-width="12" :size="25"
            style="z-index: 999!important;"></n-spin>
  </div>
</template>
<script>
import countries from "@/utility/countries"
import {
  NButton,
  NModal,
  NDivider,
  NEmpty,
  NInput,
  NInputGroup,
  NPagination,
  NForm,
  NFormItem,
  NSelect,
  NSpace, NSpin, NImage,

} from "naive-ui";
import BreedCrumb from "@/components/ui/breadcrumb.vue";
import userService from "@/services/userService";
import showMessage from "@/utility/showMessage"
import colors from "@/utility/colors";
import callHub from "@/callHub";
import notificationService from "@/services/notificationService";

export default {
  name: "UsersList",
  components: {
    NImage,
    NSpin,
    NSelect,
    NFormItem, NSpace, NForm, NPagination, NModal, NInputGroup, NDivider, NButton, NInput, BreedCrumb, NEmpty
  },
  computed: {

    colors() {
      return colors;
    },
    countries() {
      return countries;
    }
  },
  data() {
    return {
      loading: true,
      profileDialog: false,
      user: {},
      activeTab: 1,
      addDialog: false,
      bodyStyle: {
        width: "600px"
      },
      segmented: {
        content: "soft",
        footer: "soft"
      },
      users: [],
      filter: {
        search: "",
        page: 1,
        role: 10,
        pageSize: 10,
        totalPages: 1,
        "filterText": "",
        "filterTag": "",
        "userId": Number(sessionStorage.getItem("userId")),
        "fromDate": new Date(),
        "toDate": new Date(),
        "month": new Date(),
        "transactionType": 10,
        "transactionStatus": 0,

      },
      pagination: {
        fetched: 0,
        page: 1,
        pageSize: 10,
        records: 0,
        totalPages: 0
      },
      roleFilters: [
        {
          label: "All",
          value: 10
        },
        {
          label: "Admin",
          value: 20
        },
        {
          label: "Users",
          value: 30
        },
      ],
      pageSizes: [
        {
          label: '10 per page',
          value: 10
        },
        {
          label: '20 per page',
          value: 20
        },
        {
          label: '30 per page',
          value: 30
        },
        {
          label: '40 per page',
          value: 40
        }
      ]
    }
  },
  methods: {
    isImage(mimeType) {
      return mimeType.startsWith('image/');
    },
    imageSrc(mimeType, fileData) {
      return `data:${mimeType};base64,${fileData}`;
    },
    saveAndNext() {
      if (this.activeTab < 4) {
        this.activeTab += 1;
      }
    },
    pageChange(page) {
      this.filter.page = page;
      this.getUsers();
    },
    pageSizeChange(pageSize) {
      this.filter.pageSize = pageSize;
      this.getUsers();
    },
    changeRole(){
      this.filter.page =1;
      this.getUsers();
    },
    async getUsers() {
      this.loading = true;
      try {
        let response = await userService.all(this.filter);
        this.users = response?.data?.data ?? [];
        this.pagination = response?.data?.pagination;
      } catch (e) {
        showMessage("error", "Something went wrong")
      }
      this.loading = false;
    },
    async generateReferral(userId) {
      this.loading = true;
      try {

        let response = await userService.generateReferral(userId);
        if (response?.data.isSuccessful) {
          await this.getUsers();
          showMessage("success", "Referral Code Generated")
        } else {
          showMessage("error", response?.data?.msg ?? "Referral Code Generation Failed")
        }
      } catch (e) {
        showMessage("error", "Something went wrong")
      }
      this.loading = false;
    },
    checkLogin() {
      return (sessionStorage.getItem("loggedIn") === null || sessionStorage.getItem("loggedIn") === undefined || sessionStorage.getItem("loggedIn") === false);
    },
    async getProfile(userId) {
      this.loading = true;
      try {
        let response = await userService.getProfile(userId);
        this.user = response?.data?.data;

        this.profileDialog = true;
      }
          // eslint-disable-next-line no-empty
      catch (e) {
        showMessage("error", "Profile fetching failed")
      }
      this.loading = false;
    },
    async markNotificationRead() {
      // eslint-disable-next-line no-unused-vars
      let response = await notificationService.markRead(this.$store.state.notificationType);
      this.$store.commit('setUpdateNotification', true);
    },
  },
  created() {
    if (!this.checkLogin) {
      this.$router.push('/');
      return;
    }
    this.getUsers();
    this.markNotificationRead();
  },
  async mounted() {
    await callHub.start();
    callHub.client.on("SignUp", () => {
      this.getUsers();
    });
  },
}
</script>

<style scoped>

</style>