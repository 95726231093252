<template>
  <div>
    <div class="row align-items-center justify-between">
      <div class="col-12 col-lg-4">
        <BreedCrumb/>
      </div>
      <div class="col-12 col-md-6">
        <div class="d-flex flex-md-row flex-column  justify-content-end">
          <el-tooltip content="Update USD">
            <n-button type="primary" class="" @click="addConfigDialog = true;">

              Update USD Price
            </n-button>
          </el-tooltip>
        </div>
      </div>
    </div>
    <n-divider class="my-1"></n-divider>
    <div class="row align-items-stretch">
      <div class="col-12 col-md-6 mt-2 mt-md-0">
        <n-card class="h-100" hoverable>
          <span class="text-2xl d-block">Users</span>
          <div class="d-flex justify-content-between align-items-end">

            <AnimatedNumber
                :to="data?.users"
                css-class="text-5xl d-block"
            />
            <i class="bi bi-person text-xl"></i>
          </div>
        </n-card>
      </div>
      <div class="col-12 col-md-6 mt-2 mt-md-0">
        <n-card class="h-100" hoverable>
          <span class="text-2xl d-block">Total Transactions</span>
          <div class="d-flex justify-content-between align-items-end">

            <AnimatedNumber
                :to="data.transactions"
                css-class="text-5xl"
                :format="formatCurrency"
            />
            <span class="text-xl">$</span>
          </div>
        </n-card>
      </div>
      <!--      <div class="col-12 col-md-4 mt-2 mt-md-0">-->
      <!--        <n-card class="h-100" hoverable>Users</n-card>-->
      <!--      </div>-->
    </div>
    <el-dialog v-model="addConfigDialog" title="Update USD Rate" style="width: auto; max-width: 500px;">
      <template #default>
        <el-form label-position="top">
          <el-form-item label="Value">
            <el-input v-model="config.value"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <n-button tertiary @click="addConfigDialog = false">Cancel</n-button>
          <n-button type="primary" class="ms-2" :disabled="!config.key || !config.value" @click="addOrUpdateConfig()">
            Update
          </n-button>
        </div>
      </template>
    </el-dialog>
    <n-spin v-if="loading" class="mn-spin" :stroke-width="12" :size="25"
            style="z-index: 999!important;"></n-spin>



  </div>
</template>
<script>
import {NButton, NCard, NDivider, NSpin} from "naive-ui";
import BreedCrumb from "@/components/ui/breadcrumb.vue";
import AnimatedNumber from "@/components/ui/AnimateNumber.vue";
import dashboardService from "@/services/dashboardService";
import showMessage from "@/utility/showMessage";
import settingsService from "@/services/settingsService";

export default {
  name: "DashboardComp",
  components: {NButton, NSpin, AnimatedNumber, NCard, NDivider, BreedCrumb},
  data() {
    return {
      loading: false,
      addConfigDialog: false,
      accountsDialog: false,
      addAccountDialog: false,
      accounts:[],
      config: {
        id: 0,
        key: "",
        value: "",
      },
      dateRange: [
        new Date(new Date().setMonth(new Date().getMonth() - 1)),
        new Date()
      ],
      data: {
        users: 0,
        deposits: 0,
        withdraw: 0,
        transactions: 0
      },
      filter: {
        search: "",
        date: new Date(),
      },
      number: 0,
    }
  },
  mounted() {
    // Calculate the dates
    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);

    // Format the dates (you can use your preferred method of formatting)
    const formatDate = (date) => {
      const day = date.getDate();
      const month = date.toLocaleString('default', {month: 'short'});
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    this.filter.fromdate = formatDate(oneMonthAgo);
    this.filter.todate = formatDate(today);

    // Set the date range
    this.dateRange = [oneMonthAgo, today];
  },
  watch: {
    dateRange(newRange) {
      if (newRange.length === 2) {
        this.filter.fromdate = newRange[0];
        this.filter.todate = newRange[1];
      }
    }
  },
  methods: {
    async addOrUpdateConfig() {
      this.loading = true;
      this.addConfigDialog = false;
      try {
        let response = await settingsService.addOrUpdate(this.config);
        if (response?.data.isSuccessful) {
          showMessage("success", "Config Added");
          await this.getCurrencyConfig();
        }
      } catch (e) {
        showMessage("error", e?.response?.msg ?? "Configuration Update failed");
      }
      this.loading = false;
    },
    async getCurrencyConfig() {
      let response = await settingsService.getByKey("USD");
      this.config = response?.data ?? {};

    },
    formatCurrency(amount, locale = 'en-US') {
      const options = {
        style: 'decimal',
        minimumFractionDigits: amount % 1 === 0 ? 0 : 2,
        maximumFractionDigits: 2
      };
      return new Intl.NumberFormat(locale, options).format(amount);
    },
    async getData() {
      this.loading = true;
      try {
        const userId = Number(sessionStorage.getItem("userId"));
        let response = await dashboardService.getData(userId);
        this.data = response.data;
      } catch (e) {
        showMessage("error", "Data fetching failed");
      }
      this.loading = false;
    },
    checkLogin() {
      return (sessionStorage.getItem("loggedIn") === null || sessionStorage.getItem("loggedIn") === undefined || sessionStorage.getItem("loggedIn") === false);
    }
  },
  created() {

    if (!this.checkLogin) {
      this.$router.push('/');
      return;
    }
    this.getData();
    this.getCurrencyConfig();
  }
}

</script>

<style scoped>

</style>