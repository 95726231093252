<template>
  <div>
    <div class="row align-items-center justify-between">
      <div class="col-12 col-lg-4">
        <BreedCrumb/>
      </div>
      <div class="col-12 col-lg-6">
        <div class="d-flex flex-md-row flex-column">
          <n-select class="w-100" v-model:value="filter.ticketType" :options="ticketTypes"
                    @update:value="getAll()"/>
          <n-input-group class="ms-md-2 mt-2 mt-md-0">
            <n-input v-model:value="filter.filterText" @input="this.getTickets()" placeholder="search here"></n-input>
            <n-button type="primary" class="ms-2">
              <i class="mgc_search_line"></i>
            </n-button>
          </n-input-group>
          <!--          <router-link to="deposit">-->
          <!--            <n-button type="primary" class="ms-2">Deposit Account</n-button>-->
          <!--          </router-link>-->
        </div>
      </div>
    </div>
    <n-divider class="my-1"></n-divider>
    <el-table border :data="tickets" header-cell-class-name="tb-header">
      <template #empty>
        <n-empty class="pt-4" description="No Data">
        </n-empty>
      </template>
      <el-table-column label="#" type="index" width="55" align="center">
        <template #default="scope">
          <div>{{ calculateIndex(scope.$index) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="Id" prop="accountId" width="80"></el-table-column>
      <el-table-column label="Account" prop="account" width="150"></el-table-column>
      <el-table-column label="Type" prop="ticketType" width="150"></el-table-column>
      <el-table-column label="Description" min-width="150">
        <template #default="scope">
              <span>
                <n-ellipsis>
                {{ scope.row.description }}
              </n-ellipsis>
              </span>
        </template>
      </el-table-column>
      <el-table-column label="Status" prop="ticketStatus" width="100">
        <template #default="scope">
          <div class="text-center">
            <el-tag effect="dark" v-if="scope.row.status === 10" size="small" type="info" :bordered="false"
                    style="width: 80px">
              {{ scope.row.ticketStatus }}
            </el-tag>
            <el-tag effect="dark" v-if="scope.row.status === 20" size="small" type="success" :bordered="false"
                    style="width: 80px">
              {{ scope.row.ticketStatus }}
            </el-tag>
            <el-tag effect="dark" v-if="scope.row.status === 30" size="small" type="danger" :bordered="false"
                    style="width: 80px">
              {{ scope.row.ticketStatus }}
            </el-tag>

          </div>
        </template>


      </el-table-column>
      <el-table-column fixed="right" label="Action" width="100">
        <template #default="scope">

          <div class="text-center">
            <n-dropdown trigger="click" :options="statuses" @select="handleSelect" placement="bottom-end"
                        class="shadow-sm"
                        style="min-width: 150px">
              <i class="bi bi-three-dots-vertical action-btn" @click="takeRow(scope.row)"></i>

            </n-dropdown>
          </div>

        </template>
      </el-table-column>
    </el-table>
    <div class="mt-2 d-flex justify-content-end">
      <n-pagination
          class="d-pagination"
          v-model:page="pagination.page"
          v-model:page-size="pagination.pageSize"
          :page-count="pagination.totalPages"
          show-size-picker
          :page-sizes="pageSizes"
          :on-update-page="pageChange"
          :on-update-page-size="pageSizeChange"
      />
    </div>
    <n-spin v-if="loading" class="mn-spin" :stroke-width="12" :size="25"
            style="z-index: 999!important;"></n-spin>
  </div>
</template>
<script>
import {
  NButton,
  NDivider,
  NEllipsis,
  NEmpty,
  NInput,
  NInputGroup,
  NPagination,
  NSelect,
  NDropdown, NSpin,
} from "naive-ui";
import BreedCrumb from "@/components/ui/breadcrumb.vue";
import showMessage from "@/utility/showMessage";
import moment from "moment";
import ticketService from "@/services/ticketService";
import callHub from "@/callHub";
import notificationService from "@/services/notificationService";

export default {
  name: "SupportTickets",
  components: {
    NSpin,
    NDropdown,
    NSelect, NEllipsis, NPagination, NInputGroup, NDivider, NButton, NInput, BreedCrumb, NEmpty
  },
  data() {
    return {
      loading: false,
      addDialog: false,
      tickets: [],
      isMobile: false,
      bodyStyle: {
        width: "600px"
      },
      segmented: {
        content: "soft",
        footer: "soft"
      },
      users: [],
      filter: {
        search: "",
        page: 1,
        pageSize: 10,
        totalPages: 1,
        "filterText": "",
        "filterTag": "",
        "userId": Number(sessionStorage.getItem("userId")),
        "fromDate": new Date(),
        "toDate": new Date(),
        "month": new Date(),
        "transactionType": 10,
        "transactionStatus": 0,
        "ticketType": 0,

      },
      pagination: {
        fetched: 0,
        page: 1,
        pageSize: 10,
        records: 0,
        totalPages: 0
      },
      pageSizes: [
        {
          label: '10 per page',
          value: 10
        },
        {
          label: '20 per page',
          value: 20
        },
        {
          label: '30 per page',
          value: 30
        },
        {
          label: '40 per page',
          value: 40
        }
      ],
      statuses: [
        {
          label: "Pending",
          key: 10,
        },
        {
          label: "Approved",
          key: 20,
        },
        {
          label: "Rejected",
          key: 30,
        },
      ],
      ticketTypes: [
        {
          label: "All",
          value: 0
        }, {
          label: "Account",
          value: 10
        },
        {
          label: "Password Change",
          value: 20
        },
        {
          label: "Account Title",
          value: 30
        },
        {
          label: "Account Description",
          value: 40
        },
      ],
      ticker: {},
    }
  },
  methods: {
    calculateIndex(index) {
      return (this.pagination.page - 1) * this.pagination.pageSize + index + 1;
    },
    takeRow(ticket) {
      this.ticket = JSON.parse(JSON.stringify(ticket));
    },
    handleSelect(key) {
      switch (key) {
        case 10:
          this.changeStatus(this.ticket.id, 10);
          break;
        case 20:
          this.changeStatus(this.ticket.id, 20);
          this.transferDialog = true;
          break;
        case 30:
          this.changeStatus(this.ticket.id, 30);
          this.transferDialog = true;
          break;

      }
    },
    async changeStatus(id, status) {
      this.loading = true;
      try {
        let response = await ticketService.changeStatus(id, status);

        if (response.data) {
          showMessage("success", "Status updated");
          await this.getTickets();
        }
      } catch (e) {
        showMessage("error", "Status update failed");
      }
      this.loading = false;
    },
    getFormattedDate(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    pageChange(page) {
      this.filter.page = page;
      this.getTickets();
    },
    pageSizeChange(pageSize) {
      this.filter.pageSize = pageSize;
      this.getTickets();
    },
    getAll() {
      this.filter.page = 1;
      this.getTickets();
    },
    async getTickets() {
      this.loading = true;
      try {
        let response = await ticketService.all(this.filter);
        this.tickets = response?.data?.data ?? [];
        this.pagination = response?.data?.pagination;

      } catch (e) {
        showMessage("error", "Something went wrong")
      }
      this.loading = false;
    },
    checkLogin() {
      return (sessionStorage.getItem("loggedIn") === null || sessionStorage.getItem("loggedIn") === undefined || sessionStorage.getItem("loggedIn") === false);
    },
    async markNotificationRead() {
      // eslint-disable-next-line no-unused-vars
      let response = await notificationService.markRead(this.$store.state.notificationType);
      this.$store.commit('setUpdateNotification', true);
    }
  },
  created() {

    if (!this.checkLogin) {
      this.$router.push('/');
      return;
    }
    this.getTickets();
    this.markNotificationRead();
  },
  async mounted() {
    await callHub.start();
    callHub.client.on("Deposit", () => {
      this.getTickets();
    }); callHub.client.on("Referral", () => {
      this.getTickets();
    });
    callHub.client.on("CommissionTransferred", () => {
      this.getTickets();
    });
    callHub.client.on("PasswordChange", () => {
      this.getTickets();
    });
    callHub.client.on("Delete", () => {
      this.getTickets();
    });
    callHub.client.on("Margin", () => {
      this.getTickets();
    });
    callHub.client.on("Rename", this.getTickets);
    callHub.client.on("Transfer", this.getTickets);
    callHub.client.on("Withdraw", this.getTickets);
    callHub.client.on("Commission", this.getTickets);
    callHub.client.on("AccountApproved", this.getTickets);
    callHub.client.on("AccountCreated", () => {

      this.getTickets();
    });
    callHub.client.on("UpdateStatus", () => {
      this.getTickets();
    });
    callHub.client.on("SignUp", () => {
      this.getTickets();
    });
  },
}
</script>

<style scoped>

</style>