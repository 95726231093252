<template>
  <div>
    <div class="row align-items-center justify-between">
      <div class="col-12 col-lg-4 flex align-items-center">
        <router-link :to="$store.state.previousRoute">
          <n-button tertiary class="me-2" size="small">
            <i class="bi bi-arrow-left"></i>
          </n-button>
        </router-link>
        <BreedCrumb :show-bread="false"/>
      </div>
    </div>
    <n-divider class="my-2"></n-divider>
    <div class="container-fluid">
      <span class="h4 py-3">Select withdrawal method from below</span>
      <div class="row my-3" v-if="showFirstRow">
        <div class="col-12 col-md-6">
          <n-card hoverable segmented class="action-btn" >
            <template #default>
              <div class="d-flex align-items-center">
                <n-skeleton height="40px" circle />
                <n-skeleton class="ms-2" height="30px" width="50%"  />
              </div>
            </template>
            <template #footer>
              <ul>
                <n-skeleton :repeat="3" width="66%" text></n-skeleton>
              </ul>
            </template>
          </n-card>
        </div>
        <div class="col-12 col-md-6">
          <n-card hoverable segmented class="action-btn">
            <template #default>
              <div class="d-flex align-items-center">
                <n-skeleton height="40px" circle/>
                <n-skeleton class="ms-2" height="30px" width="50%"/>
              </div>
            </template>
            <template #footer>
              <ul>
                <n-skeleton :repeat="3" width="66%" text></n-skeleton>
              </ul>
            </template>
          </n-card>
        </div>
      </div>
      <div class="row my-3" v-else>
        <div class="col-12 col-md-6">

          <n-card hoverable segmented class="action-btn" @click="goToWithdraw(10)">
            <template #default>
              <div class="d-flex align-items-center">
                <img src="@/assets/img/payment/localbank.svg" alt="bank" width="50">
                <span class="text-xl font-semibold ms-2">Local Bank Transfer</span>
              </div>
            </template>
            <template #footer>
              <ul>
                <li><span class="text-secondary font-thin">Processing Time:</span> <span>Instant - 24 Hours</span></li>
                <li><span class="text-secondary font-thin">Fee:</span> <span>0%</span></li>
                <li><span class="text-secondary font-thin">Limit:</span> <span>Unlimited</span></li>
              </ul>
            </template>
          </n-card>
        </div>
        <div class="col-12 col-md-6 mt-2 mt-md-0">
          <n-card hoverable segmented class="action-btn" @click="goToWithdraw(20)">
            <template #default>
              <div class="d-flex align-items-center">
                <img src="@/assets/img/payment/tetherusdttrc20.svg" alt="bank" width="50">
                <span class="text-xl font-semibold ms-2">Tether USDT</span>
              </div>
            </template>
            <template #footer>
              <ul>
                <li><span class="text-secondary font-thin">Processing Time:</span> <span>Instant - 24 Hours</span></li>
                <li><span class="text-secondary font-thin">Fee:</span> <span>0%</span></li>
                <li><span class="text-secondary font-thin">Limit:</span> <span>Unlimited</span></li>
              </ul>
            </template>
          </n-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreedCrumb from "@/components/ui/breadcrumb.vue";
import {NButton, NCard, NDivider, NSkeleton} from "naive-ui";

export default {
  name: "PayoutAccountTypes",
  components: {NSkeleton, NCard, NDivider, NButton, BreedCrumb},
  data() {
    return {
      loading: true,
      showFirstRow: true,
    }
  },
  methods: {
    goToWithdraw(type) {
      this.$store.state.payoutAccountType = type;
      this.$router.push('/withdraw')
    }
  },
  mounted() {
    setTimeout(() => {
      this.showFirstRow = false;
    }, 500);
  },
}
</script>

<style scoped>

</style>