<template>
  <div class="d-flex flex-column w-100" style="height: 100% !important; min-height: 462px">
    <n-spin :show="loading" :stroke-width="8">
      <template #description>
        {{ loaderText }}
      </template>
      <el-tabs v-model="activeTab">
        <el-tab-pane :name="1" label="Personal" disabled>
          <el-form label-position="top" class="mt-2">
            <div class="row">
              <el-form-item class="mb-1 col-12 col-md-6">
                <template #label>
                  <span class="mb-0">Name</span>
                </template>
                <el-input v-model="user.fullName"></el-input>
              </el-form-item>
              <el-form-item class="mb-1 col-12 col-md-6">
                <template #label>
                  <span>Email</span>
                </template>
                <el-input v-model="user.email"></el-input>
              </el-form-item>
              <el-form-item class="mb-1 col-12 col-md-6">
                <template #label>
                  <span>Country</span>
                </template>
                <el-select filterable v-model="user.country">
                  <el-option v-for="con in countries" :label="con.label" :key="con.value"
                             :value="con.label"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="mb-1 col-12 col-md-6">
                <template #label>
                  <span>State</span>
                </template>
                <el-input v-model="user.state"></el-input>
              </el-form-item>
              <el-form-item class="mb-1 col-12 col-md-6">
                <template #label>
                  <span>City</span>
                </template>
                <el-input v-model="user.city"></el-input>
              </el-form-item>
              <el-form-item class="mb-1 col-12 col-md-6">
                <template #label>
                  <span>Contact</span>
                </template>
                <el-input v-model="user.contact"></el-input>
              </el-form-item>
              <el-form-item class="mb-1 col-12">
                <template #label>
                  <span>Street Address</span>
                </template>
                <el-input type="textarea" v-model="user.streetAddress"></el-input>
              </el-form-item>
            </div>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :name="2" label="Trading" disabled>
          <el-form label-position="top" class="mt-2">
            <el-form-item>
              <template #label>
                <span>Trading Experience</span>
              </template>
              <el-radio-group v-model="user.tradingExperience">
                <el-radio label="Beginner" :value="10"/>
                <el-radio label="Master" :value="20"/>
              </el-radio-group>
            </el-form-item>
            <el-form-item>
              <template #label>
                <span>Are you a team leader?</span>
              </template>
              <el-radio-group v-model="user.isTeamLeader" class="">
                <el-radio label="Yes" :value="true"/>
                <el-radio label="No" :value="false"/>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :name="3" label="Documents" disabled>
          <div class="ms-1 mt-2 ">
            <n-text type="warning"><i class="bi bi-info-circle-fill text-xs"></i></n-text>
            <n-text type="warning" class="ms-2 font-thin text-xs">The document image should be clear and of size between
              100Kb to 5Mb
            </n-text>
          </div>
          <el-form label-position="top" class="mt-2">
            <el-form-item>
              <template #label>
                <span>CNIC / Passport</span>
              </template>
              <template #default>
                <div class="w-100">
                  <div v-if="!isNullOrEmpty(user.frontDocument) || !isNullOrEmpty(user.backDocument)">
                    <div class="d-flex justify-content-between">
                      <div v-if="!isNullOrEmpty(user.frontDocument)" class="d-flex">
                        <n-image :src="JSON.parse(user.frontDocument).path" :width="200" :height="200" style="width: 200px !important; height: 200px !important;" alt="Front"></n-image>
                        <n-button circle size="small" tertiary class="ms-2" @click="removeDoc(JSON.parse(user.frontDocument).path, 'front')"><i
                            class="bi bi-x"></i>
                        </n-button>
                      </div>
                      <div v-if="!isNullOrEmpty(user.backDocument)" class="d-flex">
                        <n-image :src="JSON.parse(user.backDocument).path"  :width="200" :height="200" style="width: 200px !important; height: 200px !important;" alt="Front"></n-image>
                        <n-button circle size="small" tertiary class="ms-2" @click="removeDoc(JSON.parse(user.backDocument).path, 'back')"><i
                            class="bi bi-x"></i>
                        </n-button>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
<!--                      <div v-if="!isNullOrEmpty(user.frontDocument)" class="d-flex">
                        <img :src="JSON.parse(user.frontDocument).path" width="200" height="200" alt="Front">
                        <n-button circle size="small" tertiary class="ms-2" @click="removeDoc(JSON.parse(user.frontDocument).path, 'front')"><i
                            class="bi bi-x"></i>
                        </n-button>
                      </div>-->
                      <div v-if="isNullOrEmpty(user.frontDocument)">
                        <input type="file" id="upload-photo" class="d-none" :key="fileInputKey"
                               ref="uploader1" accept="image/*"
                               @change="onFileChanged($event)">
                        <n-button block class="py-12" :color="colors.logoSecondary" dashed
                                  v-if="!isBase64Image(document.fileData)" @click="handleFileImport('front')">
                          <div class="flex flex-col justify-center align-items-center">
                            <i class="mgc_upload_3_line text-3xl"></i>
                            <div class="el-upload__text">
                              Upload the front of your document
                            </div>
                          </div>
                        </n-button>
                      </div>
                    </div>
                    <div class="mt-2">
<!--                      <div v-if="!isNullOrEmpty(user.backDocument)" class="d-flex">
                        <img :src="JSON.parse(user.backDocument).path" width="200" height="200" alt="Front">
                        <n-button circle size="small" tertiary class="ms-2" @click="removeDoc(JSON.parse(user.backDocument).path, 'front')"><i
                            class="bi bi-x"></i>
                        </n-button>
                      </div>-->
                      <div v-if="isNullOrEmpty(user.backDocument)">
                        <input type="file" id="upload-photo" class="d-none" :key="fileInputKey"
                               ref="uploader2" accept="image/*"
                               @change="onFileChanged($event)">
                        <n-button block class="py-12" :color="colors.logoSecondary" dashed
                                  v-if="!isBase64Image(document.fileData)" @click="handleFileImport('back')">
                          <div class="flex flex-col justify-center align-items-center">
                            <i class="mgc_upload_3_line text-3xl"></i>
                            <div class="el-upload__text">
                              Upload the back of your document
                            </div>
                          </div>
                        </n-button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :name="4" label="Payment Method" disabled>
          <el-form label-position="top" class="mt-2" size="small">
            <div class="row">
              <el-form-item class="col-12 col-md-6">
                <template #label>
                  <span>Payout Method</span>
                </template>
                <el-select v-model="payoutAccount.type" @change="changeType(payoutAccount.type)">
                  <el-option v-for="opt in payoutTypes" :key="opt.value" :label="opt.text"
                             :value="opt.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="col-12 col-md-6">
                <template #label>
                  <span>Bank Name</span>
                </template>
                <el-input v-model="payoutAccount.bankName"></el-input>
              </el-form-item>
              <el-form-item class="col-12 col-md-6">
                <template #label>
                  <span>Account Title</span>
                </template>
                <el-input v-model="payoutAccount.accountTitle"></el-input>
              </el-form-item>
              <el-form-item class="col-12 col-md-6" v-if="payoutAccount.type===10">
                <template #label>
                  <span>Branch</span>
                </template>
                <el-input v-model="payoutAccount.branch"></el-input>
              </el-form-item>
              <el-form-item class="col-12 col-md-6">
                <template #label>
                  <span v-if="payoutAccount.type===10">Account Number</span>
                  <span v-if="payoutAccount.type===20">TRC20 Address</span>
                </template>
                <el-input v-model="payoutAccount.accountNumber"></el-input>
              </el-form-item>
            </div>
          </el-form>
          <div class="d-flex justify-content-end">
            <n-button :color="colors.blackPrimary" size="small"
                      :disabled="!payoutAccount.accountNumber || !payoutAccount.accountTitle || !payoutAccount.bankName"
                      @click="user.payoutAccounts.push(payoutAccount)">Add
            </n-button>
          </div>
          <el-table border :data="user.payoutAccounts" header-cell-class-name="tb-header" size="small" stripe
                    :max-height="200">
            <el-table-column label="Bank Name" prop="bankName"></el-table-column>
            <el-table-column label="Account Title" prop="accountTitle"></el-table-column>
            <el-table-column label="Account #" prop="accountNumber"></el-table-column>
            <el-table-column label="Branch" prop="branch"></el-table-column>
            <el-table-column label="" width="50">
              <template #default="scope">
                <div class="text-center">
                  <el-tooltip content="Delete Account">
                    <i class="mgc_delete_2_line text-danger text-lg action-btn" @click="removeAccount(scope.row)"></i>
                  </el-tooltip>
                </div>
              </template>

            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </n-spin>
    <div class="flex-grow"></div>
    <div class="flex justify-end">
      <n-space>
        <n-button v-if="activeTab === 1" tertiary @click="closeDialog()">Cancel</n-button>
        <n-button v-else tertiary @click="activeTab > 1 ? activeTab -= 1 : activeTab = 1">Previous</n-button>
        <n-button v-if="activeTab < 4" @click="saveAndNext()" :color="colors.blackPrimary">Next</n-button>
        <n-button v-else @click="saveProfile" :color="colors.blackPrimary" :loading="loading">Finish</n-button>
      </n-space>
    </div>

  </div>
</template>
<script>
import countries from "@/utility/countries"
import {NSpace, NButton, NSpin, NText, NImage} from "naive-ui"
import colors from "@/utility/colors"
import userService from "@/services/userService";
import showMessage from "@/utility/showMessage"
import documentService from "@/services/documentService";
import imageCompression from 'browser-image-compression';
export default {
  name: "CompleteProfile",
  components: {NImage, NText, NSpin, NSpace, NButton},
  data() {
    return {
      loading: false,
      loaderText: "Loading",
      activeTab: 1,
      fileInputKey: Date.now(),
      payoutTypes: [{text: "Bank", value: 10}, {text: "TRC20", value: 20},],
      "payoutAccount": {
        "id": 0,
        "type": 10,
        "userId": Number(sessionStorage.getItem("userId")),
        "bankName": "",
        "accountTitle": "",
        "accountNumber": "",
        "branch": "",
        "currency": "",
        "logo": "",
        "user": {},
      },
      document: {
        "userId": Number(sessionStorage.getItem("userId")),
        "fileName": "",
        "path": "",
        "isFront": true,
        "documentType": 20,
        "documentSubType": 10,
      },
      "frontDocument": {
        "fileName": "",
        "path": ""
      },
      "backDocument": {
        "fileName": "",
        "path": ""
      },
      user: {
        "userId": Number(sessionStorage.getItem("userId")),
        "email": "",
        "username": "",
        "fullName": "",
        "password": "",
        "accountVerified": false,
        "country": "",
        "contact": "",
        "state": "",
        "city": "",
        "mobileNumber": "",
        "isTeamLeader": true,
        "referralCode": "",
        "streetAddress": "",
        "role": 30,
        "tradingExperience": 10,
        "isEnableReferralCode": true,
        "referralCodeCreatedOn": null,
        "referredBy": "",
        "profileCompleted": false,
        "payoutAccounts": [],
        "frontDocument": "",
        "backDocument": ""
      }
    }
  },
  methods: {
    isNullOrEmpty(str) {
      return !str || str.trim() === '';
    },
    changeType(type) {
      switch (type) {
        case 10:
          this.payoutAccount.bankName = "";
          this.payoutAccount.accountTitle = "";
          break;
        case 20:
          this.payoutAccount.bankName = "TRC20";
          this.payoutAccount.accountTitle = "TRC20";
          break;
      }
    },
    removeAccount(row) {
      let index = this.user.payoutAccounts.indexOf(row);
      if (index > -1) {
        this.user.payoutAccounts.splice(index, 1);
      }
    },
    saveAndNext() {

      this.saveProfile();
    },
    async removeDoc(blob, type) {
      this.loading = true;
      this.loaderText = "Deleting document";
      try {
        const url = blob;
        const docsIndex = url.indexOf('medatiq-docs/');
        const blobName = url.substring(docsIndex + 12);
        let response = await documentService.delete(blobName);
        if (response?.data) {
          switch (type) {
            case 'front':
              this.user.frontDocument = "";
              break;
            case 'back':
              this.user.backDocument = "";
              break;
          }
        }
      } catch (e) {
        showMessage("error", "Image deletion failed" ?? e.response?.data.msg)
      }
      this.loaderText = "Saving & Continue"
      this.loading = false;
    },
    closeDialog() {
      this.$emit('close-dialog');
      this.$router.push(this.$store.previousRoute)

    },
    handleFileImport(type) {
      if (type === 'front') {
        this.document.isFront = true;
        this.$refs.uploader1.click();
      }
      if (type === 'back') {
        this.document.isFront = false;
        this.$refs.uploader2.click();
      }
    },
    getFileSize(fileSizeInBytes) {
      return fileSizeInBytes / 1024;
    },

    async onFileChanged() {
      this.loading = true;
      this.loaderText = "Uploading Image to Server"
      this.fileInputKey = new Date();
      const fileInput = document.getElementById("upload-photo");

      // Check if a file was selected
      if (fileInput.files && fileInput.files.length) {
        const selectedFile = fileInput.files[0];
        if (!selectedFile.type.match('image.*')) {
          this.loading = false;
          showMessage("error", 'Only image files are allowed');
          return;
        }
        if (this.getFileSize(selectedFile.size) > 10000) {
          showMessage("error", "The image size should be under 10Mb");
          this.loading = false;
          this.loaderText = "Saving & Continue";
          return;
        }
        const compressedFile = await this.compressImage(selectedFile);


        let response = await documentService.upload(compressedFile);
        if (this.document.isFront) {
          this.user.frontDocument = JSON.stringify({
            fileName: selectedFile.name,
            path: response?.data ?? "",
            type: "ID Card",
          });
        }
        if (!this.document.isFront) {
          this.user.backDocument = JSON.stringify({
            fileName: selectedFile.name,
            path: response?.data ?? "",
            type: "ID Card",
          });
        }
        this.loading = false;
        this.loaderText = "Saving & Continue"
        // Convert the selected image file to Base64
        /*this.convertFileToBase64(selectedFile)
            .then((base64Data) => {
              // Assign the Base64 data to this.deposit.document.fileData
              //this.document.fileData = `data:${selectedFile.type};base64,${base64Data}`;
              this.document.fileData = base64Data;
              this.user.documents.push(this.document);
              this.resetDocument();
              // Optionally, display a success message or perform other actions
            })
            .catch((error) => {
              console.error("Error converting image to Base64:", error);
            });*/
      }
    },
    async compressImage(file) {
      const options = {
        maxSizeMB: 3,  // Maximum size in MB
        maxWidthOrHeight: 1000,  // Maximum width or height of the image
        useWebWorker: true,  // Enable web worker for better performance
        fileType: "image/*"  // Output file type (optional)
      };

      try {
        // Compress the image file using browser-image-compression
        const compressedFile = await imageCompression(file, options);
        return compressedFile;  // Return the compressed image file
      } catch (error) {
        console.error("Compression error:", error);
        throw error;  // Throw error if compression fails
      }
    },
    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result.split(',')[1]); // Get the Base64 string
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    resetDocument() {
      this.document = {
        "documentId": 0,
        "userId": Number(sessionStorage.getItem("userId")),
        "fileName": "",
        "fileSize": "",
        "fileExt": "",
        "mimeType": "",
        "fileData": "",
        "isFront": true,
        "documentType": 20,
        "documentSubType": 10,
        "lastModifiedAt": null
      }
    },
    isBase64Image(imageUrl) {
      // eslint-disable-next-line no-unreachable
      if (imageUrl === '' || imageUrl === null || imageUrl === undefined) {
        return;
      }
      // Check if the image URL starts with the specified base64 prefix
      return imageUrl.startsWith("data:image/jpeg;base64,") || imageUrl.startsWith("data:image/png;base64,");
    },
    async saveProfile() {
      /*  if (this.activeTab < 4) {
          this.activeTab += 1;
          return;
        } else {
          //showMessage("success", response?.data?.msg ?? "Profile updated")

        }*/
      // eslint-disable-next-line no-unreachable
      if (this.loaderText !== "Saving & Continue") {
        this.loaderText = "Saving & Continue"
      }
      this.loading = true;
      if (this.activeTab === 4) {
        this.$emit('close-dialog');
      }
      try {
        let type = "";
        switch (this.activeTab) {
          case 3:
            type = "docs";
            break;
          case 4:
            type = "payout";
            break;
          default:
            type = "";
            break;
        }


        let response = await userService.completeProfile(this.user, type);

        if (response.data.isSuccessful) {
          this.user = response?.data?.data;

          if (this.activeTab < 4) {
            this.activeTab += 1;
          } else {
            showMessage("success", response?.data?.msg ?? "Profile updated")

          }
        }

      } catch (e) {
        showMessage("error", e?.data?.msg ?? "Something went wrong")
      }
      this.loading = false;
    },
    async getProfile() {
      this.loading = true;
      const userId = Number(sessionStorage.getItem("userId"));
      try {
        let response = await userService.getProfile(userId);
        this.user = response?.data?.data;
      }
          // eslint-disable-next-line no-empty
      catch (e) {
      }
      this.loading = false;
    },
    checkLogin() {
      return (sessionStorage.getItem("loggedIn") === null || sessionStorage.getItem("loggedIn") === undefined || sessionStorage.getItem("loggedIn") === false);
    }
  },
  created() {
    if (!this.checkLogin) {
      this.$router.push('/');
      return;
    }
    this.getProfile();
  },
  computed: {
    countries() {
      return countries;
    },
    colors() {
      return colors;
    }
  }
}
</script>

<style scoped>
.el-upload .el-upload--text {
  width: 100% !important;
}


.profileSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>