<template>
  <div>

    <div class="container-fluid mt-3">
      <div class="bg-logo-yellow h-24 my-2 d-flex align-items-center px-3">
        <span class="md:text-3xl transition-all font-semibold">GET 10% BONUS ON YOUR FIRST DEPOSIT</span>
      </div>


      <div class="flex justify-between align-middle">
        <span class="h2">My Accounts</span>
        <router-link to="new-account" class="d-none d-md-block">
          <n-button secondary>
            <i class="bi bi-plus me-2"></i>
            Open New Account
          </n-button>
        </router-link>
        <router-link to="new-account" class="d-block d-md-none">
          <n-button secondary>
            <i class="bi bi-plus me-2"></i>
            New
          </n-button>
        </router-link>
      </div>
    </div>
    <div class="container-fluid">
      <n-tabs type="line" v-model:value="activeTab" animated size="large" @update:value="changeTab">
        <n-tab-pane :name="100" tab="Real">
          <n-card v-if="!loading && accounts.length === 0" class="py-2">
            <div class="d-flex flex-column justify-content-center align-items-center h-100">
              <n-empty description="You don't have real account">
                <template #icon>
                  <n-icon>
                    <i class="bi bi-layers-fill"></i>
                  </n-icon>
                </template>
                <template #extra>
                  <router-link to="new-account">
                    <n-button :color="colors.logoPrimary">
                      Create a new account
                    </n-button>
                  </router-link>
                </template>
              </n-empty>
            </div>
          </n-card>
          <div v-if="loading" class="mt-3">

            <n-card>
              <div>
                <n-space align="center">
                  <n-skeleton :width="100" :sharp="false" size="small"/>
                  <n-skeleton :width="50" :sharp="false" size="small"/>
                  <n-skeleton :width="50" :sharp="false" size="small"/>
                  <n-skeleton :width="70" :sharp="false" size="small"/>
                  <n-skeleton :width="100" :sharp="false" size="small"/>
                </n-space>
              </div>
              <div class="flex justify-between align-middle mt-5">
                <div>
                  <n-skeleton :width="100" :sharp="false" size="medium"/>
                </div>
                <div>
                  <n-space>
                    <n-skeleton :width="100" :sharp="false" size="medium"/>
                    <n-skeleton :width="100" :sharp="false" size="medium"/>

                    <n-skeleton :width="50" :sharp="false" size="medium"/>
                  </n-space>
                </div>
              </div>
              <template #action>
                <span>&nbsp;</span>
              </template>
            </n-card>
          </div>
          <div v-else class="mt-3" v-for="account in accounts" :key="account.id">
            <n-card>
              <div>
               <div class="d-flex justify-content-between">
                 <n-space align="center">
                   <span class="text-lg font-semibold me-2">{{ account.accountName }}</span>
                   <n-tag size="small">{{ account.accountType }}</n-tag>
                   <n-tag size="small">{{ account.platform }}</n-tag>
                   <n-tag size="small">{{ account.accountCategory }}</n-tag>

                   <span class="d-none d-md-block">#{{ account.accountNumber }}</span>
                 </n-space>
                 <n-dropdown  trigger="click" :options="options" @select="handleSelect"
                             >
                     <i class="bi bi-three-dots-vertical d-block d-md-none action-btn" @click="takeRow(account)"></i>

                 </n-dropdown>
               </div>
                <span class="d-block d-md-none">#{{ account.accountNumber }}</span>
              </div>
              <div class="flex justify-between align-middle mt-3 mt-md-5">
                <div>
                    <span class="h4">
                     {{ getAmount(account.amount) }}
                    </span>
                  <span>{{ account.currency }}</span>
                </div>
                <div class="d-none d-md-block">
                  <n-space>
                    <n-button secondary @click="goToDeposit(account.id)">
                      <i class="bi bi-arrow-down-circle me-2"></i>
                      Deposit
                    </n-button>
                    <n-button secondary @click="goToWithdraw(account.id)">
                      <i class="bi bi-arrow-up-right-circle me-2"></i>
                      Withdraw
                    </n-button>
                    <n-button :color="colors.logoPrimary" @click="takeRow(account,'Trade')">
                      <i class="bi bi-graph-up-arrow me-2"></i>
                      Trade
                    </n-button>
                    <n-dropdown trigger="click" :options="options" @select="handleSelect"
                                class="shadow-sm"
                                style="min-width: 150px">
                      <n-button secondary @click="takeRow(account)">
                        <i class="bi bi-three-dots-vertical"></i>
                      </n-button>
                    </n-dropdown>
                  </n-space>
                </div>

              </div>
              <div class="d-md-none mt-4">
                <div class="d-flex justify-content-center align-items-center">
                  <n-space :size="30">
                    <div class="d-flex flex-column align-items-center">
                      <n-button circle secondary @click="goToDeposit(account.id)">
                        <i class="bi bi-arrow-down-circle "></i>

                      </n-button>
                      <span class="text-sm">Deposit</span>
                    </div>
                    <div class="d-flex flex-column align-items-center">
                      <n-button circle secondary @click="goToWithdraw(account.id)">
                        <i class="bi bi-arrow-up-right-circle "></i>

                      </n-button>
                      <span class="text-sm">Withdraw</span>
                    </div>
                    <div class="d-flex flex-column align-items-center">
                      <n-button circle :color="colors.logoPrimary" @click="takeRow(account,'Trade')">
                        <i class="bi bi-graph-up-arrow "></i>

                      </n-button>
                      <span class="text-sm">Trade</span>
                    </div>
                  </n-space>
                </div>
              </div>
              <template #action>
                <span class="text-sm md:text-base">Your Account equity on website will be updated after every 24 hours </span>
              </template>
            </n-card>
          </div>
        </n-tab-pane>
        <n-tab-pane :name="200" tab="Demo">

          <n-card v-if="!loading && accounts.length === 0" class="py-2">
            <div class="d-flex flex-column justify-content-center align-items-center h-100">
              <n-empty description="You don't have demo account">
                <template #icon>
                  <n-icon>
                    <i class="bi bi-layers-fill"></i>
                  </n-icon>
                </template>
                <template #extra>
                  <router-link to="new-account">
                    <n-button :color="colors.logoPrimary">
                      Create a new account
                    </n-button>
                  </router-link>
                </template>
              </n-empty>
            </div>
          </n-card>
          <div v-if="loading" class="mt-3">
            <n-card>
              <div>
                <n-space align="center">
                  <n-skeleton :width="100" :sharp="false" size="small"/>
                  <n-skeleton :width="50" :sharp="false" size="small"/>
                  <n-skeleton :width="50" :sharp="false" size="small"/>
                  <n-skeleton :width="70" :sharp="false" size="small"/>
                  <n-skeleton :width="100" :sharp="false" size="small"/>
                </n-space>
              </div>
              <div class="flex justify-between align-middle mt-5">
                <div>
                  <n-skeleton :width="100" :sharp="false" size="medium"/>
                </div>
                <div>
                  <n-space>
                    <n-skeleton :width="100" :sharp="false" size="medium"/>
                    <n-skeleton :width="100" :sharp="false" size="medium"/>

                    <n-skeleton :width="50" :sharp="false" size="medium"/>
                  </n-space>
                </div>
              </div>
              <template #action>
                <span>&nbsp;</span>
              </template>
            </n-card>
          </div>
          <div v-else class="mt-3" v-for="account in accounts" :key="account.id">
            <n-card>
              <div>
                <n-space align="center">
                  <span class="text-lg font-semibold me-2">{{ account.accountName }}</span>
                  <n-tag size="small">{{ account.accountType }}</n-tag>
                  <n-tag size="small">{{ account.platform }}</n-tag>
                  <n-tag size="small">{{ account.accountCategory }}</n-tag>
                  <span v-if="account.accountNumber">#{{ account.accountNumber }}</span>
                </n-space>
              </div>
              <div class="flex justify-between align-middle mt-5">
                <div>
                    <span class="h4">
                     {{ getAmount(account.amount) }}
                    </span>
                  <span>{{ account.currency }}</span>
                </div>
                <div>
                  <n-space>
                    <n-button secondary>
                      <i class="bi bi-arrow-down-circle me-2"></i>
                      Deposit
                    </n-button>
                    <n-button secondary>
                      <i class="bi bi-arrow-up-right-circle me-2"></i>
                      Withdraw
                    </n-button>
                    <n-button :color="colors.logoPrimary" @click="takeRow(account,'Trade')">
                      <i class="bi bi-graph-up-arrow me-2"></i>
                      Trade
                    </n-button>

                    <n-dropdown trigger="click" :options="options" @select="handleSelect"
                                class="shadow-sm"
                                style="min-width: 150px">
                      <n-button secondary @click="takeRow(account)">
                        <i class="bi bi-three-dots-vertical"></i>
                      </n-button>
                    </n-dropdown>
                  </n-space>
                </div>
              </div>
              <template #action>
                <span>Your Account equity on website will be updated after every 24 hours </span>
              </template>
            </n-card>
          </div>
        </n-tab-pane>
      </n-tabs>
    </div>


    <el-dialog v-model="renameDialog" style="width: auto; max-width: 500px" title="Rename your Account">
      <template #default>
        <span>#{{ account.accountNumber }}</span><br>
        <span class="text-xs font-thin">If you have multiple accounts, you can give each a unique name to make navigation easier.</span>
        <el-form label-position="top" class="mt-3">
          <el-form-item label="New Nickname">
            <el-input v-model="renameModel.accountName"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-end">
          <n-button tertiary @click="renameDialog = false" class="me-2">Cancel</n-button>
          <n-button :color="colors.blackPrimary" @click="renameAccount()">Continue</n-button>
        </div>
      </template>

    </el-dialog>
    <el-dialog v-model="infoDialog" style="width: auto; max-width: 500px" title="Account Information">
      <template #default>
        <span>Account: #{{ account.accountNumber }}</span>
        <n-list bordered>
          <n-list-item>
            <div class="d-flex justify-content-between">
              <span>Nickname</span>
              <span>{{ account?.accountName }}</span>
            </div>
          </n-list-item>
          <n-list-item>
            <div class="d-flex justify-content-between">
              <span>Type</span>
              <span>{{ getCategory(account?.tradingCategoryId) }}</span>
            </div>
          </n-list-item>
          <n-list-item>
            <div class="d-flex justify-content-between">
              <span>Margin</span>
              <span>{{ account?.margin }}</span>
            </div>
          </n-list-item>
          <n-list-item>
            <div class="d-flex justify-content-between">
              <span>Vertex Login</span>
              <div>
                <span>{{ account.accountNumber }}</span>
                <el-tooltip content="Copy to clipboard">
                  <n-button tertiary size="small" class="m-0 ms-2" @click="copyText(account.accountNumber)">
                    <i class="bi bi-copy font-bold"></i>
                  </n-button>
                </el-tooltip>
              </div>
            </div>
          </n-list-item>
        </n-list>
      </template>
      <template #footer>
        <div class="d-flex justify-end">
          <n-button tertiary @click="infoDialog = false" class="me-2">Cancel</n-button>
          <n-button :color="colors.blackPrimary">Continue</n-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="changeMarginDialog" style="width: auto; max-width: 500px" title="Change max Leverage">
      <template #default>
        <span>Account : #{{ account.accountNumber }}</span>
        <el-form class="mt-2" label-position="top">
          <el-form-item label="Max Leverage">
            <el-select v-model="account.margin">
              <el-option v-for="item in marginOptions" :key="item.value" :label="item.text"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-end">
          <n-button tertiary @click="changeMarginDialog = false" class="me-2">Cancel</n-button>
          <n-button :color="colors.blackPrimary" @click="changeMargin()">Continue</n-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="changePasswordDialog" style="width: auto; max-width: 500px" title="Change trading password">
      <template #default>
        <span>#{{ account.accountNumber }}</span>
        <el-form class="mt-2" label-position="top">
          <el-form-item label="Trading password">
            <el-input minlength="5" @keyup.space.prevent @input="testPassword(account.accountPassword)"
                      v-model="account.accountPassword" type="password"
                      show-password></el-input>
            <div class="pswd-indicator">
              <span class="font-semibold">Password Strength</span>
              <div class="p-0 m-0 pswd-div">
                <i v-if="account && account.accountPassword && account.accountPassword.length < 5"
                   class="bi bi-x-circle-fill me-2 text-danger text-xs"></i>
                <i v-else class="bi bi-check-circle-fill me-2 text-success text-xs"></i>
                <span
                    :class="account && account.accountPassword && account.accountPassword.length < 5 ? 'text-secondary' : 'text-success'"
                    class="text-xs">
                  At least five characters
                </span></div>
              <div class="p-0 m-0 pswd-div">
                <i v-if="!specialCh" class="bi bi-x-circle-fill me-2 text-danger text-xs"></i>
                <i v-else class="bi bi-check-circle-fill me-2 text-success text-xs"></i>
                <span
                    :class="!specialCh ? 'text-secondary' : 'text-success'" class="text-xs">At least one special character</span>
              </div>
              <div class="p-0 m-0 pswd-div">
                <i v-if="!hasCapitalChar" class="bi bi-x-circle-fill me-2 text-danger text-xs"></i>
                <i v-else class="bi bi-check-circle-fill me-2 text-success text-xs"></i>
                <span
                    :class="!hasCapitalChar ? 'text-secondary' : 'text-success'" class="text-xs">At least one capital character</span>
              </div>
              <div class="p-0 m-0 pswd-div">
                <i v-if="!hasNumber" class="bi bi-x-circle-fill me-2 text-danger text-xs"></i>
                <i v-else class="bi bi-check-circle-fill me-2 text-success text-xs"></i>
                <span :class="!hasNumber ? 'text-secondary' : 'text-success'" class="text-xs">At least One number</span>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-end">
          <n-button tertiary @click="changePasswordDialog = false" class="me-2">Cancel</n-button>
          <n-button :color="colors.blackPrimary" @click="changePassword()">Change Password</n-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="transferDialog" style="width: auto; max-width: 500px" title="Transfer Funds">
      <template #default>
        <span class="font-thin">Transfer funds between your account.</span>
        <el-form class="mt-2" label-position="top">
          <el-form-item label="From Account">
            <el-select v-model="transferModel.fromAccount">
              <el-option
                  v-for="account in accounts"
                  :key="account.id"
                  :label="account.accountName + ' (' + account.accountNumber + ')'"
                  :value="account.id"
                  :disabled="account.id === transferModel.toAccount"
              >
                <template #default>
                  <span>{{ account.accountName }} ({{ account.accountNumber }})</span>
                </template>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="To Account">
            <el-select v-model="transferModel.toAccount">
              <el-option
                  v-for="account in accounts"
                  :key="account.id"
                  :label="account.accountName + ' (' + account.accountNumber + ')'"
                  :value="account.id"
                  :disabled="account.id === transferModel.fromAccount"
              >
                <template #default>
                  <span>{{ account.accountName }} ({{ account.accountNumber }})</span>
                </template>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Transfer Amount">
            <el-input @keyup="checkFund(transferModel.transferAmount)" v-model="transferModel.transferAmount"
                      type="number"></el-input>
            <el-collapse-transition>
              <span v-if="amountMessage" class="text-xs text-danger m-0 p-0">{{ amountMessage }}</span>
            </el-collapse-transition>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-end">
          <n-button tertiary @click="transferDialog = false" class="me-2">Cancel</n-button>
          <n-button :disabled="disableTransfer(transferModel.transferAmount)" :color="colors.blackPrimary"
                    @click="transferFunds()">Transfer
          </n-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="deleteDialog" style="width: auto; max-width: 500px" title="Delete Account">
      <template #default>
        <span class="">Are you sure? you want to delete <span class="font-bold">{{ account.accountName }}</span>.</span>
      </template>
      <template #footer>
        <div class="d-flex justify-end">
          <n-button tertiary @click="deleteDialog = false" class="ms-2">Cancel</n-button>
          <n-button type="error" class="ms-2" @click="deleteAccount()">Delete</n-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog v-model="tradeDialog"  style="width: auto; max-width: 550px">
      <template #header>
        <span class="text-xl font-semibold">Trade</span>
      </template>
      <template #default>
        <n-collapse :trigger-areas="triggerAreas" arrow-placement="right">
          <n-collapse-item title="right" name="1" class="bg-gray-50 p-2 rounded-2">
            <template #arrow>
              <div></div>
            </template>
            <template #header>
              <div class="d-flex justify-content-between align-items-center w-100">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <img src="@/assets/img/vertex.png" alt="vertex" :width="60">
                  </div>
                  <div class="d-flex flex-col ms-2">
                    <span class="h5 font-semibold my-0">VertextFx</span>
                    <span>Download VertexFx</span>
                  </div>
                </div>
                <div class="">
                  <i class="bi bi-chevron-right"></i>
                </div>
              </div>
            </template>
            <span class="text-base md:text-lg font-semibold d-block">How to connect to VertexFx</span>
            <span class="text-xs md:text-sm">Copy the server name and VertexFx login, then enter your password.</span>
            <n-list bordered class="mt-2 text-xs md:text-base">
              <n-list-item>
                <div class="d-flex justify-content-between">
                  <span class="text-xs md:text-base">Server/Broker:</span>
                  <div>
                    <span class="text-xs md:text-base">{{ account?.server ?? '' }}</span>
                  </div>
                </div>
              </n-list-item>
              <n-list-item>
                <div class="d-flex justify-content-between">
                  <span class="text-xs md:text-base">Username:</span>
                  <div>
                    <span class="text-xs md:text-base">{{ account.accountNumber }}</span>
                    <el-tooltip content="Copy to clipboard">
                      <n-button tertiary size="small" class="m-0 ms-2" @click="copyText(account.accountNumber)">
                        <i class="bi bi-copy font-bold"></i>
                      </n-button>
                    </el-tooltip>
                  </div>
                </div>
              </n-list-item>
              <n-list-item>
                <div class="d-flex justify-content-between">
                  <span class="text-xs md:text-base">Password:</span>
                  <div>
                    <span class="text-xs md:text-base">Use your trading account password</span>
                  </div>
                </div>
              </n-list-item>
            </n-list>
          </n-collapse-item>
        </n-collapse>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <n-button tertiary class="ms-2" @click="tradeDialog = false">Close</n-button>
        </div>
      </template>
    </el-dialog>


    <n-spin v-if="loading" class="mn-spin"  :stroke-width="12" :size="25"
            style="z-index: 999!important;"></n-spin>
  </div>
</template>
<script>
import {
  NButton,
  NCard, NCollapse, NCollapseItem,
  NDropdown, NEmpty, NIcon,
  NList,
  NListItem,
  NSkeleton,
  NSpace, NSpin,
  NTabPane,
  NTabs,
  NTag,
  useMessage
} from "naive-ui";
import colors from "@/utility/colors"
import showMessage from "@/utility/showMessage"
import accountService from "@/services/accountService";
import notificationService from "@/services/notificationService";
import store from "@/store/index"
import callHub from "@/callHub"

export default {
  name: "UserAccounts",
  components: {
    NSpin,
    NCollapseItem,
    NCollapse,
    NIcon, NEmpty, NSkeleton, NListItem, NList, NDropdown, NTag, NCard, NTabPane, NTabs, NSpace, NButton
  },
  data() {
    return {
      loading: true,
      renameDialog: false,
      infoDialog: false,
      changeMarginDialog: false,
      transferDialog: false,
      changePasswordDialog: false,
      deleteDialog: false,
      tradeDialog: false,
      activeTab: 100,
      triggerAreas: ["main", "extra", "arrow"],
      msg: [],
      categories: [],
      specialCh: false,
      hasCapitalChar: false,
      hasNumber: false,
      useMessage: useMessage(),
      amountMessage: "",
      account: {},
      accounts: [],
      renameModel: {
        accountId: 0,
        userId: Number(sessionStorage.getItem("userId")),
        accountName: "",
      },
      transferModel: {
        fromAccount: "",
        toAccount: "",
        transferAmount: 0,
        userId: Number(sessionStorage.getItem("userId"))
      },
      marginOptions: [
        {text: "1:100", value: "1:100"},
        {text: "1:500", value: "1:500"},
        {text: "1:1000", value: "1:1000"},
        {text: "1:2000", value: "1:2000"},
      ],
      accountOptions: [
        {text: "Newest", value: 100},
        {text: "Oldest", value: "oldest"},
        {text: "Free Margin", value: "freeMargin"},
        {text: "Nick Name", value: "nickName"},
      ],
      options: [
        {
          label: "Transfer funds",
          key: "transfer",
        },
        {
          label: 'Rename Account',
          key: 'rename',
        },
        {
          label: 'Change Margin',
          key: 'margin',
        }, {
          label: 'Account information',
          key: 'information',
        },
        {
          label: 'Change Trading Password',
          key: 'password'
        },
        {
          label: 'Delete Account',
          key: 'delete',
        },
      ],
      pagination: {
        fetched: 0,
        page: 1,
        pageSize: 10,
        records: 0,
        totalPages: 0
      },
      filter: {
        "pageSize": 10,
        "page": 1,
        "filterText": "",
        "filterTag": "",
        "userId": Number(sessionStorage.getItem("userId")) ?? 0,
        "tradingAccountType": 100
      },
      pageSizes: [
        {
          label: '10 per page',
          value: 10
        },
        {
          label: '20 per page',
          value: 20
        },
        {
          label: '30 per page',
          value: 30
        },
        {
          label: '40 per page',
          value: 40
        }
      ]
    }
  },
  methods: {
    getCategory(id) {
      return this.categories.find(x => x.id === id).title ?? "";
    },
    goToDeposit(accountId) {
      this.$store.state.depositAccountId = accountId;
      this.$router.push('/deposit-methods');
    },
    goToWithdraw(accountId) {
      this.$store.state.withdrawAccountId = accountId;
      this.$router.push('/payout-methods');
    },
    changeTab(newTabValue) {
      this.accounts = [];
      this.activeTab = newTabValue;
      this.filter.tradingAccountType = this.activeTab;
      this.getAll();
      // Additional logic when the tab changes
    },
    takeRow(account, type) {
      this.account = {...account};
      switch (type) {
        case 'Trade':
          this.tradeDialog = true;
          break;
      }
    },
    getAmount(amount) {
      return amount.toFixed(2);
    },
    checkFund(amount) {
      let fromAccount = this.accounts.find(x => x.id === this.transferModel.fromAccount);
      if (fromAccount === undefined || fromAccount === null) {
        showMessage("error", "Fetching account data failed");
        return;
      }
      if (amount > fromAccount.amount) {

        this.amountMessage = "Not enough money. Your balance is " + fromAccount.amount + " " + fromAccount.currency;
      } else {
        this.amountMessage = "";
      }
    },
    disableTransfer(amount) {
      let fromAccount = this.accounts.find(x => x.id === this.transferModel.fromAccount);
      if (fromAccount === undefined || fromAccount === null) {
        showMessage("error", "Fetching account data failed");
        return true;
      }
      return amount >= fromAccount.amount;
    },
    handleSelect(key) {
      switch (key) {
        case "transfer":
          this.transferModel.fromAccount = this.account.id;
          this.transferDialog = true;
          break;
        case "password":
          this.changePasswordDialog = true;
          this.testPassword(this.account.accountPassword);
          break;
        case "rename":
          this.renameModel.accountId = this.account.id;
          this.renameDialog = true;
          break;
        case "margin":
          this.changeMarginDialog = true;
          break;
        case "information":
          this.infoDialog = true;
          break;
        case "delete":
          this.deleteDialog = true;
          break;
      }
    },
    testPassword(inputString) {
      // Define a regular expression to match any character that is not alphanumeric or whitespace
      const regex = /[^a-zA-Z0-9\s]/;
      const capitalCharRegex = /[A-Z]/;
      const numberRegex = /[0-9]/;
      // Test the input string against the regular expression
      this.specialCh = regex.test(inputString);
      this.hasCapitalChar = capitalCharRegex.test(inputString);
      this.hasNumber = numberRegex.test(inputString);
    },
    copyText(text) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
          this.useMessage.success("Copied to clipboard", {duration: 1000});
          // eslint-disable-next-line no-unused-vars
        }).catch(err => {
          this.useMessage.success("Copy failed", {duration: 1000});
        });
      }
    },
    async getAll() {
      this.loading = true;
      try {
        let response = await accountService.all(this.filter);
        this.accounts = response?.data?.data ?? [];
        this.pagination = response?.data?.pagination;
      } catch (e) {
        showMessage("error", "Something went wrong")
      }
      this.loading = false;
    },
    async transferFunds() {
      this.transferDialog = false;
      this.loading = true;
      try {
        let response = await accountService.transfer(this.transferModel);
        if (response?.data?.isSuccessful) {
          showMessage("success", "Transfer funds request generated");
          await this.getAll();
        }
      } catch (e) {
        showMessage("error", e?.data?.message ?? "Transfer funds failed")
      }
      this.loading = false;
    },
    async deleteAccount() {
      this.deleteDialog = false;
      this.loading = true;
      try {
        let response = await accountService.delete(this.account.id);
        if (response?.data) {
          showMessage("success", "Account deletetion request generated");

        }
      } catch (e) {
        showMessage("error", "Account deletetion failed")
      }
      this.loading = false;
    },
    async renameAccount() {
      this.renameDialog = false;
      this.loading = true;
      try {
        let response = await accountService.rename(this.renameModel);
        if (response?.data?.isSuccessful) {
          showMessage("success", response?.data?.msg ?? "Account rename request generated");
          await this.getAll();
        }
      } catch (e) {
        showMessage("error", "Account rename failed")
      }
      this.loading = false;
    },
    async changeMargin() {
      this.changeMarginDialog = false;
      this.loading = true;
      try {
        let response = await accountService.changeMargin(this.account.id, this.account.margin);
        if (response?.data?.isSuccessful) {
          showMessage("success", response?.data?.msg ?? "Margin change request generated");
          await this.getAll();
        }
      } catch (e) {
        showMessage("error", "Margin changed failed")
      }
      this.loading = false;
    },
    async getCategories() {
      this.loading = true;
      try {
        let response = await accountService.getCategories();
        this.categories = response?.data?.data ?? [];
      } catch (e) {
        showMessage("error", e?.data?.message ?? "Data fetching failed")
      }
      this.loading = false;
    },
    async changePassword() {
      this.changePasswordDialog = false;
      this.loading = true;
      try {
        let response = await accountService.changePasswords(this.account.id, this.account.accountPassword);
        if (response.data) {
          showMessage("success", "Password changed request generated");

        }
      } catch (e) {
        showMessage("error", "Password update failed");
      }
      this.loading = false;
    },
    checkLogin() {
      return (sessionStorage.getItem("loggedIn") === null || sessionStorage.getItem("loggedIn") === undefined || sessionStorage.getItem("loggedIn") === false);
    },
    async markNotificationRead() {
      // eslint-disable-next-line no-unused-vars
      let response = await notificationService.markRead(this.$store.state.notificationType);
      this.$store.commit('setUpdateNotification', true);
    }
  },
  async mounted() {
    await callHub.start();
    callHub.client.on("AccountApproved", () => {
      this.getAll()
    });
    callHub.client.on("Deposit", () => {
      this.getAll();
    });  callHub.client.on("Delete", () => {
      this.getAll();
    });
    callHub.client.on("Withdraw", () => {
      this.getAll();
    });
    callHub.client.on("Transfer", () => {
      this.getAll();
    });
    callHub.client.on("UpdateStatus", () => {
      this.getAll();
    });
    callHub.client.on("Rename", () => {
      this.getAll();
    });
    callHub.client.on("UpdateTicket", () => {
      this.getAll();
    });
    callHub.client.on("AccountCreated", () => {
      this.getAll();
    })

  },
  created() {
    if (!this.checkLogin) {
      this.$router.push('/');
      return;
    }

    this.getAll();
    this.getCategories();
    this.markNotificationRead();
  },
  computed: {
    colors() {
      return colors;
    }, store() {
      return store;
    }
  }
}
</script>

<style scoped>
.pswd-div {
  height: 18px !important;
}
</style>