<template>
  <div>
    <n-config-provider :theme-overrides="themeOverrides">
      <n-message-provider>
        <el-container>

          <el-aside v-if="loggedIn && showSidebar" :width="sidebarCollapsed ? '78px' : '220px'"
                    class="transition-all px-2" style="background-color: #f8fafc !important;position: fixed">
            <SideBar :style="{height: '100vh'}"/>
          </el-aside>

          <el-container>
            <el-header v-if="loggedIn" class="px-0 sticky-top top-0, z-2 bg-white" :style="headerStyle">
              <HeaderComp/>
            </el-header>
            <el-main class="p-0 transition-all" :style="computedStyle">
              <div v-if="loggedIn && !profileCompleted" class="container-fluid">
                <div class="profile-bg p-2 flex justify-between py-4 align-items-center">
                  <!--                  <el-progress type="circle" :width="80" :percentage="25">-->
                  <!--                    <i class="bi bi-person text-xl"></i>-->
                  <!--                  </el-progress>-->

                  <div style="background-color: rgba(240,228,202,0.8); border-radius: 50%" class="p-2">
                    <i class="bi bi-person px-1 text-gr text-3xl"></i>
                  </div>
                  <!--                  <el-button text bg circle size="large">-->
                  <!--                    <i class="bi bi-person  px-3 text-gr text-2xl"></i>-->
                  <!--                  </el-button>-->
                  <n-space>
                    <n-button :color="colors.logoSecondary">Learn More</n-button>
                    <n-button :color="colors.logoPrimary" @click="profileDialog = !profileDialog">Complete Profile
                    </n-button>
                  </n-space>
                </div>
              </div>

              <router-view class="p-3"/>
            </el-main>
          </el-container>
        </el-container>
      </n-message-provider>
    </n-config-provider>


    <n-modal v-model:show="profileDialog" title="Complete Profile" class="d-flex" preset="card" style="width: 700px; min-height: 530px !important;"
             @close="handleClose()">
      <CompleteProfile @close-dialog="closeDialog"/>
    </n-modal>
    <el-tooltip v-if="Number(role) === 30" content="Contact Us">
      <a data-v-721f27de="" href="https://wa.me/971558098395" target="_blank" class="btn-whatsapp-pulse"><i
          data-v-721f27de="" class="bi bi-whatsapp text-white" aria-hidden="true"></i></a>

    </el-tooltip>
  </div>
</template>
<script>
import {NButton, NConfigProvider, NMessageProvider, NModal, NSpace} from "naive-ui";
import SideBar from "@/components/navigation/sidebar.vue";
import HeaderComp from "@/components/navigation/header.vue";
import colors from "@/utility/colors";
import store from "@/store/index"
import CompleteProfile from "@/components/user/profile.vue";
import callHub from "@/callHub"
export default {
  components: {
    NModal, CompleteProfile, NMessageProvider, NButton, NSpace, HeaderComp, SideBar, NConfigProvider,
  },
  data() {
    return {
      profileDialog: false,
      screenWidth: window.innerWidth,
      themeOverrides: {
        common: {
          primaryColor: '#020617',
          primaryColorHover: '#020617',
          primaryColorPressed: '#020617',
          primaryColorSuppl: '#020617'
        },
        // Button: {
        //   textColor: '#fff',
        //   colorPrimary: '#020617',
        // },
        // Select: {
        //   peers: {
        //     InternalSelection: {
        //       textColor: '#FF0000'
        //     }
        //   }
        // }
        // ...
      },
    };
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    callHub.start();
    callHub.client.on("ProfileCompleted", () => {
      sessionStorage.setItem("profileCompleted", true);
      this.$store.state.profileCompleted = true;
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    closeDialog() {
      this.profileDialog = false;
    },
    handleClose() {
      this.profileDialog = false;
    },
    handleResize() {
      store.state.showSidebar = window.innerWidth > 768;
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
  created() {
    window.addEventListener('resize', this.updateScreenWidth);
  },

  computed: {
    computedStyle() {
      if (!this.loggedIn) {
        return {marginLeft: '0px'};
      }
      if (this.loggedIn && this.$store.state.sidebarCollapsed) {
        return {marginLeft: '78px'};
      }
      return {marginLeft: this.showSidebar ? '220px' : '0px'};
      // return { marginLeft: this.sidebarCollapsed ? '78px' : '220px' };
    },
    headerStyle() {
      if (this.screenWidth <= 768) {
        return {marginLeft: '0px'};
      } else if (this.sidebarCollapsed && this.loggedIn) {
        return {marginLeft: '78px'};
      } else {
        return {marginLeft: '220px'};
      }
    },
    colors() {
      return colors
    },
    loggedIn() {
      return store.state.loggedIn;
    },
    profileCompleted() {
      return store.state.profileCompleted;
    },
    sidebarCollapsed() {
      return store.state.sidebarCollapsed;
    },
    showSidebar() {
      return store.state.showSidebar;
    },
    role() {
      return Number(sessionStorage.getItem("role"))
    }
  },
  watch: {
    screenWidth() {
      this.$forceUpdate();
    },
  },
};
</script>
<style>
@import "./assets/Styles/Style.css";
@import "./assets/MingIcons/MingCute.css";
@import "./assets/RemixIcons/remixicon.css";
@import "assets/bootstrap/css/bootstrap.css";
@import './assets/tailwind.css';
@import './assets/themes/elementplus.css';

.my-router {
  overflow-x: hidden !important;
}

.main-r {
  border-left: 1px solid #e3e3e3;
  position: relative !important;
}

#toggleBtn {
  position: absolute;
  top: 5px;
  left: 0px;
  z-index: 999 !important;
}

.n-float-button .n-float-button__body {
  padding: 0 !important;
}

.whatsapp {
  position: fixed;
  bottom: 15px;
  right: 10px;
  cursor: pointer;
}

.whatsapp img {
  width: 30px;
}

.btn-whatsapp-pulse {
  background: #1ad03f !important;
  color: white !important;
  position: fixed;
  bottom: 15px !important;
  right: 25px;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  padding: 30px;
  text-decoration: none !important;
  border-radius: 50%;
  animation-name: pulse;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  z-index:-1;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #1ad03f;
  }
  80% {
    box-shadow: 0 0 0 10px rgba(37, 211, 102, 0);
  }
}

</style>
