<template>
  <div class="d-flex justify-content-center align-items-center" style="height: calc(100vh - 100px);">
    <n-result
        status="404"
        title="404 Not Found"
        description=""
    >
      <template #footer>
        <router-link to="/home">
          <n-button :color="colors.blackPrimary">Go back to home</n-button>
        </router-link>
      </template>
    </n-result>
  </div>
</template>
<script>
import {NResult, NButton} from "naive-ui";
import colors from "@/utility/colors"

export default {
  name: "NotFound",
  components: {NButton, NResult},
  data() {
  },
  computed: {
    colors() {
      return colors;
    }
  }

}
</script>

<style scoped>

</style>