import axiosConfig from "@/config/axiosConfig";
import axios from "axios"

export default {
    getUrl: axiosConfig.apiBaseUrl + "Account/Get",
    allUrl: axiosConfig.apiBaseUrl + "Account/All",
    getAllUrl: axiosConfig.apiBaseUrl + "Account/GetAll",
    addOrUpdateUrl: axiosConfig.apiBaseUrl + "Account/AddOrUpdate",
    approveUrl: axiosConfig.apiBaseUrl + "Account/Approve",
    removeUrl: axiosConfig.apiBaseUrl + "Account/Remove?accountId=",
    deleteUrl: axiosConfig.apiBaseUrl + "Account/Delete?accountId=",
    categoriesUrl: axiosConfig.apiBaseUrl + "Account/GetCategories",
    getCategoryUrl: axiosConfig.apiBaseUrl + "Account/GetCategory?categoryId=",
    transferUrl: axiosConfig.apiBaseUrl + "Account/Transfer",
    renameUrl: axiosConfig.apiBaseUrl + "Account/Rename",
    depositUrl: axiosConfig.apiBaseUrl + "Account/Deposit",
    allAmountUrl: axiosConfig.apiBaseUrl + "Account/GetAllAmount?userId=",
    passwordUrl: axiosConfig.apiBaseUrl + "Account/ChangePassword?accountId=",
    usersAccountUr: axiosConfig.apiBaseUrl + "Account/GetAllAccounts",
    marginUrl: axiosConfig.apiBaseUrl + "Account/ChangeMargin?id=",
    getTransactionAccountsUrl: axiosConfig.apiBaseUrl + "Account/GetAccountsForTransaction",
    getUserAccountsUrl: axiosConfig.apiBaseUrl + "Account/GetUserAccounts?userId=",
    getIbTransferAccountsUrl: axiosConfig.apiBaseUrl + "Account/GetIbTransferAccounts?userId=",
    transferCommissionUrl: axiosConfig.apiBaseUrl + "Account/TransferCommission",
    getIbAccountUrl: axiosConfig.apiBaseUrl+"Account/GetIbAccount?userId=",
    withdrawCommissionUrl: axiosConfig.apiBaseUrl+"Account/WithdrawCommission",
    get(accountId) {
        const fd = new FormData();
        fd.append("accountId", accountId)
        return axios({
            method: "post",
            url: this.getUrl,
            data: fd,
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
            }
        });
    },
    getCategories() {
        return axios.get(this.categoriesUrl);
    },
    getCategory(categoryId) {
        return axios.get(this.getCategoryUrl + categoryId);
    },
    all(filter) {
        return axios.post(this.allUrl, filter)
    },
    getAll(filter) {
        return axios.post(this.getAllUrl, filter)
    },
    addOrUpdate(account) {
        return axios.post(this.addOrUpdateUrl, account)
    },
    changeMargin(id, margin) {
        return axios.get(this.marginUrl + id + "&margin=" + margin);
    },
    remove(accountId) {
        return axios.get(this.removeUrl+accountId)
    },
    delete(accountId) {
       return axios.get(this.deleteUrl+accountId)
    },
    transfer(model) {
        return axios.post(this.transferUrl, model)
    },
    rename(model) {
        return axios.post(this.renameUrl, model);
    },
    deposit(model) {
        return axios.post(this.depositUrl, model);
    },
    getAllAmount(userId) {
        return axios.get(this.allAmountUrl + userId);
    },
    changePasswords(accountId, password) {
        return axios.get(this.passwordUrl + accountId + "&password=" + password);
    },
    getUsersAccount(filter) {
        return axios.post(this.usersAccountUr, filter)
    },
    getTransactionsAccount(filter) {
        return axios.post(this.getTransactionAccountsUrl, filter)
    },
    getUserAccounts(userId) {
        return axios.get(this.getUserAccountsUrl + userId);
    },
    approve(account) {
        return axios.post(this.approveUrl, account)
    },
    getIbTransferAccounts(userId) {
        return axios.get(this.getIbTransferAccountsUrl+ userId)
    },
    transferCommission(model){
        return axios.post(this.transferCommissionUrl, model);
    },
    getIbAccount(userId) {
        return axios.get(this.getIbAccountUrl+userId);
    },
    withdrawCommission(model){
        return axios.post(this.withdrawCommissionUrl,model)
    }
}