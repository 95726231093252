<template>
  <div>
    <div class="row align-items-center justify-between">
      <div class="col-12 col-lg-4 flex align-items-center">
        <router-link to="deposit">
          <n-button tertiary class="me-2" size="small">
            <i class="bi bi-arrow-left"></i>
          </n-button>
        </router-link>
        <span class="text-lg font-semibold">Deposit</span>
      </div>
      <div class="mt-3">
        <span class="h3">Verify your deposit</span>
        <div class="row mt-2">
          <div class="col-12 col-md-4">
            <span class="">Please upload image/screenshot of your payment below.</span>
            <div class="mt-2">
              <div class="position-relative d-flex w-100">
                <div v-if="deposit.documentJson !== '' && deposit.documentJson !== undefined"
                  class="rounded p-2 border-1 border-gray-400">
                  <img :width="300" :height="300" :src="JSON.parse(deposit.documentJson).path" alt="Image">

                  <el-button style="position: absolute; right: 0;top:0" circle bg text size="small"
                    v-if="deposit.documentJson !== '' && deposit.documentJson !== undefined"
                    @click="removeDoc(JSON.parse(deposit?.documentJson).path)">
                    <i class="bi bi-x"></i>
                  </el-button>
                </div>
                <div v-else>
                  <input type="file" id="upload-photo" class="d-none" :key="fileInputKey" ref="uploader1"
                    accept="image/*" @change="onFileChanged($event)">
                  <el-button style="width: 300px !important; height: 300px !important; border: 1px dashed gray"
                    @click="handleFileImport()" bg text>
                    <i class="bi bi-plus"></i>
                  </el-button>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <n-button :disabled="!this.deposit.documentJson" @click="makeDeposit()">Verify
          </n-button>
        </div>
      </div>
    </div>
    <div v-if="loading" class="spinner-border cstm-loading">
    </div>
  </div>
</template>
<script>
import { NButton } from "naive-ui";
import colors from "@/utility/colors"
import accountService from "@/services/accountService";
import showMessage from "@/utility/showMessage"
import store from "@/store";
import settingsService from "@/services/settingsService";
import documentService from "@/services/documentService";
import imageCompression from 'browser-image-compression';
export default {
  name: "DepositProof",
  components: { NButton },
  data() {
    return {
      loading: false,
      fileInputKey: Date.now(),
      deposit: {},
      config: {},
    }
  },
  computed: {
    colors() {
      return colors;
    },
    role() {
      return this.$store.state.role;
    }
  },
  methods: {
    isBase64Image(imageUrl) {
      if (imageUrl === '' || imageUrl === null || imageUrl === undefined) {
        return;
      }
      // Check if the image URL starts with the specified base64 prefix
      return imageUrl.startsWith("data:image/jpeg;base64,") || imageUrl.startsWith("data:image/png;base64,");
    },
    handleFileImport() {

      this.$refs.uploader1.click();
    },
    async onFileChanged() {
      this.loading = true;
      this.loaderText = "Uploading Image to Server"
      this.fileInputKey = new Date();
      const fileInput = document.getElementById("upload-photo");

      // Check if a file was selected
      if (fileInput.files && fileInput.files.length) {
        const selectedFile = fileInput.files[0];
        if (!selectedFile.type.match('image.*')) {
          this.loading = false;
          showMessage("error", 'Only image files are allowed');
          return;
        }
        if (this.getFileSize(selectedFile.size) > 10000) {
          showMessage("error", "The image size should be under 10Mb");
          this.loading = false;
          this.loaderText = "Saving & Continue";
          return;
        }
        const compressedFile = await this.compressImage(selectedFile);


        /* eslint-disable no-unreachable */
        let response = await documentService.upload(compressedFile);
        if (response?.data !== "") {
          this.deposit.documentJson = JSON.stringify({
            fileName: selectedFile.name,
            path: response?.data ?? "",
            type: "Deposit",
          });
        }
        this.loading = false;

      }
      this.loaderText = "Saving & Continue";
    },
    async compressImage(file) {
      const options = {
        maxSizeMB: 3,  // Maximum size in MB
        maxWidthOrHeight: 1000,  // Maximum width or height of the image
        useWebWorker: true,  // Enable web worker for better performance
        fileType: "image/*"  // Output file type (optional)
      };

      try {
        // Compress the image file using browser-image-compression
        const compressedFile = await imageCompression(file, options);
        return compressedFile;  // Return the compressed image file
      } catch (error) {
        console.error("Compression error:", error);
        throw error;  // Throw error if compression fails
      }
    },
    getFileSize(fileSizeInBytes) {
      return fileSizeInBytes / 1024;
    },
    async removeDoc(blob) {
      this.loading = true;
      try {
        const url = blob;
        const docsIndex = url.indexOf('medatiq-docs/');
        const blobName = url.substring(docsIndex + 12);
        let response = await documentService.delete(blobName);

        if (response?.data) {
          this.deposit.documentJson = "";
        }
      } catch (e) {
        showMessage("error", "Image deletion failed" ?? e.response?.data.msg)
      }
      this.loading = false;
    },
    /*onFileChanged() {
      this.fileInputKey = new Date();
      const fileInput = document.getElementById("upload-photo");

      // Check if a file was selected
      if (fileInput.files && fileInput.files.length) {
        const selectedFile = fileInput.files[0];

        // Extract file properties
        this.deposit.document.fileName = selectedFile.name;
        this.deposit.document.fileSize = selectedFile.size.toString();
        this.deposit.document.fileExt = selectedFile.name.split('.').pop();
        this.deposit.document.mimeType = selectedFile.type;
        this.deposit.document.lastModifiedAt = new Date(selectedFile.lastModified);

        // Convert the selected image file to Base64
        this.convertFileToBase64(selectedFile)
            .then((base64Data) => {
              // Assign the Base64 data to this.deposit.document.fileData
              this.deposit.document.fileData = `data:${selectedFile.type};base64,${base64Data}`;

              // Optionally, display a success message or perform other actions
            })
            .catch((error) => {
              console.error("Error converting image to Base64:", error);
            });
      }
    },*/
    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result.split(',')[1]); // Get the Base64 string
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    calculatedUsd(amount) {
      if (amount < 1 || typeof Number(this.config?.value) !== 'number') return amount;
      return (amount / Number(this.config?.value)).toFixed(2);
    },
    async makeDeposit() {
      this.loading = true;
      try {
        if (this.$store.state.fundingAccountType === 10) {
          this.deposit.amount = this.calculatedUsd(this.deposit.amount);
        }
        let response = await accountService.deposit(this.deposit);
        if (response?.data?.isSuccessful) {
          showMessage("success", response?.data?.msg);
          store.state.updateHeader = true;
          this.$router.push('/accounts')
        } else {
          showMessage("error", response?.data?.msg ?? "Complete your profile to deposit");
          this.$router.push('/accounts');
        }
      } catch (e) {
        showMessage("error", "Deposit failed");
      }
      this.loading = false;
    },
    checkLogin() {
      return (sessionStorage.getItem("loggedIn") === null || sessionStorage.getItem("loggedIn") === undefined || sessionStorage.getItem("loggedIn") === false);
    },
    async getCurrencyConfig() {
      let response = await settingsService.getByKey("USD");
      this.config = response?.data ?? {};

    },
  },
  created() {
    if (!this.checkLogin) {
      this.$router.push('/');
      return;
    }
    this.deposit = JSON.parse(JSON.stringify(this.$store.state.depositAccount));
    if (this.deposit !== undefined) {
      this.deposit.amount = Number(this.deposit.amount);
      if (Number(this.role) === 10) {
        this.deposit.transactionStatus = 20
      }
    }
    this.getCurrencyConfig();
  }
}
</script>

<style scoped></style>