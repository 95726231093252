import axiosConfig from "@/config/axiosConfig";
import axios from "axios"

export default {
    //loginUrl: axiosConfig.apiBaseUrl + "Auth/Authenticate",
    loginUrl: axiosConfig.apiBaseUrl + "Auth/Login",
    signupUrl: axiosConfig.apiBaseUrl+"Auth/Signup",
    requestPasswordUrl: axiosConfig.apiBaseUrl +"Auth/RequestPassword?userName=",

    login(loginModel) {
        return axios.post(this.loginUrl, loginModel)
    },
    signup(model) {
        return axios.post(this.signupUrl, model)
    },
    requestPassword(username){
        return axios.get(this.requestPasswordUrl+username);
    }

}