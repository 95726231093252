import axiosConfig from "@/config/axiosConfig";
import axios from "axios"

export default {
    //loginUrl: axiosConfig.apiBaseUrl + "Auth/Authenticate",
    allUrl: axiosConfig.apiBaseUrl + "Config/All",
    getAllUrl: axiosConfig.apiBaseUrl + "Config/GetAll",
    getUrl: axiosConfig.apiBaseUrl + "Config/Get?configId=",
    getByKeyUrl: axiosConfig.apiBaseUrl + "Config/GetByKey?key=",
    addOrUpdateUrl: axiosConfig.apiBaseUrl + "Config/AddOrUpdate",
    deleteUrl: axiosConfig.apiBaseUrl + "Config/Delete?configId=",

    all(filter) {
        return axios.post(this.allUrl, filter)
    },
    getAll(filter) {
        return axios.post(this.getAllUrl, filter)
    },
    get(configId) {
        return axios.get(this.getUrl + configId)
    },
    getByKey(key) {
        return axios.get(this.getByKeyUrl + key)
    },
    addOrUpdate(config) {
        return axios.post(this.addOrUpdateUrl, config)
    },
    delete(configId) {
        return axios.get(this.deleteUrl + configId)
    },
}