import {ElMessage} from "element-plus";

export default function showMessage(type, message) {
    ElMessage({
        showClose: true,
        message: message,
        type: type,
        grouping: true,

    })
}