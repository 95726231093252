<template>
  <div style="height: 100vh; z-index: 999">
    <div class="cstm-header border-b d-flex justify-content-center align-items-center">
      <img v-if="!collapsed" src="../../assets/img/logos/logo2.png" alt="Logo" :width="120">
      <img v-else src="../../assets/img/logos/logo1.png" alt="Logo" :width="30">
      <!--      <span v-if="!collapsed" class="text-2xl">Medatiq</span>-->
      <!--      <span v-else class="text-2xl">M</span>-->
    </div>
    <el-menu
        class="border-0 bg-surface py-1 d-flex flex-column"
        :collapse="collapsed"
        :collapse-transition="false"
        router
        background-color="#f8fafc"
        :style="{height: loggedIn ? 'calc(100vh - 60px)' : '100vh'}"
    >
      <el-menu-item v-if="Number(role) === 10 || Number(role) === 20" index="1" route="home"
                    :class="{ 'active-menu-item': $route.path === '/home' }">
        <el-icon>
          <i class="bi bi-grid"></i>
        </el-icon>
        <template #title>
          <span>Dashboard</span>
        </template>
      </el-menu-item>
      <el-menu-item index="2" route="accounts" v-if="Number(role) === 30" @click="store.state.notificationType = 0"
                    :class="{ 'active-menu-item': $route.path === '/accounts' }">
        <el-icon>
          <i class="bi bi-layers"></i>
        </el-icon>
        <template #title>
          <span>My Accounts</span>
        </template>
      </el-menu-item>
      <el-menu-item index="3" route="deposits" @click="store.state.notificationType = 0"
                    :class="{ 'active-menu-item': isActive('/deposits') }">
        <el-icon>
          <i class="bi bi-arrow-down-circle"></i>
        </el-icon>
        <template #title>
          <span>Deposit</span>
        </template>
      </el-menu-item>
      <el-menu-item index="4" route="withdraws" @click="store.state.notificationType = 0"
                    :class="{ 'active-menu-item': isActive('/withdraws') }">
        <el-icon>
          <i class="bi bi-arrow-up-right-circle"></i>
        </el-icon>
        <template #title>
          <span>Withdraw</span>
        </template>
      </el-menu-item>
      <el-menu-item index="5" route="transactions" :class="{ 'active-menu-item': isActive('/transactions') }" @click="store.state.notificationType = 0">
        <el-icon>
          <!--          <i class="bi bi-currency-bitcoin"></i>-->
          <i class="bi bi-currency-dollar"></i>
        </el-icon>
        <template #title>
          <span>Transactions</span>
        </template>
      </el-menu-item>
      <el-menu-item  index="11" route="partners" :class="{ 'active-menu-item': isActive('/partners') }" @click="store.state.notificationType = 0">
        <el-icon>
          <!--          <i class="bi bi-currency-bitcoin"></i>-->
          <i class="bi bi-layout-text-sidebar"></i>
        </el-icon>
        <template #title>
          <span>IB Dashboard</span>
        </template>
      </el-menu-item>
      <el-menu-item  @click="profileDialog = true" v-if="Number(role) === 30">
        <el-icon>
          <i class="bi bi-person"></i>
        </el-icon>
        <template #title>
          <span>Profile</span>
        </template>
      </el-menu-item>
      <el-menu-item v-if="Number(role) === 10 || Number(role) === 20" index="6" route="users" :class="{ 'active-menu-item': isActive('/users') }">
        <el-icon>
          <i class="bi bi-people"></i>
        </el-icon>
        <template #title>
          <span>Users</span>
        </template>
      </el-menu-item>
      <el-menu-item v-if="Number(role) === 10 || Number(role) === 20" index="8" route="tickets"
                    :class="{ 'active-menu-item': isActive('/tickets') }">
        <el-icon>
          <i class="bi bi-patch-question"></i>
        </el-icon>
        <template #title>
          <span>Support Tickets</span>
        </template>
      </el-menu-item>
      <el-menu-item v-if="Number(role) === 10 || Number(role) === 20" index="9" route="useraccounts"
                    :class="{ 'active-menu-item': isActive('/useraccounts') }">
        <el-icon>
          <i class="bi bi-person-circle"></i>
        </el-icon>
        <template #title>
          <span>Users Accounts</span>
        </template>
      </el-menu-item>
      <el-menu-item v-if="Number(role) === 10 || Number(role) === 20" index="38" route="funding-accounts"
                    :class="{ 'active-menu-item': isActive('/funding-accounts') }">
        <el-icon>
          <i class="bi bi-bank"></i>
        </el-icon>
        <template #title>
          <span>Funding Accounts</span>
        </template>
      </el-menu-item>
<!--      <el-menu-item v-if="Number(role) === 10 || Number(role) === 20" index="37" route="settings"
                    :class="{ 'active-menu-item': isActive('/settings') }">
        <el-icon>
          <i class="bi bi-gear"></i>
        </el-icon>
        <template #title>
          <span>Settings</span>
        </template>
      </el-menu-item>-->
<!--      <el-menu-item v-if="Number(role) === 10" index="12" route="ib"
                    :class="{ 'active-menu-item': isActive('/ib') }">
        <el-icon>
          <i class="bi bi-layout-text-sidebar"></i>
        </el-icon>
        <template #title>
          <span>IB Dashboard</span>
        </template>
      </el-menu-item>-->
<!--      <div class="flex-grow"></div>-->


    </el-menu>


    <n-modal v-model:show="profileDialog"  title="Complete Profile" preset="card" style="width: 700px; min-height: 530px;">
      <template #default>
      <CompleteProfile @close-dialog="closeDialog()" style="height: 100%" />
      </template>
    </n-modal>



  </div>
</template>
<script>
import store from '@/store/index'
import colors from '@/utility/colors'
import  CompleteProfile from "@/components/user/profile.vue"
import {NModal} from "naive-ui";

export default {
  name: 'SideBar',
  components: {NModal, CompleteProfile},
  data() {
    return {
      loggedIn: store.state.loggedIn,
      profileDialog: false,
      activeMenuItem: this.$route.path,
    }
  },
  watch: {
    $route(to) {
      this.activeMenuItem = to.path;
    },
  },

  methods:{
    getFormattedAmount(amount){
      if(amount < 1) return amount;
      return Number(amount).toFixed(2)
    },
    // isActive(route) {
    //   return this.$route.path === route;
    // },
    handleClose() {
      this.profileDialog = false;
    },
    closeDialog() {
      this.profileDialog = false;
    },
  },
  computed: {
    isActive() {
      return (route) => {
        return this.$route.path === route;
      };
    },
    colors() {
      return colors
    },
    role() {
      return store.state.role;
    },
    collapsed() {
      return store.state.sidebarCollapsed;
    },
    store(){
      return store;
    }
  },
}
</script>

<style scoped>
.el-menu-item.is-active {
  color: #fff !important;
  background-color: #020617 !important;
}

.active-menu-item {
  color: #fff !important;
  background-color: #020617 !important;
}


/*
.el-menu-item.is-active {
  color: #18a058 !important;
  background-color: #18a05829 !important;
}
*/
.el-menu-item:hover {
  background-color: #f1f5f9 !important;
}

.el-menu-item.is-active.active-menu-item:hover {
  color: #fff !important;
  background-color: #020617E8 !important;
}

.active-menu-item:hover {
  color: #fff !important;
  background-color: #020617E8 !important;
}

/*.el-menu-item:hover{
  background-color: #18a05829 !important;
}*/
.el-menu-item {
  border-radius: 5px;
  height: 40px;
  color: #64748b;
  margin: 5px 0 5px 0 !important;

}


</style>