import axiosConfig from "@/config/axiosConfig";
import axios from "axios"

export default {
    completeProfileUrl: axiosConfig.apiBaseUrl + "User/CompleteProfile?type=",
    getProfileUrl: axiosConfig.apiBaseUrl + "User/GetProfile?userId=",
    allUrl: axiosConfig.apiBaseUrl + "User/All",
    getUserUrl: axiosConfig.apiBaseUrl + "User/Get?userId=",
    getAllUsersUrl: axiosConfig.apiBaseUrl + "User/GetAllUsers?role=",
    referralCodeUrl: axiosConfig.apiBaseUrl + "User/RequstReferral?userId=",
    generateReferralUrl: axiosConfig.apiBaseUrl + "User/GenerateReferral?userId=",
    referralUrl: axiosConfig.apiBaseUrl + "User/GetReferrals",
    ibUsersUrl: axiosConfig.apiBaseUrl + "User/GetIbUsers",
    ibParentAccountsUrl: axiosConfig.apiBaseUrl + "User/GetIbAccounts?referralCode=",
    ibDashboardDataUrl: axiosConfig.apiBaseUrl + "User/GetIbDashboardData?userId=",
    updateUrl: axiosConfig.apiBaseUrl+"User/Update",

    completeProfile(profile,type) {
        return axios.post(this.completeProfileUrl+type, profile)
    },
    getProfile(userId) {
        return axios.get(this.getProfileUrl + userId)
    },
    all(filter) {
        return axios.post(this.allUrl, filter)
    },
    getUser(userId) {
        return axios.get(this.getUserUrl + userId);
    },
    getAllUsers(role) {
        return axios.get(this.getAllUsersUrl + role);
    },
    requestReferralCode(userId) {
        return axios.get(this.referralCodeUrl + userId);
    },
    generateReferral(userId) {
        return axios.get(this.generateReferralUrl + userId)
    },
    getReferrals(filter) {
        return axios.post(this.referralUrl, filter)
    },
    getIbUsers() {
        return axios.get(this.ibUsersUrl);
    },
    getIbParentAccounts(referralCode) {
        return axios.get(this.ibParentAccountsUrl + referralCode);
    },
    getIbDashboardData(filter) {
        return axios.get(this.ibDashboardDataUrl + filter.userId);
    },
    update(user){
        return axios.post(this.updateUrl,user)
    }

}