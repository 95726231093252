<template>
  <div>
    <div class="row align-items-center justify-between">
      <div class="col-12 col-lg-4">
        <BreedCrumb/>
      </div>
      <div class="col-12 col-lg-4">
        <div class="d-flex flex-md-row flex-column  justify-content-end">

          <n-button type="primary" class="ms-md-2 mt-3 w-100 w-auto" @click="addDialog = true;resetAccount()">Add
            Funding Account
          </n-button>

        </div>
      </div>
    </div>
    <n-divider class="my-1"></n-divider>
    <el-table border :data="accounts" header-cell-class-name="tb-header" :show-overflow-tooltip="true">
      <template #empty>
        <n-empty class="pt-4" description="No Data">
        </n-empty>
      </template>
      <el-table-column width="200" label="Bank" prop="bankName"></el-table-column>
      <el-table-column width="200" label="Title" prop="accountTitle"></el-table-column>
      <el-table-column label="Account #" prop="accountNumber"></el-table-column>
      <el-table-column width="150" label="Branch" prop="branch">
      </el-table-column>
      <el-table-column width="120" label="Type" prop="type">
        <template #default="scope">
          <span v-if="scope.row.type === 10">Bank</span>
          <span v-if="scope.row.type === 20">TRC20</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="Action" width="90">
        <template #default="scope">
          <div class="text-center">
            <el-tooltip content="Edit">
              <i class="mgc_edit_line action-btn text-primary" @click="takeRow(scope.row, 'edit')"></i>
            </el-tooltip>
            <el-tooltip content="Delete">
              <i class="mgc_delete_2_line ms-2 action-btn text-danger" @click="takeRow(scope.row, 'delete')"></i>
            </el-tooltip>

          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="mt-2 d-flex justify-content-end">
      <n-pagination
          class="d-pagination"
          v-model:page="pagination.page"
          v-model:page-size="pagination.pageSize"
          :page-count="pagination.totalPages"
          show-size-picker
          :page-sizes="pageSizes"
          :on-update-page="pageChange"
          :on-update-page-size="pageSizeChange"
      />
    </div>

    <el-dialog v-model="addDialog" :title="account.id > 0 ? 'Update' :'Add' + ' Funding Account' "
               style="width: auto; max-width: 500px;">
      <template #default>
        <el-form label-position="top">
          <el-form-item label="Account Type">
            <el-select v-model="account.type" @change="changeType(account.type)">
              <el-option v-for="type in types" :key="type.value" :label="type.text" :value="type.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Bank Name">
            <el-input v-model="account.bankName">
            </el-input>
          </el-form-item>
          <el-form-item label="Account Title">
            <el-input v-model="account.accountTitle">
            </el-input>
          </el-form-item>
          <el-form-item :label="account.type === 10 ? 'Account Number' : 'Wallet Address'">
            <el-input v-model="account.accountNumber">
            </el-input>
          </el-form-item>
          <el-form-item label="Branch" v-if="account.type === 10">
            <el-input v-model="account.branch">
            </el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <n-button tertiary @click="addDialog = false">Cancel</n-button>
          <n-button type="primary" class="ms-2" @click="addOrUpdate">{{ account.id < 1 ? 'Add' : 'Update' }}</n-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="deleteDialog" title="Delete Funding Account"
      style="width: auto; max-width: 500px;">
      <template #default>
        <span>Are you sure? You want to delete <span class="text-danger">{{ account.accountTitle ?? '' }}</span>?</span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <n-button tertiary @click="deleteDialog = false">Cancel</n-button>
          <n-button type="error" class="ms-2" @click="deleteAccount()">Delete</n-button>
        </div>
      </template>
    </el-dialog>


    <n-spin v-if="loading" class="mn-spin" :stroke-width="12" :size="25"
            style="z-index: 999!important;"></n-spin>
  </div>
</template>
<script>
import {NButton, NDivider, NEmpty, NPagination, NSpin} from "naive-ui";
import BreedCrumb from "@/components/ui/breadcrumb.vue";
import fundingAccountService from "@/services/fundingAccountService";
import showMessage from "@/utility/showMessage"

export default {
  name: "FundingAccounts",
  components: {NSpin, NPagination, BreedCrumb, NDivider, NButton, NEmpty},
  data() {
    return {
      loading: false,
      addDialog: false,
      deleteDialog: false,
      accounts: [],
      account: {
        "id": 0,
        "bankName": "",
        "accountTitle": "",
        "accountNumber": "",
        "branch": "",
        "currency": "",
        "type": 10,
        "logo": ""
      },
      types: [
        {text: "Bank", value: 10},
        {text: "TRC20", value: 20},
      ],
      filter: {
        "pageSize": 10,
        "page": 1,
        "filterText": "",
        "filterTag": "",
        "belongsToAdmin": true
      },
      pagination: {
        fetched: 0,
        page: 1,
        pageSize: 10,
        records: 0,
        totalPages: 0
      },
      pageSizes: [
        {
          label: '10 per page',
          value: 10
        },
        {
          label: '20 per page',
          value: 20
        },
        {
          label: '30 per page',
          value: 30
        },
        {
          label: '40 per page',
          value: 40
        }
      ]
    }
  },
  methods: {
    pageChange(page) {
      this.filter.page = page;
      this.getAll();
    },
    pageSizeChange(pageSize) {
      this.filter.pageSize = pageSize;
      this.getAll();
    },
    changeType(type) {
      if (type === 10) {
        this.account.accountTitle = "";
        this.account.bankName = "";
      } else {
        this.account.accountTitle = "TRC20";
        this.account.bankName = "TRC20";
      }
    },
    takeRow(account, type) {
      this.account = JSON.parse(JSON.stringify(account));
      switch (type) {
        case 'edit':
          this.addDialog = true;
          break;
        case 'delete':
          this.deleteDialog = true;
          break;
      }
    },
    async getAll() {
      this.loading = true;
      try {
        let response = await fundingAccountService.all(this.filter);
        this.accounts = response?.data?.data ?? [];
      } catch (e) {
        showMessage("error", e?.data.msg ?? "Account adding failed");
      }
      this.loading = false;
    },
    async deleteAccount() {
      this.loading = true;
      this.deleteDialog = false;
      try {
        let response = await fundingAccountService.delete(this.account.id);
        if (response?.data) {
          showMessage("success", "Account Deleted");
          await this.getAll();
        }
        else{
          showMessage("error", "Something went wrong. Try again later");
        }
      } catch (e) {
        showMessage("error", e?.data.msg ?? "Account deleting failed");
      }
      this.loading = false;
    },
    async addOrUpdate() {
      this.loading = true;
      this.addDialog = false;
      try {
        let response = await fundingAccountService.addOrUpdate(this.account);
        if (response?.data?.isSuccessful) {
          showMessage("success", this.account.id > 0 ? 'Account Updated' : 'Account Added');
          await this.getAll();
        }
      } catch (e) {
        showMessage("error", e?.data.msg ?? "Account adding failed");
      }
      this.loading = false;
    },
    resetAccount() {
      this.account = {
        "id": 0,
        "bankName": "",
        "accountTitle": "",
        "accountNumber": "",
        "branch": "",
        "currency": "",
        "type": 10,
        "logo": ""
      };
    }
  },
  created() {
    this.getAll();
  }
}
</script>

<style scoped>

</style>