import axios from "axios";
import axiosConfig from "@/config/axiosConfig";

export default {
    allUrl: axiosConfig.apiBaseUrl + "Notification/All?userId=",
    markReadUrl: axiosConfig.apiBaseUrl + "Notification/MarkRead?type=",

    all(userId) {
        return axios.get(this.allUrl + userId)
    },
    markRead(type) {
        const userId = Number(sessionStorage.getItem("userId"));
        return axios.get(this.markReadUrl + type + "&userId=" + userId)
    }
}