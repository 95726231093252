<template>
  <component :is="tag"  :class="cssClass" v-bind="$attrs">
    {{ formattedNumber }}
  </component>
</template>

<script>
import { ref, watch, defineComponent, onMounted, computed } from 'vue';
import { gsap } from 'gsap';

export default defineComponent({
  name: 'AnimatedNumber',
  props: {
    tag: {
      type: String,
      default: 'span'
    },
    cssClass: {
      type: String,
      default: ''
    },
    from: {
      type: Number,
      default: 0
    },
    to: {
      type: Number,
      required: true
    },
    duration: {
      type: Number,
      default: 2 // Default duration is 2 seconds
    },
    delay: {
      type: Number,
      default: 0 // Default delay is 0 seconds
    },
    format: {
      type: Function,
      default: null
    }
  },
  setup(props) {
    const currentNumber = ref(props.from);

    const formattedNumber = computed(() => {
      return props.format ? props.format(currentNumber.value) : currentNumber.value;
    });

    const animateNumber = () => {
      gsap.to(currentNumber, {
        value: props.to,
        duration: props.duration,
        delay: props.delay,
        roundProps: "value",
        ease: "power1.out",
        onUpdate: () => {
          currentNumber.value = parseFloat(gsap.getProperty(currentNumber, "value"));
        }
      });
    };

    watch(
        () => props.to,
        () => {
          animateNumber();
        }
    );

    watch(
        () => props.from,
        (newValue) => {
          currentNumber.value = newValue;
          animateNumber();
        }
    );

    onMounted(() => {
      animateNumber();
    });

    return {
      currentNumber,
      formattedNumber
    };
  }
});
</script>

<style scoped>
/* Add any scoped styles here */
</style>
