<template>
  <div class="vh-100">
    <div class="row justify-content-center align-items-center h-100">
      <div class="col-12 col-md-6 text-center">
        <span class="h2">We have sent a verification link to your email.</span> <br>
        <span class="h6">Please click the link to verify and then login to your account</span>
        <br>
        <router-link to="/">
          <el-button class="mt-3" type="primary">Back to Login</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>


export default {
  name: "verificationScreen",
  components: {},
  data() {
    return {}
  }

}
</script>

<style scoped>

</style>