import axiosConfig from "@/config/axiosConfig";
import axios from "axios"

export default {
    getUrl: axiosConfig.apiBaseUrl + "Transaction/Get",
    addOrUpdateUrl: axiosConfig.apiBaseUrl + "Transaction/AddOrUpdate",
    allUrl: axiosConfig.apiBaseUrl + "Transaction/All",
    getAllUrl: axiosConfig.apiBaseUrl + "Transaction/GetAll",
    removeUrl: axiosConfig.apiBaseUrl + "Transaction/Remove",
    withdrawUrl: axiosConfig.apiBaseUrl + "Transaction/Withdraw",
    statusUrl: axiosConfig.apiBaseUrl + "Transaction/UpdateStatus?transactionId=",
    addCommissionUrl: axiosConfig.apiBaseUrl + "Transaction/AddCommission",
    get(paymentMethodId) {
        const fd = new FormData();
        fd.append("paymentMethodId", paymentMethodId)
        return axios({
            method: "post",
            url: this.getUrl,
            data: fd,
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
            }
        });
    },
    addCommission(model) {
        return axios.post(this.addCommissionUrl, model)
    }, withdraw(model) {
        return axios.post(this.withdrawUrl, model)
    },
    all(filter) {
        return axios.post(this.allUrl, filter)
    },
    getAll(filter) {
        return axios.post(this.getAllUrl, filter)
    },
    addOrUpdate(transaction) {
        return axios.post(this.addOrUpdateUrl, transaction)
    },
    remove(accountId) {
        const fd = new FormData();
        fd.append("accountId", accountId)
        return axios({
            method: "post",
            url: this.removeUrl,
            data: fd,
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
            }
        });
    },
    changeStatus(id, status) {
        return axios.get(this.statusUrl + id + "&status=" + status);
    }
}