<template>
  <div>
    <span class="text-lg font-semibold">{{ pageTitle }}</span>
    <n-breadcrumb v-if="showBread">
      <n-breadcrumb-item separator=">">
        <router-link to="/" class="p-0">
          Home
        </router-link>
      </n-breadcrumb-item>
      <n-breadcrumb-item v-for="item in items" :key="item" separator=">">
        {{ item }}
      </n-breadcrumb-item>
    </n-breadcrumb>

  </div>
</template>
<script>
import {NBreadcrumb, NBreadcrumbItem} from "naive-ui";

export default {
  name: "BreedCrumb",
  components: {NBreadcrumbItem, NBreadcrumb},
  data() {
    return {}
  },
  props: {
    showBread: {
      type: Boolean,
      default: true
    },
    id: {
      type: Number,
      default: 0
    },
    view: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    items() {
      return this.$route.meta.breadcrumbs;
    },
    pageTitle() {
      return this.$route.meta.pageTitle;
    }
  }
}
</script>


<style scoped>

</style>