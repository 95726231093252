<template>
  <div>
    <div class="row align-items-center justify-between">
      <div class="col-12 col-lg-3">
        <BreedCrumb/>
      </div>
      <el-divider class="d-md-none mt-1"></el-divider>
      <div class="col-12 col-lg-7">
        <div class="d-flex flex-md-row flex-column justify-content-end">
          <div v-if="filter.role === 10 || filter.role === 20" class="d-flex flex-column ms-md-2 w-100">
            <span class="text-xs">User</span>
            <el-select v-model="filter.userId" filterable

                       @change="filter.page=1; getAllUsersAccounts(filter.userId)">
              <el-option v-for="type in users" :key="type.id" :label="type.fullName"
                         :value="type.id"></el-option>
            </el-select>
          </div>
          <div class="d-flex flex-column ms-md-2 mt-2 mt-md-0  w-100">
            <span class="text-xs">Account</span>
            <el-select v-model="filter.accountId" @change="getTransactions()"
                       filterable>
              <el-option v-for="type in usersAccounts" :key="type.id" :label="type.accountName"
                         :value="type.id"></el-option>
            </el-select>
          </div>
          <div class="d-flex flex-column ms-md-2 mt-2 mt-md-0  w-100">
            <span class="text-xs">Transaction Type</span>
            <el-select v-model="filter.transactionType" @change="getTransactions()"
                       filterable>
              <el-option v-for="type in transactionTypes" :key="type.key" :label="type.label"
                         :value="type.value"></el-option>
            </el-select>
          </div>
          <!--          <el-date-picker style="width: 300px" class="cursor-pointer ms-2" format="DD-MMM-YYYY" v-model="dateRange"-->
          <!--                          type="daterange"-->
          <!--                          :editable="false" @change="dateChange()"></el-date-picker>-->
          <div class="d-flex flex-column ms-md-2  mt-2 mt-md-0">
            <span class="text-xs">Add</span>
            <el-tooltip content="Add Transaction">
              <el-button type="primary" class="" @click="addDialog = true;usersAccounts =[]; resetTransactionModel()">
                <i class="bi bi-plus"></i>
              </el-button>
            </el-tooltip>
          </div>
        </div>

      </div>
    </div>
    <n-divider class="my-1"></n-divider>
    <el-table border :data="transactions" header-cell-class-name="tb-header" show-overflow-tooltip>
      <template #empty>
        <n-empty class="pt-4" description="No Data">
        </n-empty>
      </template>
      <el-table-column min-width="100" label="Account" prop="account.accountName"></el-table-column>
      <el-table-column min-width="100" label="Amount" prop="amount"></el-table-column>
      <el-table-column min-width="100" label="Account #" prop="account.accountNumber"></el-table-column>
      <el-table-column min-width="100" label="Fee" prop="fee"></el-table-column>
      <el-table-column min-width="100" label="Type" prop="transactionType"></el-table-column>
      <el-table-column min-width="100" label=" Date" prop="transactionDate">
        <template #default="scope">
          <span>{{ getFormattedDate(scope.row.transactionDate) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="USD Rate" prop="usdRate"></el-table-column>
      <el-table-column label="Source" prop="source"></el-table-column>
      <el-table-column label="Status" prop="transactionStatus" width="100">
        <template #default="scope">
          <div class="text-center">
            <el-tag effect="dark" v-if="scope.row.status === 10" size="small" type="info" :bordered="false"
                    style="width: 80px">
              {{ scope.row.transactionStatus }}
            </el-tag>
            <el-tag effect="dark" v-if="scope.row.status === 20" size="small" type="success" :bordered="false"
                    style="width: 80px">
              {{ scope.row.transactionStatus }}
            </el-tag>
            <el-tag effect="dark" v-if="scope.row.status === 30" size="small" type="danger" :bordered="false"
                    style="width: 80px">
              {{ scope.row.transactionStatus }}
            </el-tag>
            <!--         -->
            <!--            <n-tag v-if="scope.row.status === 20" size="small" type="success" :bordered="false">-->
            <!--              {{ scope.row.transactionStatus }}-->
            <!--            </n-tag>-->
            <!--            <n-tag v-if="scope.row.status === 30" size="small" type="error" :bordered="false">-->
            <!--              {{ scope.row.transactionStatus }}-->
            <!--            </n-tag>-->
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="Action" width="100" v-if="filter.role !== 30">
        <template #default="scope">
          <div class="d-flex justify-content-between">
            <el-tooltip content="view Details">
              <i class="bi bi-eye action-btn text-primary" v-if="scope.row.type !== 30 && scope.row.type !== 40" @click="takeRow(scope.row, 'details')"></i>
            </el-tooltip>
            <n-dropdown v-if="scope.row.status !== 20" trigger="click" :options="statuses" @select="handleSelect"
                        placement="bottom-end"
                        class="shadow-sm "
                        style="min-width: 150px">
              <i class="bi bi-three-dots-vertical action-btn ms-2" @click="takeRow(scope.row)"></i>
            </n-dropdown>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="mt-2 d-flex justify-content-end">
      <n-pagination
          class="d-pagination"
          v-model:page="pagination.page"
          v-model:page-size="pagination.pageSize"
          :page-count="pagination.totalPages"
          show-size-picker
          :page-sizes="pageSizes"
          :on-update-page="pageChange"
          :on-update-page-size="pageSizeChange"
      />
    </div>

    <el-dialog v-model="detailsDialog" title="Transaction Details" style="width: auto; max-width: 600px">
      <div v-if="transaction.type === 10">
        <div class="d-flex justify-content-between">
          <span>Deposit Proof</span>
          <span>Account Details</span>
        </div>
        <div class="row  justify-content-between  align-items-center">
          <div class="col-12 col-md-6 mt-2">

            <img class="mt-1" :src="JSON.parse(transaction?.documentJson).path" alt="" width="200">
          </div>
          <div class="col-12 col-md-6 mt-2">

            <n-table size="small" class="mt-1" :single-line="false" bordered>
              <tbody>
              <tr>
                <td class="text-xs" style="width: 50%">Bank Name</td>
                <td class="text-xs" style="width: 50%">{{ transaction?.fundingAccount?.bankName ?? '' }}</td>
              </tr>
              <tr>
                <td class="text-xs" style="width: 50%">Account Title</td>
                <td class="text-xs" style="width: 50%">{{ transaction?.fundingAccount?.accountTitle ?? '' }}</td>
              </tr>
              <tr>
                <td class="text-xs">Account Number</td>
                <td class="text-xs">
                  <div class="d-flex align-items-center">
                    <span>{{ transaction?.fundingAccount?.accountNumber ?? '' }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-xs">Branch</td>
                <td class="text-xs">{{ transaction?.fundingAccount?.branch ?? '' }}</td>
              </tr>
              <tr>
                <td class="text-xs">Currency</td>
                <td class="text-xs">{{ transaction?.fundingAccount?.currency ?? '' }}</td>
              </tr>

              </tbody>
            </n-table>
          </div>
        </div>
      </div>
      <div v-if="transaction.type === 20">
        <div class="d-flex justify-content-between">
          <span>Account Details</span>
        </div>
        <div class="">
          <div>
            <n-table size="small" style="width: 100%" class="mt-1" :single-line="false" bordered>
              <tbody>
              <tr>
                <td class="text-xs" style="width: 50%">Bank Name</td>
                <td class="text-xs" style="width: 50%">{{ transaction?.payoutAccount?.bankName ?? '' }}</td>
              </tr>
              <tr>
                <td class="text-xs" style="width: 50%">Account Title</td>
                <td class="text-xs" style="width: 50%">{{ transaction?.payoutAccount?.accountTitle ?? '' }}</td>
              </tr>
              <tr>
                <td class="text-xs">Account Number</td>
                <td class="text-xs">
                  <div class="d-flex align-items-center">
                    <span>{{ transaction?.payoutAccount?.accountNumber ?? '' }}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-xs">Branch</td>
                <td class="text-xs">{{ transaction?.payoutAccount?.branch ?? '' }}</td>
              </tr>
              <tr>
                <td class="text-xs">Currency</td>
                <td class="text-xs">{{ transaction?.payoutAccount?.currency ?? '' }}</td>
              </tr>

              </tbody>
            </n-table>
          </div>
        </div>
      </div>
      <div v-if="transaction.type === 60">
        <div class="d-flex justify-content-between">
          <span>IB Commission</span>
          <span>Account Details</span>
        </div>
        <div class="">
          <div>
            <n-table size="small" style="width: 100%" class="mt-1" :single-line="false" bordered>
              <tbody>
              <tr>
                <td class="text-xs" style="width: 50%">Account Title</td>
                <td class="text-xs" style="width: 50%">{{ transaction?.account?.accountName ?? '' }}</td>
              </tr>
              <tr>
                <td class="text-xs" style="width: 50%">Account Number</td>
                <td class="text-xs" style="width: 50%">{{ transaction?.account?.accountNumber ?? '' }}</td>
              </tr>
              <tr>
                <td class="text-xs" style="width: 50%">Amount</td>
                <td class="text-xs" style="width: 50%">{{ transaction?.amount ?? '' }}</td>
              </tr>
              <tr>
                <td class="text-xs" style="width: 50%">Date</td>
                <td class="text-xs" style="width: 50%">{{ getFormattedDate(transaction.createdAt) }}</td>
              </tr>


              </tbody>
            </n-table>
          </div>
        </div>
      </div>
      <div v-if="transaction.type === 50">
        <div>
          <div class="d-flex justify-content-between">
            <span>From Account</span>
          </div>
          <div class="">
            <div>
              <n-table size="small" style="width: 100%" class="mt-1" :single-line="false" bordered>
                <tbody>
                <tr>
                  <td class="text-xs" style="width: 50%">Account Name</td>
                  <td class="text-xs" style="width: 50%">{{ fromAccount?.accountName ?? '' }}</td>
                </tr>
                <tr>
                  <td class="text-xs" style="width: 50%">Account Number</td>
                  <td class="text-xs" style="width: 50%">{{ fromAccount?.accountNumber ?? '' }}</td>
                </tr>
                <tr>
                  <td class="text-xs">Amount</td>
                  <td class="text-xs">
                    <div class="d-flex align-items-center">
                      <span>{{ transaction?.amount ?? 0 }}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="text-xs">Date</td>
                  <td class="text-xs">
                    <div class="d-flex align-items-center">
                      <span>{{ getFormattedDate(transaction.date) }}</span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </n-table>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <div class="d-flex justify-content-between">
            <span>To Account</span>
          </div>
          <div class="">
            <div>
              <n-table size="small" style="width: 100%" class="mt-1" :single-line="false" bordered>
                <tbody>
                <tr>
                  <td class="text-xs" style="width: 50%">Account Name</td>
                  <td class="text-xs" style="width: 50%">{{ toAccount?.accountName ?? '' }}</td>
                </tr>
                <tr>
                  <td class="text-xs" style="width: 50%">Account Number</td>
                  <td class="text-xs" style="width: 50%">{{ toAccount?.accountNumber ?? '' }}</td>
                </tr>
                <tr>
                  <td class="text-xs">Amount</td>
                  <td class="text-xs">
                    <div class="d-flex align-items-center">
                      <span>{{ transaction?.amount ?? 0 }}</span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </n-table>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-1">
        <div class="d-flex justify-content-end">
          <n-button :color="colors.blackPrimary" @click="detailsDialog = false,getAllUsersAccounts(filter.userId)">
            Close
          </n-button>
        </div>
      </div>
    </el-dialog>


    <el-dialog v-model="addDialog" title="Make Transaction" width="400">
      <template #default>
        <el-form label-position="top">
          <el-form-item label="User">
            <el-select v-model="transactionModel.userId" filterable
                       @change="getAllUsersAccounts(transactionModel.userId, 'transaction')">
              <el-option :disabled="user.id < 1" v-for="user in users" :key="user.id" :value="user.id"
                         :label="user.fullName"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Accounts">
            <el-select v-model="transactionModel.tradingAccountId" filterable>
              <el-option :disabled="user.id === 0" v-for="user in usersAccounts" :key="user.id" :value="user.id"
                         :label="user.accountName"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Transaction Type">
            <el-select v-model="transactionModel.type" filterable
                       @change="transactionModel.source = transactionModel.type">
              <el-option v-for="type in types" :key="type.value" :value="type.value"
                         :label="type.label"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Amount">
            <el-input-number controls-position="right" v-model="transactionModel.amount"
                             class="w-100"></el-input-number>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="f-flex flex-end">
          <n-button tertiary @click="addDialog = false">Cancel</n-button>
          <n-button
              :disabled="transactionModel.userId < 1 || transactionModel.tradingAccountId < 1 || transactionModel.amount < 0"
              :color="colors.blackPrimary" class="ms-2" @click="makeTransaction()">Save
          </n-button>
        </div>
      </template>
    </el-dialog>
    <n-spin v-if="loading" class="mn-spin" :stroke-width="12" :size="25"
            style="z-index: 999!important;"></n-spin>
  </div>
</template>
<script>
import {NDivider, NEmpty, NPagination, NDropdown, NButton, NTable, NSpin} from "naive-ui";
import BreedCrumb from "@/components/ui/breadcrumb.vue";
import transactionService from "@/services/transactionService";
import showMessage from "@/utility/showMessage";
import moment from "moment/moment";
import colors from "@/utility/colors"
import userService from "@/services/userService";
import accountService from "@/services/accountService";
import store from "@/store";
import notificationService from "@/services/notificationService";
import callHub from "@/callHub";

export default {
  name: "TransactionsList",
  components: {NSpin, NTable, NButton, NDropdown, NPagination, NDivider, BreedCrumb, NEmpty},
  data() {
    return {
      loading: false,
      addDialog: false,
      detailsDialog: false,
      transaction: {},
      fromAccount: {},
      toAccount: {},
      transactions: [],
      transactionModel: {
        "userId": "",
        "createdAt": new Date(),
        "modifiedAt": null,
        "modifiedBy": "string",
        "id": 0,
        "tradingAccountId": "",
        "paymentMethodId": 0,
        "withdrawalAccountId": 0,
        "depositAccountId": 0,
        "date": new Date(),
        "type": 30,
        "status": 20,
        "amount": 0,
        "fee": 0,
        "user": {},
        "source": 30,
        "tradingAccount": {
          "user": {},
          "category": {},
        }
      },
      bodyStyle: {
        width: "600px"
      },
      segmented: {
        content: "soft",
        footer: "soft"
      },
      users: [{id: 0, fullName: "All"}],
      usersAccounts: [
        {id: 0, accountName: "All"}
      ],
      types: [
        {
          label: "Profit",
          value: 30
        },
        {
          label: "Loss",
          value: 40
        },
      ],
      pagination: {
        fetched: 0,
        page: 1,
        pageSize: 10,
        records: 0,
        totalPages: 0
      },
      transactionTypes: [
        {
          label: "All",
          value: 0
        },
        {
          label: "Deposit",
          value: 10
        },
        {
          label: "Withdraw",
          value: 20
        },
        {
          label: "Profit",
          value: 30
        },
        {
          label: "Loss",
          value: 40
        },
        {
          label: "Transfer",
          value: 50
        },
        {
          label: "IB Commission",
          value: 60
        },

      ],
      dateRange: [
        new Date(new Date().setMonth(new Date().getMonth() - 1)),
        new Date()
      ],
      filter: {
        search: "",
        page: 1,
        pageSize: 10,
        totalPages: 1,
        accountId: 0,
        "filterText": "",
        "filterTag": "",
        "userId": Number(sessionStorage.getItem("userId")),
        "fromDate": new Date(),
        "toDate": new Date(),
        "month": new Date(),
        "transactionType": 0,
        "transactionStatus": 0,
        "role": Number(sessionStorage.getItem("role")),
      },
      pageSizes: [
        {
          label: '10 per page',
          value: 10
        },
        {
          label: '20 per page',
          value: 20
        },
        {
          label: '30 per page',
          value: 30
        },
        {
          label: '40 per page',
          value: 40
        }
      ],
      statuses: [
        {
          label: "Pending",
          key: 10,
        },
        {
          label: "Approved",
          key: 20,
        },
        {
          label: "Rejected",
          key: 30,
        },
      ],
    }
  },
  computed: {
    role() {
      return store.state.role;
    },
    colors() {
      return colors;
    }, store() {
      return store;
    }
  },
  methods: {
    resetTransactionModel() {
      this.transactionModel.tradingAccountId = "";
      this.transactionModel.amount = 0
    },
    isBase64Image(document) {
      let fileData = document?.fileData;
      const fileName = document?.fileName;
      const fileExtension = document?.fileExt;
      const mimeType = document?.mimeType;


      if (fileData === '' || fileData === null) {
        return;
      }
      const base64Prefixes = {
        'jpeg': 'data:image/jpeg;base64,',
        'jpg': 'data:image/jpeg;base64,',
        'png': 'data:image/png;base64,',
        // Add more image types if needed
      };

      const extension = fileExtension.toLowerCase();

      // Check if the file is an image based on MIME type or file extension
      const isImage = mimeType.startsWith('image/') || ['jpeg', 'jpg', 'png'].includes(extension);

      if (isImage) {
        // Ensure the fileData starts with the correct base64 prefix
        const prefix = base64Prefixes[extension];
        if (!fileData.startsWith(prefix)) {
          fileData = prefix + fileData;
        }
        return fileData; // Return the correctly prefixed base64 image data
      } else {
        // If it's not an image, create a Blob and download the file
        const byteCharacters = atob(fileData);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: mimeType});

        // Create a link element to download the file with the given fileName
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    async takeRow(transaction, type) {
      await this.getAllUsersAccounts(transaction.userId, 'transaction')
      this.transaction = JSON.parse(JSON.stringify(transaction));
      this.fromAccount = this.usersAccounts.find(x => x.id === this.transaction.withdrawalAccountId) ?? {}
      this.toAccount = this.usersAccounts.find(x => x.id === this.transaction.depositAccountId) ?? {}
      switch (type) {
        case 'details':
          this.detailsDialog = true;
          break;
      }
      console.log(this.transaction,this.fromAccount,this.toAccount)
    },
    handleSelect(key) {
      switch (key) {
        case 10:
          this.changeStatus(this.transaction.id, 10);
          break;
        case 20:
          this.changeStatus(this.transaction.id, 20);
          break;
        case 30:
          this.changeStatus(this.transaction.id, 30);
          break;

      }
    },
    async changeStatus(id, status) {
      this.loading = true;
      try {
        let response = await transactionService.changeStatus(id, status);
        if (response.data.isSuccessful) {
          showMessage("success", response?.data?.msg ?? "Status updated");
          store.state.updateHeader = true;

          await this.getTransactions();
        } else {
          showMessage("success", response?.data?.msg ?? "Status already updated");
        }
      } catch (e) {
        showMessage("error", "Status update failed");
      }
      this.loading = false;
    },
    dateChange() {
      this.filter.fromDate = this.dateRange[0];
      this.filter.toDate = this.dateRange[1];
      this.getTransactions();
    },
    getFormattedDate(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    pageChange(page) {
      this.filter.page = page;
      this.getTransactions();
    },
    pageSizeChange(pageSize) {
      this.filter.pageSize = pageSize;
      this.getTransactions();
    },
    async getTransactions() {
      this.loading = true;
      try {
        let response = await transactionService.all(this.filter);
        this.transactions = response?.data?.data ?? [];
        this.pagination = response?.data?.pagination;
      } catch (e) {
        showMessage("error", "Something went wrong")
      }
      this.loading = false;
    },
    async getAllUsers() {
      this.loading = true;
      try {
        let response = await userService.getAllUsers(this.filter.role);
        this.users = this.users.concat(response?.data?.data ?? []);
        if (this.filter.role === 10 || this.filter.role === 20) {
          if (this.users.length > 0) {
            this.filter.userId = this.users[0].id;

          }
        }
        await this.getAllUsersAccounts(this.filter.userId)
      } catch (e) {
        console.log(e)
        showMessage("error", "Something went wrong")
      }
      this.loading = false;
    },
    async getAllUsersAccounts(userId, type) {
      this.loading = true;
      try {
        let response = await accountService.getUserAccounts(userId);
        switch (type) {
          case 'transaction':
            this.usersAccounts = [];
            break;
          default:
            this.usersAccounts = [{id: 0, accountName: "All"}];
            break;
        }
        this.usersAccounts = this.usersAccounts.concat(response?.data?.data ?? []);
        await this.getTransactions();
      } catch (e) {
        showMessage("error", "Something went wrong")
      }
      this.loading = false;
    },
    async makeTransaction() {
      this.addDialog = false;
      this.loading = true;
      try {
        let response = await transactionService.addOrUpdate(this.transactionModel);
        if (response.data.isSuccessful) {
          showMessage("success", response?.data?.msg ?? "Transaction successful")
        } else {
          showMessage("error", response?.data?.msg ?? "Something went wrong")
        }
      } catch (e) {
        showMessage("error", e?.data?.msg ?? "Something went wrong")
      }
      this.loading = true;
      this.usersAccounts = [{id: 0, accountName: "All"}];
      await this.getTransactions();
    },
    checkLogin() {
      return (sessionStorage.getItem("loggedIn") === null || sessionStorage.getItem("loggedIn") === undefined);

    },
    async markNotificationRead() {
      // eslint-disable-next-line no-unused-vars
      let response = await notificationService.markRead(this.$store.state.notificationType);
      this.$store.commit('setUpdateNotification', true);
    }
  },
  created() {
    if (!this.checkLogin) {
      this.$router.push('/');
      return;
    }
    this.getAllUsers();
    this.markNotificationRead();
  },
  async mounted() {
    await callHub.start();
    callHub.client.on("Deposit", () => {
      this.getTransactions();
    });
    callHub.client.on("Withdraw", () => {
      this.getTransactions();
    });
    callHub.client.on("CommissionWithdraw", () => {
      this.getTransactions();
    });
    callHub.client.on("Transfer", () => {
      this.getTransactions();
    });
  }
}
</script>

<style scoped>

</style>