import axios from "axios";
import axiosConfig from "@/config/axiosConfig";

export default {
    getAllUrl: axiosConfig.apiBaseUrl + "FundingAccount/GetAll?type=",
    allUrl: axiosConfig.apiBaseUrl + "FundingAccount/All",
    addOrUpdateUrl: axiosConfig.apiBaseUrl + "FundingAccount/AddOrUpdate",
    removeUrl: axiosConfig.apiBaseUrl + "FundingAccount/Remove?accountId=",

    all(filter) {
        return axios.post(this.allUrl, filter)
    },
    getAll(type) {
        return axios.get(this.getAllUrl + type);
    },
    addOrUpdate(account) {
        return axios.post(this.addOrUpdateUrl, account)
    },
    delete(accountId){
        return axios.get(this.removeUrl+accountId);
    }


}