import axiosConfig from "@/config/axiosConfig";
import axios from "axios"

export default {
    getUrl: axiosConfig.apiBaseUrl + "PayoutAccount/Get",
    allUrl: axiosConfig.apiBaseUrl + "PayoutAccount/All",
    getAllUrl: axiosConfig.apiBaseUrl + "PayoutAccount/GetAll?userId=",
    removeUrl: axiosConfig.apiBaseUrl + "PayoutAccount/Remove",
    getPayoutAccountsUrl: axiosConfig.apiBaseUrl + "PayoutAccount/GetPayoutAccounts?userId=",
    get(paymentMethodId) {
        const fd = new FormData();
        fd.append("paymentMethodId", paymentMethodId)
        return axios({
            method: "post",
            url: this.getUrl,
            data: fd,
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
            }
        });
    },

    all(filter) {
        return axios.post(this.allUrl, filter)
    },
    getAll(userId) {
        return axios.get(this.getAllUrl+userId);
    },
    getPayoutAccounts(userId,type){
        return axios.get(this.getPayoutAccountsUrl+userId+"&type="+type);
    },
    remove(accountId) {
        const fd = new FormData();
        fd.append("accountId", accountId)
        return axios({
            method: "post",
            url: this.removeUrl,
            data: fd,
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
            }
        });
    },

}