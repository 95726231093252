import {createStore} from 'vuex'

export default createStore({
    state: {
        loggedIn: false,
        sidebarCollapsed: false,
        showSidebar: true,
        profileCompleted: false,
        categoryId: 0,
        role: 30,
        depositAccount: {},
        depositAccountId: 0,
        withdrawAccountId: 0,
        previousRoute: "",
        updateHeader: false,
        fundingAccountType: 10,
        payoutAccountType: 10,
        notificationType : 0,
        updateNotification: false,
    },
    getters: {},
    mutations: {
        setStatusUpdated(state, value) {
            state.updateHeader = value;
        },
        setUpdateNotification(state, value) {
            state.updateNotification = value;
        },
    },
    actions: {
        updateStatus({commit}) {
            commit('setStatusUpdated', true);
        },
        updateNotification({ commit }, value) {
            commit('setUpdateNotification', value);
        },
    },
    modules: {}
})
