<template>
  <div>
    <div class="row align-items-center justify-between">
      <div class="col-12 col-lg-4">
        <BreedCrumb/>
      </div>
      <div class="col-12 col-md-6">
        <div class="d-flex flex-md-row flex-column  justify-content-end">
          <el-tooltip content="Add Config">
            <n-button type="primary" class="ms-2" @click="addDialog = true; resetConfig()">
              <i class="bi bi-plus-lg"></i>
            </n-button>
          </el-tooltip>
        </div>
      </div>
    </div>
    <n-divider class="my-1"></n-divider>
    <el-table border :data="configs" header-cell-class-name="tb-header" :show-overflow-tooltip="true">
      <template #empty>
        <n-empty class="pt-4" description="No Data">
        </n-empty>
      </template>
      <el-table-column min-width="100" label="Key" prop="key"></el-table-column>
      <el-table-column min-width="100" label="Value" prop="value"></el-table-column>
      <el-table-column min-width="120" label="Title" prop="title"></el-table-column>
      <el-table-column min-width="100" label="Description" prop="description"></el-table-column>
      <el-table-column fixed="right" label="Action" width="100">
        <template #default="scope">
          <div class="text-center">
            <el-tooltip content="Edit">
              <i class="mgc_edit_line text-lg action-btn text-primary" @click="takeRow(scope.row, 'edit')"></i>
            </el-tooltip>
            <el-tooltip content="Delete">
              <i class="mgc_delete_2_line ms-2 text-lg action-btn text-danger" v-if="scope.row.key !== 'USD'"
                 @click="takeRow(scope.row, 'delete')"></i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="mt-2 d-flex justify-content-end">
      <n-pagination
          class="d-pagination"
          v-model:page="pagination.page"
          v-model:page-size="pagination.pageSize"
          :page-count="pagination.totalPages"
          show-size-picker
          :page-sizes="pageSizes"
          :on-update-page="pageChange"
          :on-update-page-size="pageSizeChange"
      />
    </div>


    <el-dialog v-model="addDialog" title="Add Config" style="width: auto; max-width: 500px;">
      <template #default>
        <el-form label-position="top">
          <el-form-item label="Key">
            <el-input :disabled="config.id > 0" v-model="config.key"></el-input>
          </el-form-item>
          <el-form-item label="Value">
            <el-input v-model="config.value"></el-input>
          </el-form-item>
          <el-form-item label="Title">
            <el-input v-model="config.title"></el-input>
          </el-form-item>
          <el-form-item label="Description">
            <el-input :rows="2"
                      type="textarea" v-model="config.description"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <n-button tertiary @click="addDialog = false">Cancel</n-button>
          <n-button type="primary" class="ms-2" :disabled="!config.key || !config.value" @click="addOrUpdate()">
            {{ config.id < 1 ? 'Add' : 'Update' }}
          </n-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="deleteDialog" title="Delete Key" style="width: auto; max-width: 400px">
      <template #default>
        <span>Are you sure? you want to delete <span
            class="font-semibold text-danger">{{ config.key }} key.</span></span>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <n-button tertiary @click="deleteDialog = false">Cancel</n-button>
          <n-button type="error" @click="deleteConfig()" class="ms-2">Delete</n-button>
        </div>
      </template>
    </el-dialog>


    <n-spin v-if="loading" class="mn-spin" :stroke-width="12" :size="25"
            style="z-index: 999!important;"></n-spin>
  </div>
</template>
<script>
import {NButton, NDivider, NEmpty, NPagination, NSpin} from "naive-ui";
import BreedCrumb from "@/components/ui/breadcrumb.vue";
import colors from "@/utility/colors";
import store from "@/store";
import settingsService from "@/services/settingsService";
import showMessage from "@/utility/showMessage";

export default {
  name: "AppSettings",
  components: {NSpin, NButton, NPagination, NEmpty, NDivider, BreedCrumb,},
  data() {
    return {
      loading: false,
      addDialog: false,
      deleteDialog: false,
      config: {
        id: 0,
        key: "",
        value: "",
        title: "",
        description: ""
      },
      configs: [],
      filter: {
        search: "",
        page: 1,
        pageSize: 10,
        totalPages: 1,
        accountId: 0,
        "filterText": "",
        "filterTag": "",
        "userId": Number(sessionStorage.getItem("userId")),

      },
      pagination: {
        fetched: 0,
        page: 1,
        pageSize: 10,
        records: 0,
        totalPages: 0
      },
      pageSizes: [
        {
          label: '10 per page',
          value: 10
        },
        {
          label: '20 per page',
          value: 20
        },
        {
          label: '30 per page',
          value: 30
        },
        {
          label: '40 per page',
          value: 40
        }
      ]
    }
  },
  methods: {
    takeRow(config, type) {
      this.config = JSON.parse(JSON.stringify(config));
      switch (type) {
        case 'edit':
          this.addDialog = true;
          break;
        case 'delete':
          this.deleteDialog = true;
          break;
      }
    },
    pageChange(page) {
      this.filter.page = page;
      this.getDeposits();
    },
    pageSizeChange(pageSize) {
      this.filter.pageSize = pageSize;
      this.getDeposits();
    },
    async addOrUpdate() {
      this.loading = true;
      this.addDialog = false;
      try {
        let response = await settingsService.addOrUpdate(this.config);
        if (response?.data.isSuccessful) {
          showMessage("success", "Config Added");
          await this.getConfigs();
        }
      } catch (e) {
        showMessage("error", e?.response?.msg ?? "Configuration adding failed");
      }
      this.loading = false;
    },
    async getConfigs() {
      this.loading = true;
      try {
        let response = await settingsService.all(this.filter);

        this.configs = response?.data?.data ?? []
      } catch (e) {
        showMessage("error", e?.response?.msg ?? "Fetching data failed");
      }
      this.loading = false;
    },
    async deleteConfig() {
      this.deleteDialog = false
      this.loading = true;
      try {
        // eslint-disable-next-line no-unused-vars
        let response = await settingsService.delete(this.config.id);

      } catch (e) {
        showMessage("error", e?.response?.msg ?? "Fetching data failed");
      }
      this.loading = false;
    },
    resetConfig() {
      this.config = {
        id: 0,
        key: "",
        value: "",
        title: "",
        description: ""
      }
    },
  },
  created() {
    this.getConfigs();
  },
  computed: {
    colors() {
      return colors;
    },
    role() {
      return Number(sessionStorage.getItem("role"));
    },
    store() {
      return store;
    }
  },
}
</script>

<style scoped>

</style>