import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/themes/elementplus.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from "axios";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}



axios.defaults.headers.common["Authorization"] = "Bearer " + sessionStorage.getItem("token");
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json; charset=utf-8";
store.state.profileCompleted = Boolean(sessionStorage.getItem("profileCompleted"));
store.state.loggedIn = Boolean(sessionStorage.getItem("loggedIn"));
store.state.role = Number(sessionStorage.getItem("role"));

createApp(App).use(store).use(ElementPlus).use(router).mount('#app')
