<template>
  <div>
    <div class="row align-items-center justify-between">
      <div class="col-12 col-lg-4">
        <BreedCrumb/>
      </div>
      <div class="col-12 col-lg-6">
        <div class="d-flex flex-md-row flex-column">

          <n-select class="w-100" v-model:value="filter.tradingAccountType" :options="types"
                    label-field="text" value-field="value" @update:value="getUsers()"></n-select>
          <n-input-group class="ms-md-2 mt-2 mt-md-0">
            <n-input v-model:value="filter.filterText" @input="getAll()"
                     placeholder="Search through username or account id"></n-input>
            <n-button type="primary" class="ms-2">
              <i class="mgc_search_line"></i>
            </n-button>
          </n-input-group>
        </div>
      </div>
    </div>
    <n-divider class="my-1"></n-divider>
    <el-table border :data="accounts" header-cell-class-name="tb-header" show-overflow-tooltip>
      <template #empty>
        <n-empty class="pt-4" description="No Data">
        </n-empty>
      </template>
      <el-table-column  label="Id" prop="id" width="70"></el-table-column>
      <el-table-column min-width="100" label="User" prop="user.fullName"></el-table-column>
      <el-table-column min-width="130" label="Account Title" prop="accountName"></el-table-column>
      <el-table-column min-width="120" label="Account #" prop="accountNumber"></el-table-column>
      <el-table-column min-width="100" label="Type" prop="accountType"></el-table-column>
      <el-table-column min-width="120" label=Password prop="accountPassword"></el-table-column>
      <el-table-column label="Status" prop="accountStatus" width="120"></el-table-column>
      <el-table-column label="Amount" prop="amount" width="120">
        <template #default="scope">
          <div class="text-end">
            <span>{{ scope.row.amount }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Action" width="100" fixed="right">
        <template #default="scope">
          <div class="text-center">
            <n-dropdown trigger="click" :options="options" @select="handleSelect" placement="bottom-end"
                        class="shadow-sm"
                        style="min-width: 150px">
              <i class="bi bi-three-dots-vertical action-btn" @click="takeRow(scope.row)"></i>
            </n-dropdown>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="mt-2 d-flex justify-content-end">
      <n-pagination
          class="d-pagination"
          v-model:page="pagination.page"
          v-model:page-size="pagination.pageSize"
          :page-count="pagination.totalPages"
          show-size-picker
          :page-sizes="pageSizes"
          :on-update-page="pageChange"
          :on-update-page-size="pageSizeChange"
      />
    </div>

    <el-dialog v-model="changePasswordDialog" title="Change Password" width="500">
      <template #default>
        <el-form label-position="top">
          <el-form-item label="New Password">
            <el-input v-model="account.accountPassword"></el-input>
          </el-form-item>
        </el-form>
        <div class="d-flex justify-content-end">
          <n-button tertiary @click="changePasswordDialog = false">Cancel</n-button>
          <n-button :color="colors.blackPrimary" class="ms-2" @click="updatePassword(), changePasswordDialog = false">
            Update
          </n-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="accountTitleDialog" title="Change Account Title" width="500">
      <template #default>
        <el-form label-position="top">
          <el-form-item label="New Title">
            <el-input v-model="account.accountName"></el-input>
          </el-form-item>
        </el-form>
        <div class="d-flex justify-content-end">
          <n-button tertiary @click="accountTitleDialog = false">Cancel</n-button>
          <n-button :color="colors.blackPrimary" class="ms-2" @click="updateTitle(), accountTitleDialog = false">
            Update
          </n-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="deleteDialog" width="500" title="Delete Account">
      <template #default>
        <span class="">Are you sure? you want to delete <span class="font-bold">{{ account.accountName }}</span>.</span>
      </template>
      <template #footer>
        <div class="d-flex justify-end">
          <n-button tertiary @click="deleteDialog = false" class="ms-2">Cancel</n-button>
          <n-button type="error" class="ms-2" @click="deleteAccount()">Delete</n-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="changeMarginDialog" title="Change Account Margin" width="500">
      <template #default>
        <el-form label-position="top">
          <el-form-item label="Select Margin">
            <el-select v-model="account.margin">
              <el-option v-for="item in marginOptions" :key="item.value" :label="item.text"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="d-flex justify-content-end">
          <n-button tertiary @click="changeMarginDialog = false">Cancel</n-button>
          <n-button :color="colors.blackPrimary" class="ms-2" @click="changeMargin(), changeMarginDialog = false">
            Update
          </n-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog v-model="approveDialog" title="Update Account" :width="500">
      <template #default>
        <el-form label-position="top">
          <el-form-item label="Account Name">
            <el-input disabled v-model="account.accountName"></el-input>
          </el-form-item>
          <el-form-item label="Account Number">
            <el-input v-model="account.accountNumber"></el-input>

          </el-form-item>
          <el-form-item label="Account Password">
            <el-input v-model="account.accountPassword"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="d-flex justify-content-end">
          <n-button tertiary @click="approveDialog = false">Cancel</n-button>
          <n-button class="ms-2" :color="colors.blackPrimary" @click="approveAccount()"
                    :disabled="!account.accountNumber || !account.accountPassword">Approve
          </n-button>
        </div>
      </template>

    </el-dialog>

    <n-spin v-if="loading" class="mn-spin"  :stroke-width="12" :size="25"
            style="z-index: 999!important;"></n-spin>
  </div>
</template>
<script>
import {
  NButton,
  NDivider, NDropdown, NEmpty, NInput, NInputGroup, NPagination, NSelect, NSpin,
  useMessage
} from "naive-ui";
import colors from "@/utility/colors"
import showMessage from "@/utility/showMessage"
import accountService from "@/services/accountService";
import BreedCrumb from "@/components/ui/breadcrumb.vue";
import callHub from "@/callHub"
import notificationService from "@/services/notificationService";

export default {
  name: "UsersAccounts",
  components: {
    NSpin,
    NPagination,
    NSelect,
    NDropdown,
    NEmpty,
    NInputGroup, NInput, NButton, NDivider,
    BreedCrumb,
  },
  data() {
    return {
      loading: false,
      renameDialog: false,
      infoDialog: false,
      changeMarginDialog: false,
      accountDialog: false,
      changePasswordDialog: false,
      approveDialog: false,
      accountTitleDialog: false,
      accountDescriptionDialog: false,
      deleteDialog: false,
      activeTab: 100,
      msg: [],
      specialCh: false,
      hasCapitalChar: false,
      hasNumber: false,
      useMessage: useMessage(),
      amountMessage: "",
      account: {},
      accounts: [],
      renameModel: {
        accountId: 0,
        userId: Number(sessionStorage.getItem("userId")),
        accountName: "",
      },
      transferModel: {
        fromAccount: "",
        toAccount: "",
        transferAmount: 0,
        userId: Number(sessionStorage.getItem("userId"))
      },
      marginOptions: [
        {text: "1:100", value: "1:100"},
        {text: "1:500", value: "1:500"},
        {text: "1:1000", value: "1:1000"},
        {text: "1:2000", value: "1:2000"},
      ],
      accountOptions: [
        {text: "Newest", value: 100},
        {text: "Oldest", value: "oldest"},
        {text: "Free Margin", value: "freeMargin"},
        {text: "Nick Name", value: "nickName"},
      ],
      options: [
        {
          label: "Approve",
          key: 10,
        },
        {
          label: 'Change Password',
          key: 20,
        },
        {
          label: "Account Title",
          key: 30,
        },
        {
          label: "Change Margin",
          key: 40,
        },
        {
          label: "Delete",
          key: 50,
        },
        // {
        //   label: "Account Description",
        //   key: 40,
        // },
      ],
      pagination: {
        fetched: 0,
        page: 1,
        pageSize: 10,
        records: 0,
        totalPages: 0
      },
      types: [
        {text: "All", value: 0},
        {text: "Real", value: 100},
        {text: "Demo", value: 200},
        {text: "IB Account", value: 300},
      ],
      filter: {
        "pageSize": 10,
        "page": 1,
        "filterText": "",
        "filterTag": "",
        "userId": Number(sessionStorage.getItem("userId")) ?? 0,
        "tradingAccountType": 0
      },
      pageSizes: [
        {
          label: '10 per page',
          value: 10
        },
        {
          label: '20 per page',
          value: 20
        },
        {
          label: '30 per page',
          value: 30
        },
        {
          label: '40 per page',
          value: 40
        }
      ],

    }
  },
  methods: {
    pageChange(page) {
      this.filter.page = page;
      this.getAll();
    },
    pageSizeChange(pageSize) {
      this.filter.pageSize = pageSize;
      this.getAll();
    },
    goToDeposit(accountId) {
      this.$store.state.depositAccountId = accountId;
      this.$router.push('/deposit');
    },
    goToWithdraw(accountId) {

      this.$store.state.withdrawAccountId = accountId;
      this.$router.push('/withdraw');
    },

    takeRow(account) {
      this.account = {...account};
    },
    getAmount(amount) {
      return amount.toFixed(2);
    },
    checkFund(amount) {
      let fromAccount = this.accounts.find(x => x.id === this.transferModel.fromAccount);
      if (fromAccount === undefined || fromAccount === null) {
        showMessage("error", "Fetching account data failed");
        return;
      }
      if (amount > fromAccount.amount) {

        this.amountMessage = "Not enough money. Your balance is " + fromAccount.amount + " " + fromAccount.currency;
      } else {
        this.amountMessage = "";
      }
    },
    disableTransfer(amount) {
      let fromAccount = this.accounts.find(x => x.id === this.transferModel.fromAccount);
      if (fromAccount === undefined || fromAccount === null) {
        showMessage("error", "Fetching account data failed");
        return true;
      }
      return amount >= fromAccount.amount;
    },
    handleSelect(key) {
      switch (key) {
        case 10:
          this.approveDialog = true;
          break;
        case 20:
          this.changePasswordDialog = true;
          this.account.accountPassword = "";
          this.testPassword(this.account.accountPassword);
          break;
        case 30:
          this.account.accountName = "";
          this.accountTitleDialog = true;
          break;
        case 40:
          this.changeMarginDialog = true;
          break;
        case 50:
          this.deleteDialog = true;
          break;
      }
    },
    testPassword(inputString) {
      // Define a regular expression to match any character that is not alphanumeric or whitespace
      const regex = /[^a-zA-Z0-9\s]/;
      const capitalCharRegex = /[A-Z]/;
      const numberRegex = /[0-9]/;
      // Test the input string against the regular expression
      this.specialCh = regex.test(inputString);
      this.hasCapitalChar = capitalCharRegex.test(inputString);
      this.hasNumber = numberRegex.test(inputString);
    },
    copyText(text) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
          this.useMessage.success("Copied to clipboard", {duration: 1000});
          // eslint-disable-next-line no-unused-vars
        }).catch(err => {
          this.useMessage.success("Copy failed", {duration: 1000});
        });
      }
    },
    getUsers(){
      this.filter.page = 1;
      this.getAll();
    },
    async getAll() {

      this.loading = true;
      try {
        let response = await accountService.getUsersAccount(this.filter);
        this.accounts = response?.data?.data ?? [];
        this.pagination = response?.data?.pagination;
      } catch (e) {
        showMessage("error", "Something went wrong")
      }
      this.loading = false;
    },
    async transferFunds() {
      this.transferDialog = false;
      this.loading = true;
      try {
        let response = await accountService.transfer(this.transferModel);
        if (response?.data?.isSuccessful) {
          showMessage("success", "Transfer funds request generated");
          await this.getAll();
        }
      } catch (e) {
        showMessage("error", e?.data?.message ?? "Transfer funds failed")
      }
      this.loading = false;
    },
    async approveAccount() {
      this.approveDialog = false;
      this.loading = true;
      try {
        let response = await accountService.approve(this.account);
        if (response?.data?.isSuccessful) {
          showMessage("success", response?.data?.msg ?? "Account approved");
          await this.getAll();
        }
      } catch (e) {
        showMessage("error", e?.data?.msg ?? "Account approval failed");
      }
    },
    async deleteAccount() {
      this.deleteDialog = false;
      this.loading = true;
      try {
        let response = await accountService.remove(this.account.id);
        if (response?.data) {
          showMessage("success", "Account deleted");
          await this.getAll();
        }
      } catch (e) {
        showMessage("error", "Account deletetion failed")
      }
      this.loading = false;
    },
    async renameAccount() {
      this.renameDialog = false;
      this.loading = true;
      try {
        let response = await accountService.rename(this.renameModel);
        if (response?.data?.isSuccessful) {
          showMessage("success", response?.data?.msg ?? "Account rename request generated");
          await this.getAll();
        }
      } catch (e) {
        showMessage("error", "Account rename failed")
      }
      this.loading = false;
    },
    async changeMargin() {
      this.changeMarginDialog = false;
      this.loading = true;
      try {
        let response = await accountService.addOrUpdate(this.account);
        if (response?.data?.isSuccessful) {
          showMessage("success", "Account margin changed");
          await this.getAll();
        }
      } catch (e) {
        showMessage("error", "Margin changed failed")
      }
      this.loading = false;
    },
    async changePassword() {
      this.changePasswordDialog = false;
      this.loading = true;
      try {
        let response = await accountService.changePasswords(this.account.id, this.account.accountPassword);
        if (response.data) {
          showMessage("success", "Password changed request generated");

        }
      } catch (e) {
        showMessage("error", "Password update failed");
      }
      this.loading = false;
    },
    async updatePassword() {
      this.loading = true;
      try {
        let response = await accountService.addOrUpdate(this.account);
        if (response?.data?.isSuccessful) {
          showMessage("success", "Password Changed");
          this.getAll();
        }
      } catch (e) {
        showMessage("error", "Something went wrong!");
      }
      this.loading = false;
    },
    async updateTitle() {
      this.loading = true;
      try {
        let response = await accountService.addOrUpdate(this.account);
        if (response?.data?.isSuccessful) {
          showMessage("success", "Title Changed");
          this.getAll();
        }
      } catch (e) {
        showMessage("error", "Something went wrong!");
      }
      this.loading = false;
    },
    checkLogin() {
      return (sessionStorage.getItem("loggedIn") === null || sessionStorage.getItem("loggedIn") === undefined || sessionStorage.getItem("loggedIn") === false);
    },
    async markNotificationRead() {
      // eslint-disable-next-line no-unused-vars
      let response = await notificationService.markRead(this.$store.state.notificationType);
      this.$store.commit('setUpdateNotification', true);
    }
  },
  async mounted() {
    await callHub.start();
    callHub.client.on("AccountCreated", () => {
      this.getAll();
    });
    callHub.client.on("Removed", () => {
      this.getAll();
    });
  },
  created() {
    if (!this.checkLogin) {
      this.$router.push('/');
      return;
    }
    this.getAll();
  this.markNotificationRead();
  },
  computed: {
    colors() {
      return colors;
    }
  }
}
</script>

<style scoped>
.pswd-div {
  height: 18px !important;
}
</style>