import {HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import callHubConfig from "@/config/callHubConfig";


class CallHub {
    constructor() {
        this.client = new HubConnectionBuilder()
            .withUrl(callHubConfig.url)
            .configureLogging(LogLevel.Information)
            .build();

    }

    async start() {
        try {
            await this.client.start();
        } catch (err) {

            setTimeout(() => this.start(), 5000); // Retry connection after 5 seconds
        }
    }
}

export default new CallHub();
