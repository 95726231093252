<template>
  <div>
    <div class="d-flex">
      <div class="d-flex align-items-center">
        <router-link to="new-account">
          <n-button quaternary class="me-2">
            <i class="bi bi-arrow-left text-xl"></i>
          </n-button>
        </router-link>
        <span class="h3">Open New Account</span>
      </div>
    </div>
    <div class="mt-3">
      <div class="container-fluid">
        <div class="row justify-content-around">
          <div class="col-12 col-md-6">
            <n-tabs type="segment" animated @update:value="handleTabChange">
              <n-tab-pane :name="100" tab="Real">
                <!--                <span class="text-xs">Trade with real money and withdraw any profit you may make</span>-->
                <el-form label-position="top" require-asterisk-position="right" class="mt-2">
                  <el-form-item required>
                    <template #label>
                      Max Leverage
                    </template>
                    <template #default>
                      <el-select v-model="account.margin">
                        <el-option v-for="item in marginOptions" :key="item.value" :label="item.text"
                                   :value="item.value">
                        </el-option>
                      </el-select>
                    </template>
                  </el-form-item>
                  <el-form-item>
                    <template #label>
                      Currency
                    </template>
                    <template #default>
                      <el-select v-model="account.currency">
                        <el-option v-for="item in currencies" :key="item.value" :label="item.label"
                                   :value="item.value">
                        </el-option>
                      </el-select>
                    </template>
                  </el-form-item>
                  <el-form-item required>
                    <template #label>
                      Account Nickname
                    </template>
                    <template #default>
                      <el-input type="text" v-model="account.accountName"></el-input>
                    </template>
                  </el-form-item>
                  <el-form-item required>
                    <template #label>
                      Account Password
                    </template>
                    <template #default>
                      <el-input type="text" v-model="account.accountPassword"></el-input>
                    </template>
                  </el-form-item>
                  <!--                <el-form-item required>-->
                  <!--                  <template #label>-->
                  <!--                    Account Password-->
                  <!--                  </template>-->
                  <!--                  <template #default>-->
                  <!--                    <el-input v-model="account.password" type="password" show-password></el-input>-->
                  <!--                  </template>-->
                  <!--                </el-form-item>-->
                  <n-button :disabled="!account.accountName || !account.accountPassword" :color="colors.logoPrimary"
                            block size="large" @click="createAccount()">
                    Create an Account
                  </n-button>
                </el-form>
              </n-tab-pane>
              <n-tab-pane :name="200" tab="Demo">
                <span class="text-xs">Risk-free account. Trade with virtual money</span>
                <el-form label-position="top" require-asterisk-position="right" class="mt-2">
                  <el-form-item required>
                    <template #label>
                      Max Leverage
                    </template>
                    <template #default>
                      <el-select v-model="account.margin">
                        <el-option v-for="item in marginOptions" :key="item.value" :label="item.text"
                                   :value="item.value">
                        </el-option>
                      </el-select>
                    </template>
                  </el-form-item>
                  <el-form-item>
                    <template #label>
                      Currency
                    </template>
                    <template #default>
                      <el-select v-model="account.currency">
                        <el-option v-for="item in currencies" :key="item.value" :label="item.label"
                                   :value="item.value">
                        </el-option>
                      </el-select>
                    </template>
                  </el-form-item>
                  <el-form-item required>
                    <template #label>
                      Account Nickname
                    </template>
                    <template #default>
                      <el-input type="text" v-model="account.accountName"></el-input>
                    </template>
                  </el-form-item>
                  <el-form-item required>
                    <template #label>
                      Account Password
                    </template>
                    <template #default>
                      <el-input type="text" v-model="account.accountPassword"></el-input>
                    </template>
                  </el-form-item>
                  <!--                <el-form-item required>-->
                  <!--                  <template #label>-->
                  <!--                    Account Password-->
                  <!--                  </template>-->
                  <!--                  <template #default>-->
                  <!--                    <el-input v-model="account.password" type="password" show-password></el-input>-->
                  <!--                  </template>-->
                  <!--                </el-form-item>-->
                  <n-button :color="colors.logoPrimary" block size="large" @click="createAccount()">
                    Create an Account
                  </n-button>
                </el-form>
              </n-tab-pane>
            </n-tabs>
          </div>
          <div class="col-12 col-md-4 border-l" style="height: 100%;">
            <n-list>
              <n-list-item>
                <span class="text-xl"> {{ category?.title }}</span>
              </n-list-item>
              <n-list-item>
                <div class="d-flex justify-content-between align-items-center">
                  <span class=""> Spread from</span>
                  <span class=""> {{ category?.spread }}</span>
                </div>
              </n-list-item>
              <!--              <n-list-item>-->
              <!--                <div class="d-flex justify-content-between align-items-center">-->
              <!--                  <span v-if="category?.commission === 0">No Commission</span>-->
              <!--                  <span v-else> {{ category?.comission }}</span>-->
              <!--                </div>-->
              <!--              </n-list-item>-->
            </n-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {NButton, NList, NListItem, NTabPane, NTabs} from "naive-ui";
import colors from "@/utility/colors"
import currencies from "@/utility/currencies"
import accountService from "@/services/accountService";
import showMessage from '@/utility/showMessage'

export default {
  name: "CreateAccount",
  components: {NListItem, NList, NTabPane, NTabs, NButton},
  data() {
    return {
      loading: true,
      category: {},

      account: {
        "createdDate": new Date(),
        "accountId": 0,
        "userId": Number(sessionStorage.getItem("userId")) ?? 0,
        "accountNumber": "",
        "accountName": "",
        "accountPassword": "",
        "currency": "USD",
        "amount": 0,
        "accountType": 100,
        "platform": "Vertex",
        "accountStatus": 10,
        "id": 0,
        "user": {},
        "tradingCategoryId": 0,
        "category": {},
        "type": 100,
        "status": 10,
        "margin": "1:100",
      },

      marginOptions: [
        {text: "1:100", value: "1:100"},
        {text: "1:500", value: "1:500"},
        {text: "1:1000", value: "1:1000"},
        {text: "1:2000", value: "1:2000"},
      ]
    }
  },
  methods: {
    handleTabChange(value) {
      this.account.type = value;
    },
    async createAccount() {
      this.loading = true;
      try {
        this.account.tradingCategoryId = this.category.id;

        let response = await accountService.addOrUpdate(this.account);
        if (response?.data.isSuccessful) {
          showMessage("success", "Account created, wait for approval");
          this.$router.push('/accounts')
        }
      } catch (e) {
        showMessage("error", "Account creating failed")
      }
    },
    async getCategory(categoryId) {
      this.loading = true;
      try {
        let response = await accountService.getCategory(categoryId);
        this.category = response?.data?.data ?? {};
      } catch (e) {
        showMessage("error", e?.data?.message ?? 'Something went wrong')
      }
    },
    checkLogin() {
      return (sessionStorage.getItem("loggedIn") === null || sessionStorage.getItem("loggedIn") === undefined || sessionStorage.getItem("loggedIn") === false);
    }
  },
  created() {
    if (!this.checkLogin) {
      this.$router.push('/');
      return;
    }
    if (this.$store.state.categoryId > 0) {
      this.getCategory(this.$store.state.categoryId)
    }
  },
  computed: {
    colors() {
      return colors;
    },
    currencies() {
      return currencies;
    },

  }
}
</script>

<style scoped>

</style>