import {createRouter, createWebHistory} from "vue-router";
import store from '../store/index'
import LoginPage from "@/components/auth/login.vue";
import Dashboard from "@/components/dashboard/dashboard.vue";
import Users from "@/components/users/users.vue";
import Transactions from "@/components/transactions/transactions.vue"
import UserAccount from "@/components/user/accounts/accounts.vue"
import Deposits from "@/components/deposit/depositsList.vue"
import WithdrawalsList from "@/components/withdraw/withdrawalsList.vue"
import WithdrawAmount from "@/components/withdraw/withdraw.vue"
import NewAccount from "@/components/user/accounts/newaccount.vue"
import CreateAccount from "@/components/user/accounts/createAccount.vue"
import Deposit from "@/components/deposit/deposit.vue"
import VerificationScreen from "@/components/auth/verification.vue"
import VerifyDeposit from "@/components/deposit/verifyDeposit.vue"
import Profile from "@/components/user/profile.vue"
import Tickets from "@/components/support/supportTickets.vue"
import UserAccounts from "@/components/accounts/userAccounts.vue"
import NotFound from "@/components/notFound/NoFound.vue"
import UserReferrals from "@/components/user/UserReferrals.vue"
import IbDashboard from "@/components/ib/ibDashboard.vue"
import depositScreen from "@/components/deposit/fundingAccountsTypes.vue"
import withdrawScreen from "@/components/withdraw/payoutAccountTypes.vue"
import Settings from "@/components/settings/settings.vue"
import Forbidden from "@/components/notFound/forbidden.vue"
import FundingAccounts from "@/components/fundingAccounts/fundingAccounts.vue"
const routes = [
    {
        path: "/404",
        name: "NotFound",
        component: NotFound,
    }, {
        path: "/403",
        name: "Forbidden",
        component: Forbidden,
    },
    {
        path: "/",
        name: "LoginPage",
        component: LoginPage,
    },
    {
        path: "/funding-accounts",
        name: "FundingAccounts",
        component: FundingAccounts,
        beforeEnter: (to, from, next) => {
            if (store.state.loggedIn) {
                if (Number(store.state.role) === 30) {
                    next({path: '/403'});
                } else {
                    next()
                }
            } else {
                next({path: '/'});
            }
        },
    },
    {
        path: "/ib",
        name: "IbDashboard",
        component: IbDashboard,
        beforeEnter: (to, from, next) => {
            if (store.state.loggedIn) {
                next();
            } else {
                next({path: '/'});
            }
        },
    },
    {
        path: "/partners",
        name: "UserReferrals",
        component: UserReferrals,
        meta: {
            pageTitle: 'Partners',
            breadcrumbs: ['Partners'],
        },
        beforeEnter: (to, from, next) => {
            if (store.state.loggedIn) {
                next();
            } else {
                next({path: '/'});
            }
        },
    },
    {
        path: "/deposit-methods",
        name: "Deposit Methods",
        component: depositScreen,
        meta: {
            pageTitle: 'Deposit Methods',
            breadcrumbs: ['Deposit Methods'],
        },
        beforeEnter: (to, from, next) => {
            if (store.state.loggedIn) {
                next();
            } else {
                next({path: '/'});
            }
        },
    }, {
        path: "/payout-methods",
        name: "Payout Methods",
        component: withdrawScreen,
        meta: {
            pageTitle: 'Payout Methods',
            breadcrumbs: ['Payout Methods'],
        },
        beforeEnter: (to, from, next) => {
            if (store.state.loggedIn) {
                next();
            } else {
                next({path: '/'});
            }
        },
    },
    {
        path: "/verify",
        name: "VerificationScreen",
        component: VerificationScreen,
        beforeEnter: (to, from, next) => {
            if (store.state.loggedIn) {
                next();
            } else {
                next({path: '/'});
            }
        },
    },

    {
        path: "/home",
        name: "Dashboard",
        component: Dashboard,
        meta: {
            pageTitle: 'Dashboard',
            breadcrumbs: ['Dashboard'],
        },
        beforeEnter: (to, from, next) => {
            if (store.state.loggedIn) {
                if (Number(store.state.role) === 30) {
                    next({path: '/403'});
                } else {
                    next()
                }
            } else {
                next({path: '/'});
            }
        },
    },
    {
        path: "/users",
        name: "Users",
        component: Users,
        meta: {
            pageTitle: 'Users',
            breadcrumbs: ['Users'],
        },
        beforeEnter: (to, from, next) => {
            if (store.state.loggedIn) {
                if (Number(store.state.role) === 30) {
                    next({path: '/403'});
                } else {
                    next()
                }
            } else {
                next({path: '/'});
            }
        },
    }, {
        path: "/profile",
        name: "Profile",
        component: Profile,
        meta: {
            pageTitle: 'Profile',
            breadcrumbs: ['Profile'],
        },
        beforeEnter: (to, from, next) => {
            if (store.state.loggedIn) {
                next();
            } else {
                next({path: '/'});
            }
        },
    },
    {
        path: "/transactions",
        name: "Transactions",
        component: Transactions,
        meta: {
            pageTitle: 'Transactions',
            breadcrumbs: ['Transactions'],
        },
        beforeEnter: (to, from, next) => {
            if (store.state.loggedIn) {
                next();
            } else {
                next({path: '/'});
            }
        },
    },
    {
        path: "/accounts",
        name: "UserAccount",
        component: UserAccount,
        meta: {
            pageTitle: 'Accounts',
            breadcrumbs: ['My Accounts'],
            requiresRole: 30,
        },
        beforeEnter: (to, from, next) => {
            const role = Number(sessionStorage.getItem("role"));

            if (role === to.meta.requiresRole && store.state.loggedIn) {
                next();
            } else {
                next({path: '/403'});
            }
        },
    },
    {
        path: "/deposits",
        name: "Deposits",
        component: Deposits,
        meta: {
            pageTitle: 'Deposits',
            breadcrumbs: ['Deposits'],
        },
        beforeEnter: (to, from, next) => {
            if (store.state.loggedIn) {
                next();
            } else {
                next({path: '/'});
            }
        },
    },
    {
        path: "/deposit",
        name: "Deposit",
        component: Deposit,
        meta: {
            pageTitle: 'Deposit',
            breadcrumbs: ['Deposit'],
        },
        beforeEnter: (to, from, next) => {
            if (store.state.loggedIn) {
                next();
            } else {
                next({path: '/'});
            }
        },
    }, {
        path: "/verify-deposit",
        name: "VerifyDeposit",
        component: VerifyDeposit,
        meta: {
            pageTitle: 'Deposit',
            breadcrumbs: ['Verify'],
        },
        beforeEnter: (to, from, next) => {
            if (store.state.loggedIn) {
                next();
            } else {
                next({path: '/'});
            }
        },
    },
    {
        path: "/withdraws",
        name: "Withdraws",
        component: WithdrawalsList,
        meta: {
            pageTitle: 'Withdraws',
            breadcrumbs: ['Withdraws'],
        },
        beforeEnter: (to, from, next) => {
            if (store.state.loggedIn) {
                next();
            } else {
                next({path: '/'});
            }
        },
    },
    {
        path: "/withdraw",
        name: "Withdraw",
        component: WithdrawAmount,
        meta: {
            pageTitle: 'Withdraw',
            breadcrumbs: ['Withdraw'],
        },
        beforeEnter: (to, from, next) => {
            if (store.state.loggedIn) {
                next();
            } else {
                next({path: '/'});
            }
        },
    },
    {
        path: "/new-account",
        name: "NewAccount",
        component: NewAccount,
        meta: {
            pageTitle: 'NewAccount',
            breadcrumbs: ['NewAccount'],
        },
        beforeEnter: (to, from, next) => {
            if (store.state.loggedIn) {
                if (Number(store.state.role === 30)) {
                    next();
                } else {
                    next('/403')
                }
            } else {
                next({path: '/'});
            }
        },
    },
    {
        path: "/create-account",
        name: "CreateAccount",
        component: CreateAccount,
        meta: {
            pageTitle: 'CreateAccount',
            breadcrumbs: ['CreateAccount'],
        },
        beforeEnter: (to, from, next) => {
            if (store.state.loggedIn) {
                if (Number(store.state.role === 30)) {
                    next();
                } else {
                    next('/403')
                }
            } else {
                next({path: '/'});
            }
        },
    },
    {
        path: "/tickets",
        name: "Tickets",
        component: Tickets,
        meta: {
            pageTitle: 'Tickets',
            breadcrumbs: ['Tickets'],
        },
        beforeEnter: (to, from, next) => {
            if (store.state.loggedIn) {
                if (Number(store.state.role) === 30) {
                    next({path: '/403'});
                } else {
                    next()
                }
            } else {
                next({path: '/'});
            }
        },
    },
    {
        path: "/useraccounts",
        name: "UserAccounts",
        component: UserAccounts,
        meta: {
            pageTitle: 'Users Accounts',
            breadcrumbs: ['Users Accounts'],
        },
        beforeEnter: (to, from, next) => {
            if (store.state.loggedIn) {
                if (Number(store.state.role) === 30) {
                    next({path: '/404'});
                } else {
                    next()
                }
            } else {
                next({path: '/'});
            }
        },
    },
    {
        path: "/settings",
        name: "Settings",
        component: Settings,
        meta: {
            pageTitle: 'Settings',
            breadcrumbs: ['Settings'],
        },
        beforeEnter: (to, from, next) => {
            if (store.state.loggedIn) {
                if (Number(store.state.role) === 30) {
                    next({path: '/404'});
                } else {
                    next()
                }
            } else {
                next({path: '/'});
            }
        },
    },


];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach((to, from, next) => {
    // Check if the requested route exists
    store.state.previousRoute = from;
    if (router.hasRoute(to.name)) {

        next(); // Route exists, proceed with the navigation
    } else {
        next({name: ''}); // Route not found, redirect to the not-found route
    }
});

export default router;
