import axios from 'axios';
import axiosConfig from "@/config/axiosConfig";
export default {
    dataUrl: axiosConfig.apiBaseUrl +"Dashboard/Data?userId=",



    getData(userId){
        return axios.get(this.dataUrl+userId);
    }
}